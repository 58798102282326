import React, { useEffect } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Button, Skeleton, Divider } from '@mui/material'
import 'firebase/auth'
import { useQuery } from '@tanstack/react-query'
import { gSx } from '../../styles/Theme'
import { Media } from 'service/media'
import CancelIcon from '@mui/icons-material/Cancel'
import { deauthorizeZoom, getUserConfig } from 'service'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import AddToZoomButton from 'components/settings/AddToZoomButton'
import ContainerSmall from 'components/ContainerSmall'
import { logInfo } from 'log'
import IntegrationsBackButton from 'components/IntegrationsBackButton'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'

export default function ZoomSettingsScreen() {
  const { isBlocked } = useIsBlockedByPlan()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
  })
  const isZoomConnected = data?.recall_zoom_oauth_id && data.recall_zoom_oauth_id.length > 0

  useEffect(() => {
    logInfo('Zoom Settings Screen')
  }, [])

  async function disconnect() {
    await deauthorizeZoom()
    await refetch()
  }

  if (isLoading)
    return (
      <>
        <Skeleton variant="rectangular" width={'100%'} height={100} />
        <Skeleton variant="rectangular" width={'100%'} height={100} />
        <Skeleton variant="rectangular" width={'100%'} height={100} />
      </>
    )

  return (
    <>
      <Box sx={{ padding: '1vh ' }} />
      <IntegrationsBackButton />
      <ContainerSmall>
        <Typography variant='h3'>Zoom Integration</Typography>
        <Divider sx={{ marginY: '2vh ' }} />
        <Box sx={gSx.Row}>
          <Link href="https://zoom.us" rel='noopener' target='_blank'>
            <Box
              component="img"
              sx={icon}
              src={Media.zoomLogo}
              alt="Zoom"
            />
          </Link>
          <Box sx={{ padding: 1 }} />
          <Typography variant='h1'>Zoom</Typography>
        </Box>
        <Box sx={{ padding: '1vh ' }} />

        <Typography>Connect FinMate to Zoom, so that the FinMate Notetaker can be invited to record your meetings</Typography>

        <Box sx={{ padding: '1vh ' }} />

        <Card>

          {
            isZoomConnected ? (
              <Box sx={gSx.RowBetween}>
                <Typography variant='h4'>Zoom Connected</Typography>

                <Box sx={sxZoomConnected}>
                  <Box
                    component="img"
                    src={Media.zoomConnect}
                    alt="Zoom Connected"
                  />
                </Box>

                <Tooltip title="Disconnect Zoom App">
                  <Button
                    aria-label='Disconnect Zoom App'
                    onClick={disconnect}
                  >
                    <CancelIcon />
                  </Button>
                </Tooltip>
              </Box>
            ) : (
              <>
                <Typography variant='h5'>Add to Zoom to Get Started</Typography>

                <Typography sx={{ fontSize: 14, paddingY: '1vh' }}>
                  {`When adding to zoom, please checkmark "Allow this app to use my shared access permissions."`}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                  {
                    isBlocked ? (
                      <Typography color='tomato'>
                        Connecting to Zoom requires Starter or Standard Plan
                      </Typography>
                    ) : (
                      <AddToZoomButton />
                    )
                  }
                </Box>
              </>

            )
          }

        </Card >

        <Link
          rel='noopener'
          target='_blank'
          href={'https://www.finmate.ai/zoom'}
          sx={gSx.Row}
        >
          <TextSnippetIcon />
          <Box sx={{ paddingX: '3px' }} />
          <Typography>
            Zoom Guide and Troubleshooting
          </Typography>
        </Link>

      </ContainerSmall>
    </>
  )
}

const icon: SxProps = {
  width: 50,
  marginRight: 2
}

const sxZoomConnected: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  height: '30px',
}
