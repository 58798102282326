import React, { useEffect, useState } from 'react'
import { Card, FormControl, FormControlLabel, Radio, RadioGroup, Typography, FormGroup, Checkbox, Skeleton, Box, Snackbar, Alert, LinearProgress } from '@mui/material'
import { GetCalendarUser, UpdateRecordingPrefs, disableAutoRecordMeetings, recordAllMeetings, recordExternalMeetings, recordInternalMeetings } from '../../service/calendarApi'
import { BotPrefs } from '../../client'
import { useQuery } from '@tanstack/react-query'
import { gSx } from '../../styles/Theme'
import { logInfo } from '../../log'
import useIsBlockedByPlan from '../../hooks/useIsBlockedByPlan'

enum Audience {
  'all',
  'internal',
  'external',
  'none'
}

interface Props {
  isCalendarConnected: boolean
}
export default function AutoRecord({ isCalendarConnected }: Props) {
  const { isBlocked } = useIsBlockedByPlan()
  const [audience, setAudience] = useState<Audience>()
  const [showHost, setShowHost] = useState(false)
  const [host, setHost] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  const disabled = !isCalendarConnected || isBlocked
  isCalendarConnected
  useEffect(() => {
    logInfo('AutoRecord Tab')
  }, [])

  const { data: calUser, isLoading } = useQuery({
    queryKey: ['getCalendarUser'],
    queryFn: async () => {
      const res = await GetCalendarUser()
      res && setAudience(whichAudience(res.preferences))
      res && setHost(res.preferences?.record_only_host ?? false)
      return res
    },
  })

  useEffect(() => {
    if (calUser) {
      const prefs = calUser.preferences
      const external = prefs?.record_external
      const internal = prefs?.record_internal

      if (external && internal)
        setAudience(Audience.all)
      else if (external) {
        setShowHost(true)
        setAudience(Audience.external)
      }
      else if (internal) {
        setShowHost(true)
        setAudience(Audience.internal)
      }

      if (prefs?.record_only_host) {
        setShowHost(true)
        setHost(true)
      }
      logInfo('AutoRecord', { audience, showHost, host })
    }
  }, [calUser])

  async function onUpdate(prefs: BotPrefs) {
    setBusy(true)
    setErr(undefined)
    const res = await UpdateRecordingPrefs(prefs)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setOpenSuccessSnack(true)
    }
    setBusy(false)
  }

  const skeletonHeight = 130
  return (
    <Card>
      <Typography variant='h5'>Auto Record Settings</Typography>
      {
        !isCalendarConnected &&
        <Typography color='tomato'>
          Connect your calendar to enable this feature
        </Typography>
      }
      <Typography>
        Choose the meeting types for FinMate AI to automatically join and record.
      </Typography>

      {
        isLoading ? (
          <>
            <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
            <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
            <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
            <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
            <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
          </>
        ) : (
          <>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={audience}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  control={<Radio checked={audience === Audience.all} />}
                  label="All my meetings"
                  disabled={disabled}

                  onClick={async () => {
                    if (disabled)
                      return
                    setShowHost(false)
                    setAudience(Audience.all)
                    await onUpdate(recordAllMeetings())
                  }}
                />
                <FormControlLabel
                  control={<Radio checked={audience === Audience.internal} />}
                  label="Only internal meetings"
                  disabled={disabled}
                  onClick={async () => {
                    if (disabled)
                      return
                    setShowHost(true)
                    setAudience(Audience.internal)
                    await onUpdate(recordInternalMeetings(host))
                  }}
                />
                <FormControlLabel
                  control={<Radio checked={audience === Audience.external} />}
                  label="Meetings with external participants"
                  disabled={disabled}
                  onClick={async () => {
                    if (disabled)
                      return
                    setShowHost(true)
                    setAudience(Audience.external)
                    await onUpdate(recordExternalMeetings(host))
                  }}
                />

                <FormControlLabel
                  control={<Radio checked={audience === Audience.none} />}
                  label="Disable Auto-Record"
                  disabled={disabled}
                  onClick={async () => {
                    if (disabled)
                      return
                    setShowHost(false)
                    setAudience(Audience.none)
                    await onUpdate(disableAutoRecordMeetings())
                  }}
                />
              </RadioGroup>
            </FormControl>

            {showHost && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={host}
                    />
                  }
                  disabled={disabled}
                  label="Only record if I am the calendar event organizer"
                  onChange={async () => {
                    if (disabled)
                      return
                    setHost(!host)
                    const prefs = audience === Audience.external
                      ? recordExternalMeetings(!host)
                      : recordInternalMeetings(!host)
                    await UpdateRecordingPrefs(prefs)
                  }}
                />
              </FormGroup>
            )}
          </>
        )
      }

      {busy && <LinearProgress />}
      {
        err && <Typography color={'tomato'} sx={{ padding: 2 }}>
          {err}
        </Typography>
      }

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Auto record settings have been updated
        </Alert>
      </Snackbar>

    </Card>
  )
}

function whichAudience(prefs?: BotPrefs): Audience {
  if (!prefs)
    return Audience.external // default set at calendar connect

  if (prefs.record_non_host === false &&
    prefs.record_recurring === false &&
    prefs.record_external === false &&
    prefs.record_internal === false &&
    prefs.record_confirmed === false &&
    prefs.record_only_host === false
  )
    return Audience.none

  if (prefs.record_non_host === false &&
    prefs.record_recurring === false &&
    prefs.record_external === true &&
    prefs.record_internal === true &&
    prefs.record_confirmed === false &&
    prefs.record_only_host === false
  )
    return Audience.all

  if (prefs.record_external === false &&
    prefs.record_internal === true
  )
    return Audience.internal

  return Audience.external
}
