import React from 'react'
import { Box, SxProps, Typography, ButtonBase } from '@mui/material'
import { Media } from '../../service/media'

interface Props {
  disable: boolean
  text: string
  onClick: () => Promise<void>
}

export default function GoogleCalendarSignInButton({ disable, text, onClick }: Props) {

  const sxButton: SxProps = {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    minWidth: 250,
    border: 1,
    borderRadius: 100,
    padding: 1.5,
    borderColor: 'black',
    bgcolor: 'white',
    opacity: disable ? '25%' : '100%',
  }

  return (
    <ButtonBase onClick={onClick} aria-label={'Connect to Google Calendar'}>
      <Box sx={sxButton} aria-label={text}>
        <Box
          component="img"
          sx={disable ? disableIcon : icon}
          src={Media.googleCalendar}
          alt="Google Calendar Logo"
        />
        <Typography sx={disable ? disableText : Text}>
          {text}
        </Typography>
      </Box>
    </ButtonBase>
  )
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: 2
}
const disableIcon: SxProps = {
  width: 20,
  height: 20,
  opacity: 0.2,
  marginRight: 2
}
const disableText: SxProps = {
  color: 'lightgray'
}
const Text: SxProps = {

}
