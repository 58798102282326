import React, { ReactNode } from 'react'
import { Box, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { Path } from 'routes'
import { auth } from 'service/api'

interface Props {
  icon: ReactNode
  path: string
  title: string
  setOpen: (open: boolean) => void
}

export default function SideNavItem({ icon, path, title, setOpen }: Props) {
  const navigate = useNavigate()
  const location = useLocation()
  const selected = location.pathname === path
  const isXS = useMediaQuery('(max-width:600px)')
  const isPublic = window.location.href.includes(Path.PublicEvent)
  const isSignedIn = !!auth.currentUser

  function onNav(path: string): string {
    if (isXS) setOpen(false)
    return isPublic && !isSignedIn ? Path.SignUp : path
  }

  return (
    <Box sx={{ paddingY: '3px' }}>
      <ListItemButton
        onClick={() => onNav && navigate(onNav(path))}
        selected={selected}
        sx={{
          '&.Mui-selected': {
            backgroundColor: 'grey.300',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'grey.300',
          },
          ':hover': {
            backgroundColor: 'grey.300',
          },
        }}
      >
        <ListItemIcon
          sx={{
            color: 'lightslategrey',
            ...(selected && {
              color: 'black',
            }),
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </Box>
  )
}
