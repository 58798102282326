import React, { useEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import { observer } from 'mobx-react-lite'
import { useLocation, useParams } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2'
import { useQuery } from '@tanstack/react-query'
import { Box, SxProps, Tab } from '@mui/material'
import TimelineIcon from '@mui/icons-material/Timeline'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { useStore } from 'hooks/useStore'
import Video from '../components/event/Video'
import { getEvent } from '../service/eventApi'
import { getPreciseFpConfig } from 'service/integrations/precisefpApi'
import EventTitle from '../components/event/EventTitle'
import EventDetails from '../components/event/EventDetails'
import EventAnalysis from '../components/event/EventAnalysis'
import TranscriptEditable from '../components/event/TranscriptEditable'
import EventFollowupEmail from '../components/event/EventFollowupEmail'
import EventCondensates from '../components/event/EventCondensates'
import ClientAttendeeTabs from 'components/event/ClientAttendeeTabs'
import { logInfo } from 'log'
import { auth } from 'service/api'
import { Access, FirebaseUser } from 'client'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { ShareEventButton } from 'components/event/shareEvent/ShareEventButton'
import EventMenu from 'components/event/EventMenu'
import { gSx } from 'styles/Theme'
import useIsEventOwnerBlocked from 'hooks/useIsEventOwnerBlocked'
import ExpiredPlanBanner from 'components/pay/ExpiredPlanBanner'
import WebBanner from 'components/WebBanner'
import { getFirebaseUser } from 'service'
import { isSuperUser } from 'service/authApi'
import EventFinances from '../components/event/EventFinances'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

export default observer(EventScreen)
function EventScreen() {
  const store = useStore()
  const showAdmin = store.Security.getShowAdmin()
  const { id } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const [tabValue, setTabValue] = useState(tabinitialState(tab))
  const fbUser = auth.currentUser

  const [owner, setOwner] = useState<FirebaseUser | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['getEvent', id],
    queryFn: async () => await getEvent(id),
    retryDelay: (attemptIndex) => Math.min(2000 ** attemptIndex, 5000), // retry max 5 secs
  })

  const { data: preciseFpConfig, isLoading: isPreciseFpConfigLoading } = useQuery({
    queryKey: ['getPreciseFpConfig'],
    queryFn: async () => await getPreciseFpConfig(),
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(2000 ** attemptIndex, 5000), // retry max 5 secs
  })

  const isOwner = data?.owner_id == fbUser?.uid
  const isEditor = data?.permissions?.some(p => p.uid == fbUser?.uid && p.access == Access.EDITOR) ?? false
  const { isBlocked: isBlockedByPlan } = useIsBlockedByPlan(true)
  const { isBlocked: isEventOwnerBlocked } = useIsEventOwnerBlocked(data?.owner_id)

  function getDisabled() {
    if (isOwner)
      return isBlockedByPlan
    if (isEditor) {
      if (isBlockedByPlan)
        return true
      return isEventOwnerBlocked
    }
    if (isSuperUser())
      return false

    // is viewer
    return true
  }
  const disabled = getDisabled()

  useEffect(() => {
    if (data && data.owner_id) {
      getFirebaseUser(data.owner_id)
        .then((res) => {
          setOwner(res)
        })
    }
  }, [data])

  useEffect(() => {
    logInfo('Event Screen', { eventId: id, isBlockedByPlan, disabled, isOwner, isEditor })
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={sxContainer}>
      <Grid container spacing={'2vh'} sx={sxGrid}>
        <Grid xs={12} md={8}>

          <ExpiredPlanBanner eventOwnerId={data?.owner_id} />

          <Box sx={{ ...gSx.RowBetween, alignItems: 'flex-start' }}>

            <EventTitle data={data} disabled={disabled} owner={owner} />

            <Box sx={{ width: 90 }}>
              <ShareEventButton data={data} disabled={disabled} />
              {data?.id && <EventMenu eventId={data.id} isOwner={isOwner} isEditor={isEditor} />}
            </Box>
          </Box>

          <TabContext value={tabValue}>
            <TabList sx={sxTabList} onChange={handleChange}>
              <Tab
                sx={sxTabList}
                iconPosition='start'
                icon={<TextSnippetIcon />}
                label='Summary'
                value='1'
                hidden
              />
              <Tab
                sx={sxTabList}
                iconPosition='start'
                icon={<TimelineIcon />}
                label='Analytics'
                value='2'
              />
              <Tab
                sx={sxTabList}
                iconPosition='start'
                icon={<ContactMailIcon />}
                label='Follow-up'
                value='3'
              />
              {preciseFpConfig && !isPreciseFpConfigLoading && <Tab
                sx={sxTabList}
                iconPosition='start'
                icon={<AttachMoneyIcon />}
                label='Finances'
                value='4'
              />}
              {showAdmin &&
                <Tab
                  sx={sxTabList}
                  iconPosition='start'
                  icon={<ListAltIcon />}
                  label='Condensate'
                  value='5'
                />
              }
            </TabList>

            <TabPanel sx={sxTabPanel} value='1'>
              <EventDetails
                eventId={id ?? ''}
                data={data}
                isEventMetaDataLoading={isLoading}
                disabled={disabled}
              />
            </TabPanel>
            <TabPanel sx={sxTabPanel} value='2'>
              <EventAnalysis data={data} disabled={disabled} />
            </TabPanel>
            <TabPanel sx={sxTabPanel} value='3'>
              <EventFollowupEmail
                eventMetaData={data}
                isEventMetaDataLoading={isLoading}
                eventId={id ?? ''}
                disabled={disabled}
              />
            </TabPanel>
            <TabPanel sx={sxTabPanel} value='4'>
              <EventFinances isModuleSelected={data?.module_name ? false : true} eventId={id ?? ''} />
            </TabPanel>
            <TabPanel sx={sxTabPanel} value='5'>
              <EventCondensates isModuleSelected={data?.module_name ? false : true} eventMetaData={data} eventId={id ?? ''} />
            </TabPanel>
          </TabContext>
        </Grid>

        <Grid xs={12} md={4} ref={ref}>
          <ClientAttendeeTabs data={data} disabled={disabled} />
          <TranscriptEditable eventId={id ?? ''} disabled={disabled} />
          <Video eventId={id ?? ''} width={width} />
        </Grid>

      </Grid >
    </Box >
  )
}

function tabinitialState(tab: string | null) {
  if (!tab || tab == 'summary')
    return '1'
  if (tab == 'analytics')
    return '2'
  // followup
  return '3'
}

const sxContainer: SxProps = {
}

const sxGrid: SxProps = {
}

const sxTabPanel: SxProps = {
  padding: '0',
}

const sxTabList: SxProps = {
  minHeight: '30px',
}
