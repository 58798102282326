import React from 'react'
import { Typography, SxProps, Box, CircularProgress, Card, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Api } from '../../service/api'

export default function AdminTranscriptJson() {
  const { event_id } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: ['AdminTranscriptJson' + event_id],
    queryFn: runQuery,
  })
  async function runQuery() {
    const api = await Api()
    return api.events.getEventTranscriptRaw(event_id ?? '')
  }

  return (
    <Box sx={sxPage}>
      <Typography>{JSON.stringify(data)}</Typography>
    </Box>
  )
}

const sxPage: SxProps = {
  maxWidth: 'calc(100% - 200px)',
  padding: '2vh'
}
