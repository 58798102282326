import React, { MouseEvent, useState } from 'react'
import { Avatar, Box, ButtonBase, Chip, SxProps, Tooltip, Typography } from '@mui/material'
import { AssigneeInfo, CRM, SalesforceApp } from '../../client'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { getInitial } from 'utils'
import EventTaskAssigneePopover from './EventTaskAssigneePopover'
import { useQuery } from '@tanstack/react-query'
import { getSalesforceIcon, getSalesforceSettings } from 'service/integrations/salesforceApi'
import { Media } from 'service/media'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import { getWealthboxProfile } from 'service/integrations/wealthboxApi'
import { grey } from '@mui/material/colors'
import { gSx } from 'styles/Theme'

interface Props {
  assignee: AssigneeInfo
  disabled?: boolean
  targetCrm?: CRM

  eventId?: string
  changeAssignee?: (assignee: AssigneeInfo) => Promise<void>
}

export default function TaskAssigneeChip({ assignee, disabled, targetCrm, eventId, changeAssignee }: Props) {

  const { data: sf } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings, enabled: false
  })
  const isSalesforceSignedIn = !!sf?.sf_uid
  const sfApp = sf?.app ?? SalesforceApp.SALESFORCE

  const { data: rt } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings, enabled: false
  })
  const isRedtailSignedIn = !!rt?.user_id
  const { data: wb } = useQuery({
    queryKey: ['getWealthboxProfile'], queryFn: getWealthboxProfile, enabled: false
  })
  const isWealthboxSignedIn = !!wb?.id

  const isEmpty = !assignee.name || assignee.name == null
  const isNone = (!assignee.salesforce_user_id
    && !assignee.redtail_user_id && !assignee.wealthbox_user_id)
    || (targetCrm == CRM.REDTAIL && !assignee.redtail_user_id)
    || (targetCrm == CRM.SALESFORCE && !assignee.salesforce_user_id)
    || (targetCrm == CRM.WEALTHBOX && !assignee.wealthbox_user_id)

  const [showPopover, setShowPopover] = useState<null | HTMLElement>(null)

  const handleAssigneeClickChip = (event: MouseEvent<HTMLElement>) => {
    !disabled && setShowPopover(event.currentTarget)
  }

  return (
    <>
      <ButtonBase
        onClick={handleAssigneeClickChip}
        disabled={disabled}
        sx={gSx.Crm.Chip()}
      >
        {isEmpty &&
          <PersonAddAlt1Icon fontSize='small' sx={{ paddingLeft: 2, paddingRight: 0.3 }} />
        }

        {!isEmpty && isNone && <Avatar sx={{ width: 20, height: 20, fontSize: 12, marginRight: 1 }}>
          {getInitial(assignee.name)}
        </Avatar>}

        {isSalesforceSignedIn && assignee.salesforce_user_id && (!targetCrm || targetCrm == CRM.SALESFORCE) &&
          <Tooltip title={`${sfApp} User (ID: ${assignee.salesforce_user_id})`}>
            < Box
              component="img"
              sx={sxIcon}
              src={getSalesforceIcon(sfApp)}
              alt={sfApp}
            />
          </Tooltip>
        }
        {isRedtailSignedIn && assignee.redtail_user_id && (!targetCrm || targetCrm == CRM.REDTAIL) &&
          <Tooltip title={`Redtail User (ID: ${assignee.redtail_user_id})`}>
            <Box
              component="img"
              sx={sxIcon}
              src={Media.redtailIconWhite}
              alt="Redtail"
            />
          </Tooltip>
        }
        {isWealthboxSignedIn && assignee.wealthbox_user_id && (!targetCrm || targetCrm == CRM.WEALTHBOX) &&
          <Tooltip title={`Wealthbox User (ID: ${assignee.wealthbox_user_id})`}>
            <Box
              component="img"
              sx={sxIcon}
              src={Media.wealthboxIcon}
              alt="Wealthbox"
            />
          </Tooltip>
        }

        <Typography sx={{ fontSize: 14 }}>{assignee.name}</Typography>
        <Box sx={{ paddingRight: 0.5 }} />
      </ButtonBase>

      {
        eventId && changeAssignee &&
        <EventTaskAssigneePopover
          eventId={eventId}
          showPopover={showPopover}
          closePopover={() => setShowPopover(null)}
          changeAssignee={changeAssignee}
        />
      }

    </>
  )
}

const sxIcon: SxProps = {
  marginTop: 0.1,
  marginRight: 0.7,
  width: 22,
  height: 22,
}