import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

interface Props {
  openDialog: boolean
  closeDialog: (confirm: boolean) => Promise<void>
  busy: boolean
  unchangedSpeakers: string
}

export default function SpeakerNameConfirmDialog({ openDialog, closeDialog, busy, unchangedSpeakers }: Props) {

  return (
    <Dialog
      open={openDialog}
      onClose={() => closeDialog(false)}
      aria-labelledby="Confirm speaker names"
    >
      <DialogTitle>{"Proceed with Current Speaker Names?"}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 16 }}>
          Some speaker names in the transcript have not been updated:
        </DialogContentText >
        <DialogContentText sx={{ fontSize: 16, textAlign: 'center' }}>
          <b style={{ color: '#cd5c5c' }}>{unchangedSpeakers}</b>
        </DialogContentText >
        <DialogContentText sx={{ paddingBottom: '10px', fontSize: 16 }}>
          Do you want to proceed anyway?
        </DialogContentText>
        <DialogContentText sx={{ paddingBottom: '4px', fontSize: 15 }}>
          <span style={{ color: '#2a7e8a' }}>For optimal results, please <b>verify the names of the speakers</b>.</span>
        </DialogContentText>
        <DialogContentText sx={{ fontSize: 15 }}>
          <span style={{ color: '#2a7e8a' }}>Tip: You can modify a speaker&apos;s name by clicking on their name in the Transcript.</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog(false)} color="primary">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => closeDialog(true)}
          color="primary"
          autoFocus
          loading={busy}
          disabled={busy}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
