import React, { useState, useMemo, useEffect } from 'react'
import { Popover, TextField, Grid, IconButton, Typography, Skeleton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName, IconLookup } from '@fortawesome/fontawesome-svg-core'
import { useFontAwesomeIconPack } from '../../hooks/useFontAwesomeIconPack'

interface IconSelectorProps {
  selectedIcon?: string;
  onSelect: (icon: string) => void;
  disabled: boolean;
}

const IconSelector: React.FC<IconSelectorProps> = ({ selectedIcon, onSelect, disabled }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [searchText, setSearchText] = useState('')
  const [filteredIcons, setFilteredIcons] = useState<IconLookup[]>([])
  const iconPack = useFontAwesomeIconPack() ?? []

  useEffect(() => {
    if (!iconPack) return
    const lowerCaseSearchText = searchText.toLowerCase()
    if (lowerCaseSearchText.length <= 0) {
      setFilteredIcons(iconPack)
    }
    const filtered = iconPack.filter((icon) =>
      icon.iconName.toLowerCase().includes(lowerCaseSearchText)
    )
    setFilteredIcons(filtered)
  }, [searchText, iconPack])

  if (!iconPack || iconPack.length == 0) {
    return <Skeleton variant="rectangular" width={210} height={40} />
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleIconSelect = (iconName: string) => {
    onSelect(iconName)
    handleClose()
  }

  const iconSize = 80
  return (
    <>
      <IconButton onClick={handleClick} >
        <FontAwesomeIcon icon={['fas', (selectedIcon ?? 'book-open') as IconName]} />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: 4 * (iconSize + 8), // 4 icons wide, with spacing
            height: 5 * (iconSize + 8) + 60, // 5 icons tall, plus extra space for search bar
            overflow: 'hidden', // Hide overflow to remove scrollbars
          },
        }}
      >
        <TextField
          placeholder="Search icons"
          autoFocus
          value={searchText}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ m: 1 }}
        />
        <Grid container spacing={1} sx={{ overflowY: 'auto', overflowX: 'hidden', height: `calc(100% - 60px)` }}>
          {filteredIcons.map((icon) => (
            <Grid item key={icon.iconName} xs={3}>
              <IconButton
                onClick={() => handleIconSelect(icon.iconName)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  minWidth: iconSize,
                  minHeight: iconSize,
                }}
              >
                <FontAwesomeIcon icon={icon} fontSize="24px" />
                <Typography variant="caption" sx={{ mt: 1 }}>
                  {icon.iconName}
                </Typography>
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  )
}

export default IconSelector
