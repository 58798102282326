import React, { useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { OrgInvite } from '../../client'
import CheckIcon from '@mui/icons-material/Check'

interface Props {
  open: string | undefined
  setOpen: (isOpen: string | undefined) => void
}

export default function InviteStatusSnackEmailSent({ setOpen, open }: Props) {

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(undefined)
  }

  return (
    <Snackbar
      open={!!open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        variant="filled"
        icon={<CheckIcon fontSize="inherit" />}
        severity="success"
      >
        {`Email sent to ${open}`}
      </Alert>

    </Snackbar>
  )
}