import React from 'react'
import { AssigneeInfo, SalesforceApp } from 'client'
import { Box, SxProps, Tooltip } from '@mui/material'
import { Media } from 'service/media'
import { useQuery } from '@tanstack/react-query'
import { getSalesforceIcon, getSalesforceSettings } from 'service/integrations/salesforceApi'

interface Props {
  option: AssigneeInfo
}
export default function AssigneePopoverRender(props: Props) {
  const { option } = props

  const { data: salesforceSettings } = useQuery({
    queryKey: ['getSalesforceSettings'],
    queryFn: getSalesforceSettings,
    enabled: false,
  })
  const salesforceApp = salesforceSettings?.app ?? SalesforceApp.SALESFORCE

  return (
    <li {...props}>
      {`${option.name}`}

      {
        option.redtail_user_id &&
        <Tooltip title='Redtail User'>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.redtailIconWhite}
            alt="Redtail"
          />
        </Tooltip>
      }

      {
        option.wealthbox_user_id &&
        <Tooltip title='Wealthbox User'>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.wealthboxIcon}
            alt="Wealthbox"
          />
        </Tooltip>
      }

      {
        option.salesforce_user_id &&
        <Tooltip title={`${salesforceApp} User`}>
          <Box
            component="img"
            sx={sxIcon}
            src={getSalesforceIcon(salesforceApp)}
            alt="Salesforce"
          />
        </Tooltip>
      }
    </li>
  )
}

const sxIcon: SxProps = {
  marginLeft: 1,
  width: 25,
  height: 25,
}