import { config, messaging, Api, auth } from './api'
import { MessagePayload, deleteToken, getToken, onMessage } from 'firebase/messaging'
import { logErr, logInfo } from '../log'

const vapidKey = process.env.REACT_APP_VAPID_KEY ?? ''

export async function requestNotificationPermission() {
  if (!auth.currentUser)
    return
  console.log('Requesting permission...')
  const permission = await Notification.requestPermission()
  if (permission === 'granted') {
    console.log('Notification permission granted.')

    await getNotificationToken()
  } else {
    console.log('Unable to get permission to notify.', permission)
  }
}

export async function getNotificationToken() {
  try {
    const currentToken = await getToken(messaging, { vapidKey })
    if (currentToken) {
      const api = await Api()
      const res = await api.communications.setFcmToken(currentToken)
    } else {
      console.log('No registration token available. Request permission to generate one.')
    }
  } catch (err) {
    console.log('An error occurred while retrieving token.', err)
  }
}

export async function getNotifications() {
  if (!auth.currentUser)
    return
  const api = await Api()
  const res = await api.communications.getNotifications()
  return res
}

export const onMessageListener = () =>
  new Promise<MessagePayload>((resolve) => {
    onMessage(messaging, (payload: MessagePayload) => {
      console.log("payload", payload.constructor.name)
      resolve(payload)
    })
  })

export async function readAllNotifications(): Promise<any> {
  try {
    const api = await Api()
    const res = await api.communications.readAllNotifications()
    return res
  } catch (e) {
    logErr('readAllNotifications', { e })
  }
  return 0
}