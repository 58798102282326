/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_do_nothing_tests_FE_schemas_get } from '../models/Body_do_nothing_tests_FE_schemas_get';
import type { CRM } from '../models/CRM';
import type { WealthboxType } from '../models/WealthboxType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FrontendService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Do Nothing
     * @param d
     * @param h
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public doNothingTestsFeSchemasGet(
        d: WealthboxType,
        h: CRM,
        requestBody: Body_do_nothing_tests_FE_schemas_get,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tests/FE_schemas',
            query: {
                'd': d,
                'h': h,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
