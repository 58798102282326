import React from 'react'
import Container from '@mui/material/Container'
import { Box, Button, Paper, SxProps } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'

const IntegrationsBackButton = () => {
  const navigate = useNavigate()

  return (
    <Button
      onClick={() => navigate(Path.Integrations)}
      startIcon={<ArrowBackIcon />}
    >
      Integrations
    </Button>
  )
}

export default IntegrationsBackButton
