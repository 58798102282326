import React from 'react'
import { Typography, Skeleton } from '@mui/material'
import { numActiveOrgUsersByPlan } from '../../service/orgsApi'
import { OrgBase, ProductName } from 'client'
import Grid from '@mui/material/Unstable_Grid2'

interface Props {
  org?: OrgBase
  isLoading: boolean
}
export default function ActiveOrgSeats({ org, isLoading }: Props) {

  return (
    <Grid container>
      <Grid xs={5}>
        <Typography variant='h6'>Active Seats</Typography>
      </Grid>
      <Grid xs={6}>
        <Typography>Standard Plan</Typography>
        <Typography>Starter Plan</Typography>
        <Typography>Lite Plan</Typography>
      </Grid>
      <Grid xs={1}>
        {isLoading
          ? <Skeleton variant="rectangular" width={'100%'} height={40} />
          : (
            <>
              <Typography textAlign={'right'}>
                {numActiveOrgUsersByPlan(ProductName.STANDARD, org?.org_user_list)}
              </Typography>

              <Typography textAlign={'right'}>
                {numActiveOrgUsersByPlan(ProductName.STARTER, org?.org_user_list)}
              </Typography>

              <Typography textAlign={'right'}>
                {numActiveOrgUsersByPlan(ProductName.LITE, org?.org_user_list)}
              </Typography>
            </>
          )
        }
      </Grid>
    </Grid>
  )
}