import { useContext } from 'react'

import { StoreContext } from '../context/context'
import { RootType } from '../store'

export const useStore = (): RootType => {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error(
      'The StoreContext is not provided using the <StoreProvider />.'
    )
  }
  return store
}
