import React, { useEffect, useState } from 'react'
import {
  ButtonGroup,
  Button,
  Tooltip,
  Box,
  TextField,
  Popover,
  SxProps,
  Typography,
} from '@mui/material'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import TitleIcon from '@mui/icons-material/Title'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import { Editor } from '@tiptap/react'
import FindReplaceIcon from '@mui/icons-material/FindReplace'
import { gSx } from 'styles/Theme'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import ClearIcon from '@mui/icons-material/Clear'
import { ReactComponent as WholeWordIcon } from './icon-whole-word.svg'
import { ReactComponent as ReplaceAllIcon } from './icon-replace-all.svg'
import { ReactComponent as ReplaceIcon } from './icon-replace.svg'

interface MenuProps {
  editor?: Editor | null
}

export const EditorMenuBar = ({ editor }: MenuProps) => {
  const [findPopover, setFindPopover] = useState<HTMLButtonElement | null>(null)

  const [searchTerm, setSearchTerm] = useState("")
  const [replaceTerm, setReplaceTerm] = useState('')
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchTotal, setSearchTotal] = useState(0)
  const [isWholeWord, setWholeWord] = useState(true)

  useEffect(() => {
    return () => {
      // clear on unmounting EditorMenuBar
      clear()
    }
  }, [])

  useEffect(() => {
    updateSearchReplace()
  }, [searchTerm, replaceTerm, isWholeWord])

  const updateSearchReplace = (clearIndex = false) => {
    if (!editor) return
    if (clearIndex) editor.commands.resetIndex()

    const wholeWordSearchTerm = searchTerm ? `\\b${searchTerm}\\b` : ''
    editor.commands.setSearchTerm(isWholeWord ? wholeWordSearchTerm : searchTerm)
    editor.commands.setReplaceTerm(replaceTerm)

    const { results, resultIndex } = editor.storage.searchAndReplace
    setSearchIndex(resultIndex)
    setSearchTotal(results.length)
  }

  const goToSelection = () => {
    if (!editor) return
    const { results, resultIndex } = editor.storage.searchAndReplace
    setSearchIndex(resultIndex)
    setSearchTotal(results.length)

    const position = results[resultIndex]
    if (!position) return

    editor.commands.setTextSelection(position)

    const { node } = editor.view.domAtPos(editor.state.selection.anchor)
    if (node instanceof HTMLElement) {
      node.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const clear = () => {
    setSearchTerm("")
    setReplaceTerm("")
    editor?.commands.setSearchTerm("")
    editor?.commands.setReplaceTerm("")
    editor?.commands.resetIndex()
  }

  const replace = () => {
    editor?.commands.replace()
    goToSelection()
  }

  const next = () => {
    editor?.commands.nextSearchResult()
    goToSelection()
  }

  const previous = () => {
    editor?.commands.previousSearchResult()
    goToSelection()
  }

  const replaceAll = () => editor?.commands.replaceAll()

  function onClosePopover() {
    setFindPopover(null)
  }

  if (!editor)
    return null
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>
        <ButtonGroup sx={{ borderRadius: 0 }} size="small" aria-label="small button group">

          <Tooltip title="Header">
            <Button
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} variant={editor.isActive('heading', { level: 3 }) ? "contained" : "outlined"} ><TitleIcon /></Button></Tooltip>

          <Tooltip title="Bullet List">
            <Button onClick={() => editor.chain().focus().toggleBulletList().run()} variant={editor.isActive('bulletList') ? "contained" : "outlined"} ><FormatListBulletedIcon /></Button></Tooltip>

          <Tooltip title="Ordered List">
            <Button onClick={() => editor.chain().focus().toggleOrderedList().run()} variant={editor.isActive('orderedList') ? "contained" : "outlined"} ><FormatListNumberedIcon></FormatListNumberedIcon></Button></Tooltip>

          <Tooltip title="Bold">
            <Button
              onClick={() => editor.chain().focus().toggleBold().run()}
              variant={editor.isActive('bold') ? "contained" : "outlined"}
            >
              <FormatBoldIcon />
            </Button></Tooltip>

          <Tooltip title="Italic">
            <Button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              variant={editor.isActive('italic') ? "contained" : "outlined"}
            >
              <FormatItalicIcon />
            </Button></Tooltip>

        </ButtonGroup>

        <ButtonGroup sx={{ paddingX: '5px' }} size="small" aria-label="small button group">
          <Tooltip title="Undo">
            <Button
              onClick={() => editor.chain().focus().undo().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .undo()
                  .run()
              }
            >
              <UndoIcon />
            </Button></Tooltip>
          <Tooltip title="Redo">
            <Button
              onClick={() => editor.chain().focus().redo().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .redo()
                  .run()
              }
            >
              <RedoIcon />
            </Button></Tooltip>
        </ButtonGroup>

        <ButtonGroup sx={{ paddingX: '5px' }} size="small" aria-label="small button group">
          <Tooltip title="Find and Replace">
            <Button onClick={(e) => setFindPopover(e.currentTarget)}>
              <FindReplaceIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>

      </Box>

      <Popover
        open={Boolean(findPopover)}
        anchorEl={findPopover}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >

        <Box sx={{ padding: 1 }}>

          <Box sx={gSx.Row}>
            <TextField
              size="small"
              label="Find"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && updateSearchReplace()}
              autoFocus
            />

            <Box sx={{ padding: 0.5 }} />

            <ButtonGroup sx={{ paddingX: '5px' }} size="small">
              <Tooltip title='Whole Word'>
                <Button onClick={() => setWholeWord(!isWholeWord)}
                  variant={isWholeWord ? 'contained' : 'outlined'}>
                  {isWholeWord &&
                    <WholeWordIcon style={{ width: 22, height: 22, stroke: "white", strokeWidth: 0.2 }} />
                  }
                  {!isWholeWord &&
                    <WholeWordIcon style={{ width: 22, height: 22, stroke: "349ead" }} />
                  }
                </Button>
              </Tooltip>
            </ButtonGroup>
            <Box sx={{ minWidth: "10px" }} />

            <ButtonGroup sx={{ paddingX: '5px' }} size="small">
              <Tooltip title='Previous'>
                <Button onClick={previous} variant="outlined" >
                  <NorthIcon />
                </Button>
              </Tooltip>
              <Tooltip title='Next'>
                <Button onClick={next} variant="outlined" >
                  <SouthIcon />
                </Button>
              </Tooltip>

              <Tooltip title='Clear'>
                <Button onClick={clear} variant="outlined" >
                  <ClearIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>

            <Box sx={{
              minWidth: "65px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              {searchTotal != 0 &&
                <Typography variant="caption" align="center">{searchIndex + 1} of {searchTotal}</Typography>
              }
              {searchTotal == 0 &&
                <Typography variant="caption" align="center">{"No Results"}</Typography>
              }
            </Box>

          </Box>

          <Box sx={{ padding: 1 }} />

          <Box sx={gSx.Row}>
            <TextField
              size="small"
              label="Replace"
              variant="outlined"
              value={replaceTerm}
              onChange={(e) => setReplaceTerm(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && replace()}
            />

            <Box sx={{ paddingX: 0.5 }} />
            <ButtonGroup sx={{ paddingX: '5px' }} size="small" aria-label="small button group">
              <Tooltip title='Replace'>
                <Button variant="outlined" onClick={replace} >
                  <ReplaceIcon style={{ width: 26, height: 26, stroke: "349ead" }} />
                </Button>
              </Tooltip>
              <Tooltip title='Replace All'>
                <Button variant="outlined" onClick={replaceAll}>
                  <ReplaceAllIcon style={{ width: 26, height: 26, stroke: "349ead" }} />
                </Button>
              </Tooltip>
            </ButtonGroup>

          </Box>

        </Box>
      </Popover>
    </Box >
  )
}
