import React, { useState } from "react"
import { Box, Button, Card, Typography, Link, SxProps, Container, CardContent, CardActions, CardHeader, Grid } from "@mui/material"
import { OverridableStringUnion } from '@mui/types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { OrgBase, ProductName } from "../../client"
import { useTheme } from '@mui/material/styles'
import { useStore } from "hooks/useStore"
import CheckoutDialog from "./CheckoutDialog"
import { gColor } from "styles/Theme"

interface Props {
  isAdmin: boolean
  org?: OrgBase
}
// TODO: consolidate new and update
const plans = [
  {
    title: "Starter",
    price: "95", // TODO: get pricing from stripe / backend
    productName: ProductName.STARTER,
    description: [
      "20 hours / seat / month", // TODO: overage $4 / hour 
      "AI Meeting Summary",
      "Meeting Analytics",
      "Automated Recording Rules via Calendar",
    ],
    buttonText: "Select Plan",
    buttonVariant: "outlined" as OverridableStringUnion<'text' | 'outlined' | 'contained'>,
    surcharge: "For starter users, surcharge rates over 20 hours a month may apply.",
  },
  {
    title: "Standard",
    subheader: "Most popular",
    price: "150",
    productName: ProductName.STANDARD,
    description: [
      "40 hours / seat / month", // TODO: overage $3 / hour 
      "AI Meeting Summary",
      "Meeting Analytics",
      "Automated Recording Rules via Calendar",
    ],
    buttonText: "Select Plan",
    buttonVariant: "contained" as OverridableStringUnion<'text' | 'outlined' | 'contained'>,
    surcharge: "For standard users, surcharge rates over 40 hours a month may apply.",
  }
]

export default function PricingPlansNew({ isAdmin, org }: Props) {
  const theme = useTheme()
  const store = useStore()
  const [openCart, setOpenCart] = useState(false)

  function handleButtonClick() {
    setOpenCart(true)
  }

  return (
    <>
      <Grid container spacing={2}>
        {plans.map((plan) => (
          <Grid item key={plan.title} xs={12} md={4} >
            <Card sx={{ margin: 0 }}>
              <CardHeader
                title={plan.title}
                subheader={plan.subheader}
                titleTypographyProps={{ align: "center" }}
                // action={plan.title === "Pro" ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                  backgroundColor: theme.palette.grey[200]
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                    ${plan.price}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    &nbsp;/ seat / month
                  </Typography>
                </Box>

                {plan.description.map((line: string) => (
                  <Box key={line} display="flex" alignItems="center" my={1}>
                    <CheckCircleIcon sx={{ mt: 0.5 }} color={'success'} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {line}
                    </Typography>
                  </Box>
                ))}

              </CardContent>
              <CardActions>
                {isAdmin ? (
                  <Button
                    variant={plan.buttonVariant}
                    fullWidth
                    onClick={handleButtonClick}
                    aria-label={plan.buttonText}>
                    {plan.buttonText}
                  </Button>
                ) : (
                  <Typography color={gColor.green500}>
                    Contact your administrator to purchase seats
                  </Typography>
                )}
              </CardActions>

              <Typography component="h2" variant="caption" color="text.primary">
                {plan.surcharge}
              </Typography>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12} md={4} >

          <Card>
            <CardHeader title="Enterprise" titleTypographyProps={{ align: "center" }} sx={{
              backgroundColor: theme.palette.grey[200]
            }} />
            <CardContent>

              <Box display="flex" alignItems="center" my={1}>
                <CheckCircleIcon sx={{ mt: 0.5 }} color={'success'} />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Everything in Standard
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" my={1}>
                <CheckCircleIcon sx={{ mt: 0.5 }} color={'success'} />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Customized Data Retention Policies
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" my={1}>
                <CheckCircleIcon sx={{ mt: 0.5 }} color={'success'} />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Custom Modules
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" my={1}>
                <CheckCircleIcon sx={{ mt: 0.5 }} color={'success'} />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Premium support
                </Typography>
              </Box>

              <Box sx={{ padding: '1vh' }} />
              <Box sx={sxButtonWrapper}>
                <Link href={`mailto:sales@finmate.ai`} color="inherit">
                  <Button variant="outlined" aria-label='Contact Sales'>
                    {`Contact Sales`}
                  </Button>
                </Link>
              </Box>
            </CardContent>

          </Card>

        </Grid>

        <Grid item xs={12} md={12} >

          <Card>
            <CardHeader
              title="Lite"
              titleTypographyProps={{ align: "center" }}
              subheader={'For Assistants'}
              subheaderTypographyProps={{ align: "center" }}
              sx={{ backgroundColor: theme.palette.grey[200] }}
            />
            <CardContent>
              <Typography variant='body1' textAlign="center">
                Perfect for those who want to manage shared meetings. Lite users can edit CRMs, operate analytics, send followup emails, etc
              </Typography>

            </CardContent>

            <CardActions sx={sxButtonWrapper}>
              {isAdmin && (
                <Button
                  variant='outlined'
                  onClick={handleButtonClick}
                  aria-label={ProductName.LITE}>
                  Select Plan
                </Button>
              )}
            </CardActions>

          </Card>

        </Grid>

        <Grid item xs={12} md={12} >

          <Card>
            <CardHeader title="Small Business Discounts Available" titleTypographyProps={{ align: "center" }} sx={{
              backgroundColor: theme.palette.grey[200]
            }} />
            <CardContent>
              <Typography variant='body1' textAlign="center">
                We&apos;re committed to making our tool accessible to startups, small RIAs, and Advisors.
              </Typography>
              <Typography variant='body1' textAlign="center">
                Contact us, and we&apos;d be happy to explore how we can make our tool available to all firms.
              </Typography>
              <Box sx={{ padding: '1vh' }} />
              <Box sx={sxButtonWrapper}>
                <Link href={`mailto:sales@finmate.ai`} color="inherit">
                  <Button variant="outlined" aria-label='Contact Sales'>
                    {`Contact Sales`}
                  </Button>
                </Link>
              </Box>
            </CardContent>

          </Card>

        </Grid>
      </Grid>

      {org &&
        <CheckoutDialog
          org={org}
          open={openCart}
          setOpen={setOpenCart}
        />
      }

    </>
  )
}

const sxButtonWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}