import React from 'react'
import { EventBase } from "client"
import { Box, Card, Chip } from '@mui/material'

interface Props {
  data?: EventBase
}

export default function Attendees({ data }: Props) {

  if (data?.bot?.meeting_participants && data?.bot?.meeting_participants.length > 0)
    return (
      <Card>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {data?.bot?.meeting_participants.map(p => {
            const name = p.name + '  ' + (p.is_host ? '(host)' : '')
            return (
              <Box key={p.id} >
                <Chip label={name} />
              </Box>
            )
          })
          }
        </Box>
      </Card >
    )
  return null
}