import React from 'react'
import { Typography, Box, Card, SxProps, Button, Skeleton, ButtonBase } from '@mui/material'
import 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery } from '@tanstack/react-query'
import { Media } from 'service/media'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { getUserConfig } from 'service'

export default function ZoomIntegrationLine() {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['getUserConfig'],
    queryFn: async () => await getUserConfig(),
  })

  const isZoomConnected = data?.recall_zoom_oauth_id && data.recall_zoom_oauth_id.length > 0

  return (
    <Card>
      <ButtonBase sx={sxContainer} onClick={() => navigate(Path.ZoomSettings)}>
        <Box
          component="img"
          sx={sxLogo}
          src={Media.zoomLogo}
        />
        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={50} />
            : (
              isZoomConnected ? (
                <Typography>Zoom Connected</Typography>
              ) : (
                <Button>Zoom Sign In</Button>
              )
            )
        }
        <ChevronRightIcon />
      </ButtonBase>
    </Card>
  )
}

const sxLogo: SxProps = {
  width: 38,
}

const sxContainer: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}