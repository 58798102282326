import { useState } from 'react'
import { Box, Button, Popover, Grid, Typography, SxProps } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks/useStore'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { gColor, gSx } from '../../styles/Theme'
import { logInfo } from '../../log'

interface Props {
  isShowDate: boolean
}
export default observer(SearchEventDates)
function SearchEventDates({ isShowDate }: Props) {
  const store = useStore()
  const params = store.User.getEventSearchParams()
  const earliest = params.earliest ? dayjs(params.earliest) : null
  const latest = params.latest ? dayjs(params.latest) : null

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  const title = () => {
    const earliestTitle = earliest ? earliest.format('MMM DD, YYYY') : ''
    const latestTitle = latest ? latest.format('MMM DD, YYYY') : ''
    if (earliest === null && latest === null)
      return ''
    else if (earliest !== null && latest !== null) {
      return `${earliestTitle} - ${latestTitle}`
    } else if (latest !== null) {
      return `Latest  ${latestTitle}`
    }
    return `Earliest  ${earliestTitle}`
  }

  async function onEarliest(d: Dayjs | null) {
    logInfo('SearchEventDates onEarliest', { earliest: d?.toISOString() })
    store.User.setEventSearchParams({ ...params, earliest: d?.toISOString() })
  }

  async function onLatest(d: Dayjs | null) {
    logInfo('SearchEventDates onLatest', { latest: d?.toISOString() })
    store.User.setEventSearchParams({ ...params, latest: d?.toISOString() })
  }

  async function onClear() {
    logInfo('SearchEventDates onClear')
    store.User.setEventSearchParams({})
  }

  if (isShowDate == false)
    return null

  return (
    <>
      <Button
        onClick={(event: any) => setAnchorEl(event.currentTarget)}
        variant="outlined"
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        sx={{ borderColor: 'grey.400' }}
      >
        <Typography sx={sxTitle}>Date</Typography>
        <Typography sx={sxTitleDate}>{title()}</Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ backgroundColor: 'rgba(128, 128, 128, 0.5)' }}
      >
        <Box sx={{ padding: '1vh', backgroundColor: 'white' }}>

          <Grid container spacing={6}>
            <Grid item xs={6} >
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>Earliest</Typography>

              <DatePicker value={earliest} onChange={onEarliest} />
              {
                earliest === null && <Typography>Since the dawn of time</Typography>
              }

            </Grid>
            <Grid item xs={6}>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>Latest</Typography>

              <DatePicker value={latest} onChange={onLatest} />
              {
                latest === null && <Typography>Today</Typography>
              }

            </Grid>
          </Grid>

          <Box sx={{ paddingY: '1vh' }} />

          <Box sx={gSx.RowBetween}>
            {
              (earliest !== null || latest !== null) && (
                <Button
                  variant='outlined'
                  color="error"
                  onClick={onClear}
                >
                  Clear
                </Button>
              )
            }
            <Box sx={{ paddingX: '2vh' }} />
            <Button
              variant='outlined'
              onClick={() => setAnchorEl(null)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

const sxTitle: SxProps = {
  color: 'black',
}

const sxTitleDate: SxProps = {
  color: gColor.green500,
  fontSize: '13px',
  fontWeight: 'bold',
  paddingLeft: '10px'
}