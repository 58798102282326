import React from 'react'
import { SxProps, Box } from '@mui/material'
import { EventBase } from '../../client'
import EventTimeline from './EventTimeline'
import LongestMonologue from './LongestMonologue'
import SentimentCard from './SentimentCard'

interface Props {
  data?: EventBase
  disabled: boolean
}

export default function EventAnalysis({ data, disabled }: Props) {

  return (
    <Box sx={sxCard}>
      <SentimentCard event={data} disabled={disabled} />
      <EventTimeline data={data} />
      <LongestMonologue event={data} />
    </Box>
  )
}

const sxCard: SxProps = {
  overflowY: 'auto',
  overflowX: 'hidden',
}
