import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getEventTasks, getSpeakersAndAssignees } from 'service/eventApi'
import { Box, Checkbox, Typography } from '@mui/material'
import { ActionItem, CRM } from 'client'
import { auth } from 'service/api'
import { gSx } from 'styles/Theme'
import { parseFullName } from 'parse-full-name'
import SelectTasksByAssignee from './SelectTasksByAssignee'

export type MasterTask = ActionItem & {
  originalAssignee?: string
  isSelected: boolean;
};

interface Props {
  eventId: string
  masterTasks: MasterTask[]
  setMasterTasks: Dispatch<SetStateAction<MasterTask[]>>
  disabled: boolean
  crm: CRM
}

export default function SelectTasks({ eventId, masterTasks, setMasterTasks, disabled, crm }: Props) {
  const [selectAll, setSelectAll] = useState(false)

  const { data: initTasks, isLoading } = useQuery({
    queryKey: ['getEventTasks', eventId],
    queryFn: async () => await getEventTasks(eventId),
  })
  const tasksLabelledUnassigned = labelWithUnassigned(initTasks?.items_edited)
  const sortedOriginalAssignees = sortAssignees(masterTasks)

  useEffect(() => { // initialize master task
    if (initTasks?.items_edited) {
      const mt = tasksLabelledUnassigned.map(t => {
        const m: MasterTask = {
          ...t,
          originalAssignee: t.assignee,
          isSelected: t.assignee == auth.currentUser?.displayName, // check mark owner tasks
        }
        if (!m.assignee_info)
          m.assignee_info = { name: t.assignee }
        return m
      })
      setMasterTasks(mt)
    }
  }, [initTasks?.items_edited])

  const toggleSelectAll = () => {
    if (selectAll) {
      const updated = masterTasks.map(m => ({ ...m, isSelected: false }))
      setMasterTasks(updated)
      setSelectAll(false)
    } else {
      const updated = masterTasks.map(m => ({ ...m, isSelected: true }))
      setMasterTasks(updated)
      setSelectAll(true)
    }
  }

  useEffect(() => { // select all, auto
    const allSelected = masterTasks.every(task => task.isSelected)
    setSelectAll(allSelected)
  }, [masterTasks])

  if (isLoading || masterTasks.length == 0)
    return null

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ ...gSx.Row, padding: 2 }} >
        <Checkbox
          sx={{ padding: 0, marginRight: 1.5 }}
          checked={selectAll}
          onChange={toggleSelectAll}
          disabled={disabled}
        />
        <Typography>Select All</Typography>
      </Box>

      {
        sortedOriginalAssignees.map((originalAssignee) => (
          <div key={originalAssignee}>
            <SelectTasksByAssignee
              key={originalAssignee}
              originalAssignee={originalAssignee}
              masterTasks={masterTasks}
              setMasterTasks={setMasterTasks}
              disabled={disabled}
              crm={crm}
            />
          </div>
        ))
      }
    </Box>
  )
}

function sortAssignees(tasks: MasterTask[]) {
  const assignees = [...new Set(tasks.map((item) => item.originalAssignee))]
  const currentUser = parseFullName(auth.currentUser?.displayName ?? '')

  assignees.sort((a, b) => {
    const aName = parseFullName(a ?? '')
    const bName = parseFullName(b ?? '')

    if (currentUser.last && currentUser.last?.length > 0 && currentUser.first && currentUser.first?.length > 0) {
      if (aName.first == currentUser.first && aName.last == currentUser.last) return -1
      if (bName.first == currentUser.first && bName.last == currentUser.last) return 1
    }
    if (currentUser.last && currentUser.last?.length > 0) {
      if (aName.last == currentUser.last) return -1
      if (bName.last == currentUser.last) return 1
      if (aName.first == currentUser.last) return -1
      if (bName.first == currentUser.last) return 1
    }
    if (currentUser.first && currentUser.first?.length > 0) {
      if (aName.first == currentUser.first) return -1
      if (bName.first == currentUser.first) return 1
      if (aName.last == currentUser.first) return -1
      if (bName.last == currentUser.first) return 1
    }
    if (a === 'Unassigned') return 1
    if (b === 'Unassigned') return -1
    return 0
  })
  return assignees
}

function labelWithUnassigned(items?: ActionItem[]) {
  if (!items) return []

  return items.map((item) => {
    if (item.assignee === null) {
      return {
        ...item,
        assignee: "Unassigned",
        assignee_info: {
          name: "Unassigned",
        }
      }
    }
    return item
  })
}
