import React, { useState } from 'react'
import { config } from '../../service/api'
import { Alert, Box, Button, Card, Snackbar, TextField, Typography } from '@mui/material'
import { manualJoinAuth, manualSignInAuth } from '../../service/authApi'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

interface Props {
  loadingPath: string
}

export default function ManualSignUp({ loadingPath }: Props) {
  const navigate = useNavigate()
  const auth = getAuth()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  async function onJoin() {
    await manualJoinAuth(email, password)
    navigate(loadingPath)
  }

  async function onSignIn() {
    await manualSignInAuth(email, password)
    navigate(loadingPath)
  }

  async function onForgotPassword() {
    await sendPasswordResetEmail(auth, email)
    setOpenSuccessSnack(true)
  }

  if (config.env == 'prod')
    return null

  return (
    <>
      <Box sx={{ padding: '2vh' }} />
      <Card>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography>Dev only</Typography>
          <TextField
            onChange={(t) => setEmail(t.target.value)}
            id="email"
            label="Email"
            variant="filled"
            value={email}
          />
          <TextField
            onChange={(t) => setPassword(t.target.value)}
            id="password"
            label="Password"
            variant="filled"
            value={password}
          />

          <Box>
            <Button onClick={onJoin}>Signup</Button>
            <Button onClick={onSignIn}>SignIn</Button>
          </Box>

          <Button onClick={onForgotPassword}>Forgot Password</Button>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSuccessSnack}
          autoHideDuration={3000}
          onClose={() => setOpenSuccessSnack(false)}
        >
          <Alert
            variant="filled"
            severity="success"
          >
            {`Password reset email sent to ${email}`}
          </Alert>

        </Snackbar>
      </Card>
    </>
  )
}