import { Box, LinearProgress, SxProps, Typography } from '@mui/material'

export default function AuthRecallGoogleCallback() {
  const params = window.location.href.split('?')[1]
  const recallUrl = `https://api.recall.ai/api/v1/calendar/google_oauth_callback?${params}`

  window.location.href = recallUrl
  return (
    <Box sx={sxWrap}>
      <Box>
        <Typography>Authenticating</Typography>
        <LinearProgress />
      </Box>
    </Box>
  )
}

const sxWrap: SxProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
}