/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrgUserStatus {
    INVITED = 'Invited',
    ACTIVE = 'Active',
    ACCEPTED = 'Accepted',
    DECLINED = 'Declined',
    REVOKED = 'Revoked',
    INACTIVE = 'Inactive',
}
