import React from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { User } from 'firebase/auth'
import { auth } from 'service/api'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import UpgradeButton from './UpgradeButton'
import useIsEventOwnerBlocked from 'hooks/useIsEventOwnerBlocked'

interface Props {
  eventOwnerId?: string
}
export default function ExpiredPlanBanner({ eventOwnerId }: Props) {
  const { isBlocked: blockedPlan, isLoading } = useIsBlockedByPlan(true)

  const { isBlocked: blockedOwner, isLoading: isLoadingOwner } = useIsEventOwnerBlocked(eventOwnerId)

  if (isLoading || isLoadingOwner)
    return null

  if (blockedPlan)
    return (
      <Box sx={sxBanner}>
        <Typography sx={{ color: 'darkred', paddingRight: 3 }}>
          Your Plan has expired.
        </Typography>
        <UpgradeButton />
      </Box>
    )

  if (blockedOwner)
    return (
      <Box sx={sxBanner}>
        <Typography sx={{ color: 'darkred', paddingRight: 3 }}>
          {`The Meeting Owner's Plan has expired.`}
        </Typography>
      </Box>
    )

  return null
}

const sxBanner: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'lavenderblush',
  alignItems: 'center',
  marginBottom: 2,
  padding: 2,
  borderRadius: 3
}
