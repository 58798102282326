import React, { useEffect, useState } from 'react'
import { Typography, Box, Snackbar, Alert, LinearProgress, FormControl, Select, MenuItem } from '@mui/material'
import 'firebase/auth'
import { useQuery } from '@tanstack/react-query'
import { getRedtailSettings, setRedtailVisibility } from 'service/integrations/redtailApi'
import { RedtailVisibility } from 'client'
import { gSx } from 'styles/Theme'

export default function RedtailVisibilitySetting() {
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getRedtailSettings'],
    queryFn: getRedtailSettings,
  })
  const visibility = data?.visibility ?? RedtailVisibility.EVERYONE

  async function handleChange(newValue: RedtailVisibility) {
    setBusy(true)
    setErr(undefined)
    const res = await setRedtailVisibility(newValue)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setOpenSuccessSnack(true)
      await refetch()
    }
    setBusy(false)
  }

  return (
    <>
      <Box sx={gSx.RowBetween} >
        <Box>
          <Typography variant='h6'>Default Privacy</Typography>
          <Typography>Redtail Activity Tasks Privacy</Typography>
        </Box>
        <FormControl sx={{ width: 200 }}>
          {
            !isLoading &&
            <Select
              id="visibility"
              value={visibility}
              onChange={e => handleChange(e.target.value as RedtailVisibility)}
              disabled={busy}
            >
              <MenuItem value={RedtailVisibility.ONLY_ME}>{RedtailVisibility.ONLY_ME}</MenuItem>
              <MenuItem value={RedtailVisibility.ONLY_ATTENDEES}>{RedtailVisibility.ONLY_ATTENDEES}</MenuItem>
              <MenuItem value={RedtailVisibility.EVERYONE}>{RedtailVisibility.EVERYONE}</MenuItem>
            </Select>
          }

        </FormControl>
      </Box>

      {busy &&
        <Box sx={{ width: '100%', paddingY: '1vh' }}>
          <LinearProgress />
        </Box>
      }

      {err &&
        <Typography color={'tomato'} sx={{ paddingVertical: 2 }}>
          {err}
        </Typography>
      }

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Redtail Privacy Updated
        </Alert>

      </Snackbar>
    </>
  )
}
