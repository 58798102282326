import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { BankAccount } from '../../client'

interface Props {
  eventId?: string
  bankAccountList?: BankAccount[]
}

export default function BankAccountsTable({ eventId, bankAccountList }: Props) {

  const cellRef = useRef(null)
  const [data, setBankAccountList] = useState<BankAccount[]>([])

  useEffect(() => {
    if (bankAccountList) {
      console.log("bankAccountList", bankAccountList)
      setBankAccountList(bankAccountList)
    }
  }, [bankAccountList])

  const columns = useMemo<MRT_ColumnDef<BankAccount>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        size: 100
      },
      {
        accessorKey: 'Primary',
        header: 'Primary',
        size: 100
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        size: 100
      },
      {
        accessorKey: 'Average Balance',
        header: 'Average Balance',
        size: 100
      },
      {
        accessorKey: 'Bank Name',
        header: 'Bank Name',
        size: 100
      },
      {
        accessorKey: 'Bank Address',
        header: 'Bank Address',
        size: 100
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data,
  })

  return (
    <MRT_Table table={table} />
  )
}
