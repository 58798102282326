import React from 'react'
import { Typography, Box, Tooltip } from '@mui/material'
import 'firebase/auth'
import { FlowInput } from 'client'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { gSx } from 'styles/Theme'

interface Props {
  input: FlowInput
}
export default function SalesforceFlowTaskTitle({ input }: Props) {

  return (
    <Box>
      <Box sx={gSx.Row}>
        <Typography>{input.name}</Typography>
        <Box sx={{ paddingX: 0.5 }} />
        <Tooltip title={`Task Assignees and Due date are defined in the above section "Select Tasks"`}>
          <InfoOutlinedIcon fontSize='small' />
        </Tooltip>
      </Box>

    </Box>
  )
}