/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ModuleIdentifier } from '../models/ModuleIdentifier';
import type { SubPromptTemplate } from '../models/SubPromptTemplate';
import type { UserConfigModuleBase } from '../models/UserConfigModuleBase';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomizationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Custom Followup Email
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public getCustomFollowupEmail(): CancelablePromise<ModuleIdentifier> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customizations/followup/mail',
        });
    }

    /**
     * Process Custom Followup Email
     * @param eventId
     * @param isRerun
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public processCustomFollowupEmail(
        eventId: string,
        isRerun: boolean = false,
        requestBody?: ModuleIdentifier,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customizations/followup/mail/process',
            query: {
                'event_id': eventId,
                'is_rerun': isRerun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subprompt Template
     * @param subpromptTemplateId
     * @returns SubPromptTemplate Successful Response
     * @throws ApiError
     */
    public getSubPromptTemplate(
        subpromptTemplateId: string,
    ): CancelablePromise<SubPromptTemplate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customizations/template',
            query: {
                'subprompt_template_id': subpromptTemplateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Available Meeting Type Sections
     * @returns SubPromptTemplate Successful Response
     * @throws ApiError
     */
    public getAvailableMeetingTypeSections(): CancelablePromise<Array<SubPromptTemplate>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customizations/meeting-types/sections/available',
        });
    }

    /**
     * Get Meeting Types Module
     * meeting type
     * @param moduleId
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public getMeetingTypesModule(
        moduleId: string,
    ): CancelablePromise<ModuleIdentifier> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customizations/meeting-types/module',
            query: {
                'module_id': moduleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Meeting Types Module
     * meeting type
     * @param moduleId
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public deleteMeetingTypesModule(
        moduleId: string,
    ): CancelablePromise<ModuleIdentifier> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/customizations/meeting-types/module',
            query: {
                'module_id': moduleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Meeting Types Module
     * update meeting type
     * @param requestBody
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public updateMeetingTypesModule(
        requestBody: ModuleIdentifier,
    ): CancelablePromise<ModuleIdentifier> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/customizations/meeting-types/module',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * New Meeting Types Module
     * new meeting type
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public newMeetingTypesModule(): CancelablePromise<ModuleIdentifier> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customizations/meeting-types/module/new',
        });
    }

    /**
     * Get Available Meeting Types
     * All available meeting types for this user
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public getAvailableMeetingTypes(): CancelablePromise<Array<ModuleIdentifier>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customizations/meeting-types/available',
        });
    }

    /**
     * Get Favorite Meeting Types
     * For event page meeting type selection
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public getFavoriteMeetingTypes(): CancelablePromise<Array<ModuleIdentifier>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customizations/meeting-types/favorites',
        });
    }

    /**
     * Duplicate Meeting Types Module
     * duplicate meeting type
     * @param moduleId
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public duplicateMeetingTypesModule(
        moduleId: string,
    ): CancelablePromise<ModuleIdentifier> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customizations/meeting-types/duplicate',
            query: {
                'module_id': moduleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Configs
     * @returns UserConfigModuleBase Successful Response
     * @throws ApiError
     */
    public getConfigs(): CancelablePromise<UserConfigModuleBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/customizations/configs',
        });
    }

    /**
     * Update Configs
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateConfigs(
        requestBody: UserConfigModuleBase,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/customizations/configs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
