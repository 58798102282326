import { PreciseFpBase } from "client"
import { logErr, logInfo } from "log"
import { Api, auth } from "../api"
import { getErrorMessage } from "utils"

export async function getPreciseFpConfig(): Promise<PreciseFpBase | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    return await api.precisefp.getPreciseFpConfig()
  } catch (e) {
    logErr('getPreciseFpConfig', { e })
    throw e
  }
}