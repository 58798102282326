import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { FinmateSubscription, OrgBase, OrgUser, ProductName, StripeCustomer } from "client"
import React from "react"
import { getOrgUser } from "service/orgsApi"
import { getOrgUserPrice } from "service/payApi"

interface Props {
  target: OrgUser
  org?: OrgBase
  sub?: FinmateSubscription
  customer?: StripeCustomer
}
const SwapPayingSeatInfo = ({ target, org, sub, customer }: Props) => {
  const original = getOrgUser(org, target.uid)

  const nameEmail = target?.name ? `${target?.name}, ${target?.email}` : `${target?.email}`

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
      <Typography variant='h6'>Billing Change</Typography>

      <Typography variant="caption">
        {nameEmail} will change from {original?.org_user_plan} plan to {target?.org_user_plan} plan
      </Typography>

      <Typography variant="caption">
        This will remove a {original?.org_user_plan} seat, and add a {target?.org_user_plan} seat to your subscription.
      </Typography>

      <TableContainer >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow >
              <TableCell component="th" scope="row" sx={{ color: 'darkred' }}>
                {original?.org_user_plan} Plan
              </TableCell>
              <TableCell align={'right'} sx={{ color: 'darkred' }}>
                -${getOrgUserPrice(sub, original)} / month
              </TableCell>
            </TableRow>

            <TableRow >
              <TableCell component="th" scope="row" sx={{ color: 'darkgreen' }}>
                {target?.org_user_plan} Plan
              </TableCell>
              <TableCell align={'right'} sx={{ color: 'darkgreen' }}>
                +${getOrgUserPrice(sub, target)} / month
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

      {customer?.discount &&
        <Typography variant='caption'>Pre-discount price is shown. Your discount will be applied upon saving the change.</Typography>
      }

    </Card>
  )
}

export default SwapPayingSeatInfo
