import React, { useState } from 'react'
import { Box, IconButton, SxProps, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { PageBase } from 'client'
import { logInfo } from 'log'

interface Props {
  page: number
  setPage: (page: number) => void
  pageRange?: PageBase
}
export default function PaginationTitle({ page, setPage, pageRange }: Props) {

  const earliestIso = pageRange && pageRange.earliest_paginate
  const latestIso = pageRange && pageRange.latest_paginate
  const earliest = earliestIso && (new Date(earliestIso)).toDateString()
  const latest = latestIso && (new Date(latestIso)).toDateString()

  async function onPrev() {
    logInfo('Event List, title search previous button pressed', { page, pageRange })
    if (page >= 2) {
      setPage(page - 1)
    }
  }

  async function onNext() {
    logInfo('Event List, title search next button pressed', { page, pageRange })
    setPage(page + 1)
  }

  return (
    <>
      <Box sx={row}>

        <IconButton
          onClick={onPrev}
          disabled={page == 1}
        >
          <ArrowBackIcon />
        </IconButton>

        <Box sx={{ paddingX: 1 }} />
        <Typography>Page {page}</Typography>
        <Box sx={{ paddingX: 1 }} />

        <IconButton onClick={onNext}>
          <ArrowForwardIcon />
        </IconButton>

      </Box>
      {
        page == 1
          ? <Typography align='center'>Past 3 months</Typography>
          : (earliest && latest &&
            <Typography align='center'>{`${earliest} - ${latest}`}</Typography>
          )
      }

    </>
  )
}

const row: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}