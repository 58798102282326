import React, { useEffect } from 'react'

import { useMeasure } from 'react-use'
import { useParams } from 'react-router-dom'

import Grid from '@mui/material/Unstable_Grid2'
import { useQuery } from '@tanstack/react-query'
import { Box, SxProps, Tab, Typography } from '@mui/material'
import TimelineIcon from '@mui/icons-material/Timeline'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import ContactMailIcon from '@mui/icons-material/ContactMail'

import { logInfo } from '../log'
import { Api, config } from '../service/api'
import { useStore } from '../hooks/useStore'
import Video from '../components/event/Video'
import EventGuide from '../components/help/EventGuide'
import EventTitle from '../components/event/EventTitle'
import EventDetails from '../components/event/EventDetails'
import EventAnalysis from '../components/event/EventAnalysis'
import ClientCard from '../components/client/ClientCardForEvent'
import TranscriptEditable from '../components/event/TranscriptEditable'
import EventFollowupEmail from '../components/event/EventFollowupEmail'

export default function PublicEventScreen() {
  const { id } = useParams()
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const [tabValue, setTabValue] = React.useState('1')
  const store = useStore()

  useEffect(() => {
    logInfo('Public Event Screen', {
      event_id: id,
      bot_id: id,
    })
    store.System.setEventPublicTourStep(0)
  }, [])

  const { data, isLoading } = useQuery({
    queryKey: ['getPublicEvent' + id],
    queryFn: runQuery,
  })
  async function runQuery() {
    let eventId = id
    if (!eventId) {
      if (config.env === 'prod')
        eventId = '4e7da38f-763c-4288-8e4c-bfad46496754'
      else
        eventId = '268dc2d6-7775-4fd5-a801-d7428152969c'
    }

    const api = await Api()
    return await api.public.getPublicEvent(eventId)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Grid container spacing={'2vh'}>
        <Grid xs={12} md={8}>

          <Typography sx={{ paddingLeft: '50px' }}>Public example. Some features have been disabled.</Typography>

          <EventTitle data={data} disabled={true} />
          <TabContext value={tabValue}>
            <TabList sx={sxTabList} onChange={handleChange}>
              <Tab
                sx={sxTabList}
                iconPosition='start'
                icon={<TextSnippetIcon />}
                label='Details'
                value='1'
              />
              <Tab
                sx={sxTabList}
                iconPosition='start'
                icon={<TimelineIcon />}
                label='Analytics'
                value='2'
              />
              <Tab
                sx={sxTabList}
                iconPosition='start'
                icon={<ContactMailIcon />}
                label='Follow-up'
                value='3'
              />
            </TabList>

            <TabPanel sx={sxTabPanel} value='1'>
              <Box>
                <EventDetails
                  eventId={id ?? ''}
                  data={data}
                  isEventMetaDataLoading={isLoading}
                  isPublic
                  disabled={true}
                />
              </Box>
            </TabPanel>
            <TabPanel sx={sxTabPanel} value='2'>
              <EventAnalysis data={data} disabled={true} />
            </TabPanel>
            <TabPanel sx={sxTabPanel} value='3'>
              <EventFollowupEmail
                eventId={id ?? ''}
                isPublic
                disabled={true}
              />
            </TabPanel>
          </TabContext>
        </Grid>
        <Grid xs={12} md={4} ref={ref}>
          <ClientCard event={data} disabled={true} />
          <TranscriptEditable eventId={id ?? ''} isPublic disabled={true} />
          <Video eventId={id ?? ''} width={width} isPublic />
        </Grid>
      </Grid>

      <EventGuide setTabValue={setTabValue} />
    </>
  )
}

const sxTabPanel: SxProps = {
  padding: '0',
}

const sxTabList: SxProps = {
  minHeight: '30px',
}
