/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailBase } from '../models/EmailBase';
import type { NotificationBase } from '../models/NotificationBase';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommunicationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Email Meeting Details
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public emailMeetingDetails(
        requestBody: EmailBase,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/communications/email/meeting_details',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Fcm Token
     * @param fcmToken
     * @returns any Successful Response
     * @throws ApiError
     */
    public setFcmToken(
        fcmToken: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/communications/fcm',
            query: {
                'fcm_token': fcmToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Notifications
     * @returns NotificationBase Successful Response
     * @throws ApiError
     */
    public getNotifications(): CancelablePromise<Array<NotificationBase>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/communications/notifications',
        });
    }

    /**
     * Read All Notifications
     * @returns any Successful Response
     * @throws ApiError
     */
    public readAllNotifications(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/communications/notifications/all',
        });
    }

    /**
     * Clear All Notifications
     * @returns any Successful Response
     * @throws ApiError
     */
    public clearAllNotifications(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/communications/notifications/all',
        });
    }

}
