import { useEffect, useState } from "react"
import { Box, Button, SxProps, Typography, useMediaQuery } from "@mui/material"
import GuideBoxWithArrow from "./GuideBoxWithArrow"
import { observer } from "mobx-react-lite"
import { useStore } from "../../hooks/useStore"
import EventGuideBackNext from "./EventGuideBackNext"
import { AnyNsRecord } from "dns"
import { drawerWidth } from "../../routes"
import useWindowWidth from "hooks/useWindowWidth"

interface Props {
  setTabValue: (number: string) => void
}
export default observer(EventGuide1)
function EventGuide1({ setTabValue }: Props) {
  const store = useStore()
  const step = store.System.getEventPublicTourStep()
  const windowWidth = useWindowWidth()

  const isXS = useMediaQuery('(max-width:900px)')
  if (isXS)
    return null

  if (step === 0) {
    const sxGuideIntro: SxProps = {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '550px'
    }
    return (
      <Box sx={sxGuideIntro}>
        <GuideBoxWithArrow>
          <Typography variant='h3'>FinMate AI Product Tour</Typography>
          <Box sx={{ padding: '1vh' }} />
          <Typography>Welcome! Ready to save hours a week?</Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>After signing up, Finmate AI will join your meetings.
          </Typography>

          <EventGuideBackNext />
        </GuideBoxWithArrow>
      </Box>
    )
  }

  if (step === 1) {
    store.User.setSeekSeconds(0)
    store.User.setTranscriptSeekSeconds(0)
    setTabValue('1')
    const sxGuide1: SxProps = {
      position: 'absolute',
      zIndex: 1,
      top: '40px',
      left: '290px',
      width: '500px'
    }
    return (
      <Box sx={sxGuide1}>
        <GuideBoxWithArrow arrow='left'>
          <Typography variant='h3'>Auto Generated Financial Map and Tasks</Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'FinMateAI automatically capture the client\'s financial map, enabling advisors to focus on selling.'}</Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'This isn\'t any generic output. Our structured and labelled output to import easily into your workflow (ie. WealthBox, AssetMap).'}</Typography>

          <EventGuideBackNext />
        </GuideBoxWithArrow>
      </Box>
    )
  }

  if (step === 2) {
    store.User.setSeekSeconds(1000)
    store.User.setTranscriptSeekSeconds(1000)
    const sxGuideTranscript: SxProps = {
      position: 'absolute',
      zIndex: 1,
      top: '170px',
      right: .9 * (1 / 3) * (windowWidth - drawerWidth),
      width: '500px'
    }

    return (
      <Box sx={sxGuideTranscript}>
        <GuideBoxWithArrow arrow='right'>
          <Typography variant='h3'>Transcript and Details are all connected</Typography>
          <Box sx={{ padding: '1vh' }} />
          <Typography>{'Easily locate the source of information. The Transcript and Meeting Details each have timestamp links to where in the video the information was captured.'}</Typography>
          <Box sx={{ padding: '2vh' }}></Box>
          <Typography>{'How To Use: Start playing the video, and select a timestamp from the details or transcript section'}</Typography>
          <EventGuideBackNext />
        </GuideBoxWithArrow>
      </Box>
    )
  }

  if (step === 3) {
    setTabValue('2')
    const sxGuideAnalytics: SxProps = {
      position: 'absolute',
      zIndex: 1,
      top: '250px',
      left: '290px',
      width: '500px'
    }
    return (
      <Box sx={sxGuideAnalytics}>
        <GuideBoxWithArrow arrow='top'>
          <Typography variant='h3'>Gain the Competitive Insight</Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'Capture your interaction at a high level to better engage in your client.'}
          </Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'Check for monologues and adjust to ensure clients remain highly engaged.'}
          </Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>Hint: Hover your mouse over the timeline to go to the part in the transcript and video</Typography>

          <EventGuideBackNext />
        </GuideBoxWithArrow>
      </Box>
    )
  }

  if (step === 4) {
    setTabValue('3')
    const sxGuideAnalytics: SxProps = {
      position: 'absolute',
      zIndex: 1,
      top: '40px',
      left: '560px',
      width: '500px'
    }
    return (
      <Box sx={sxGuideAnalytics}>
        <GuideBoxWithArrow arrow='left'>
          <Typography variant='h3'>We help you on follow-up email!</Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'FinMateAI automatically crafts a follow-up email after each meeting to save you time.'}
          </Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'Quickly edit and personalize them on FinMateAI before sending to your clients.'}
          </Typography>

          <EventGuideBackNext />
        </GuideBoxWithArrow>
      </Box>
    )
  }

  if (step === 5) {
    setTabValue('1')
    const sxGuideAnalytics: SxProps = {
      position: 'absolute',
      zIndex: 1,
      top: '120px',
      left: '160px',
      width: '550px'
    }
    return (
      <Box sx={sxGuideAnalytics}>
        <GuideBoxWithArrow arrow='left'>
          <Typography variant='h3'>{'Free Trial'}</Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'There\'s more to explore with FinMate. Signup today.'}
          </Typography>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'We are here to help. Let us know if you have any questions.'}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2vh' }}>
            <Button variant='contained' onClick={() =>
              window.open('https://zcal.co/danielfinmate/finmate-intro', '_blank')
            }>
              Book a Demo
            </Button>
          </Box>

          <Box sx={{ padding: '1vh' }} />
          <Typography>{'- FinMate AI Team'}
          </Typography>

          <EventGuideBackNext last />
        </GuideBoxWithArrow>
      </Box>
    )
  }

  return null
}
