import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useQuery } from '@tanstack/react-query'
import { Box, Button, Dialog, IconButton, LinearProgress, SxProps, Typography, Skeleton } from '@mui/material'
import { logInfo } from '../../log'
import SubscriptionCancelDialog from './SubscriptionCancelDialog'
import { getSubscription, modifyPayment } from "../../service/payApi"

interface Props {
  open: boolean
  onClose: (refresh?: boolean) => void
}

export default function EditSubscriptionDialog({ open, onClose }: Props) {
  const [busy, setBusy] = useState(false)
  const [showErr, setShowErr] = useState(false)
  const [cancelConfirmDialog, setCancelConfirmDialog] = useState(false)

  useEffect(() => {
    logInfo('EditSubscriptionDialog')
  }, [])

  async function onPayment() {
    setBusy(true)
    setShowErr(false)
    try {
      const res = await modifyPayment()
      if (res?.session_url)
        window.location.href = res?.session_url
    } catch (e) {
      setShowErr(true)
    }
    setBusy(false)
  }

  const { data: sub, isLoading: isLoadingSub } = useQuery({
    queryKey: ['getSubscription'],
    queryFn: getSubscription,
  })

  if (isLoadingSub || !sub || !sub?.subscription) {
    return (
      <Dialog open={open} onClose={() => onClose()} sx={sxDialog}>
        <Skeleton variant="rectangular" width={'100%'} height={50} />
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={() => onClose()} sx={sxDialog}>
      <IconButton sx={sxClose} aria-label='close' onClick={() => onClose()}>
        <CloseIcon />
      </IconButton>

      <Box sx={sxBox}>
        <Typography variant='h2'>Edit Subscription</Typography>
        <Typography variant='caption'>
          As an admin of your organization, you can change subscription.
        </Typography>
        <Box sx={{ padding: '1vh' }}></Box>

        <Button
          variant='contained'
          onClick={onPayment}
          disabled={busy}
          aria-label='Change Payment Method'
        >
          Change Payment Method
        </Button>

        <Box sx={{ padding: 2 }} />
        <Button
          color='warning'
          variant='outlined'
          onClick={() => { setCancelConfirmDialog(true) }}
          disabled={busy}
          aria-label='Cancel Subscription'
        >
          Cancel Subscription
        </Button>

      </Box>

      {
        showErr &&
        <>
          <Box sx={{ padding: '1vh' }}></Box>
          <Typography color={'red'}>Something went wrong, try again. if issue persists, contact support@finmate.ai</Typography>
        </>
      }

      {
        busy &&
        <>
          <Box sx={{ padding: '1vh' }}></Box>
          <LinearProgress />
        </>
      }

      <SubscriptionCancelDialog
        open={cancelConfirmDialog}
        closeDialog={() => { setCancelConfirmDialog(false) }}
        onClose={onClose}
        sub={sub?.subscription}
      />
    </Dialog >
  )
}

const sxDialog: SxProps = {
  '& .MuiDialog-paper': {
    width: 'fit-content', // Makes the width of the dialog fit its content
    height: 'fit-content', // Makes the height of the dialog fit its content
    maxWidth: 'none', // Removes the maximum width constraint
    maxHeight: 'none', // Removes the maximum height constraint
    overflow: 'visible' // Ensures that content isn't clipped or scrollable
  }
}

const sxBox: SxProps = {
  paddingX: '3vh',
  paddingBottom: '3vh',
  maxWidth: '1000px',
  display: 'flex',
  flexDirection: 'column',
}

const sxClose: SxProps = {
  alignSelf: 'flex-end',
}
