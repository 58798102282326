import React from 'react'
import { Box, Tooltip, SxProps } from '@mui/material'
import { ClientDetail, ClientIndex, SalesforceApp } from 'client'
import { getSalesforceIcon, getSalesforceSettings } from 'service/integrations/salesforceApi'
import { Media } from 'service/media'
import { useQuery } from '@tanstack/react-query'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import { getWealthboxProfile } from 'service/integrations/wealthboxApi'

interface Props {
  client?: ClientIndex | ClientDetail
}

export function CrmClientBadge({ client }: Props) {

  const { data: sf } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings, enabled: false,
  })
  const salesforceApp = sf?.app ?? SalesforceApp.SALESFORCE
  const isSalesforceSignedIn = !!sf?.sf_uid

  const { data: rt } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings, enabled: false
  })
  const isRedtailSignedIn = !!rt?.user_id

  const { data: wb } = useQuery({
    queryKey: ['getWealthboxProfile'], queryFn: getWealthboxProfile, enabled: false
  })
  const isWealthboxSignedIn = !!wb?.id

  if (!client)
    return

  return (
    <Box>
      {
        isRedtailSignedIn && client.redtail_id &&
        <Tooltip title={`Redtail Contact (ID: ${client.redtail_id})`}>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.redtailIconWhite}
            alt="Redtail"
          />
        </Tooltip>
      }

      {
        isWealthboxSignedIn && client.wealthbox_id &&
        <Tooltip title={`Wealthbox Contact (ID: ${client.wealthbox_id})`}>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.wealthboxIcon}
            alt="Wealthbox"
          />
        </Tooltip>
      }

      {
        isSalesforceSignedIn && client.salesforce_id &&
        <Tooltip title={`${salesforceApp} Contact (ID: ${client.salesforce_id})`}>
          <Box
            component="img"
            sx={sxIcon}
            src={getSalesforceIcon(salesforceApp)}
            alt="Salesforce"
          />
        </Tooltip>
      }
    </Box>
  )
}

const sxIcon: SxProps = {
  marginLeft: 1,
  width: 25,
  height: 25,
}