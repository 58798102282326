import React, { useState } from 'react'
import { Typography, Skeleton, IconButton } from '@mui/material'
import { FinmateSubscription, OrgBase } from 'client'
import Grid from '@mui/material/Unstable_Grid2'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import EditSubscriptionDialog from '../../components/pay/EditSubscriptionDialog'
import { useQueryClient } from '@tanstack/react-query'
import { isAdminRole } from 'service/orgsApi'

interface Props {
  org?: OrgBase
  sub?: FinmateSubscription
  isLoading: boolean
}
export default function SeatsPurchased({ sub, org, isLoading }: Props) {
  const queryClient = useQueryClient()
  const isAdmin = isAdminRole(org)
  const isOrgSubscribed = !!sub?.subscription
  const [showEditDialog, setShowEditDialog] = useState(false)

  async function onEditClose(refresh?: boolean) {
    if (refresh) {
      // update all
      queryClient.invalidateQueries(['getOrg'])
      queryClient.invalidateQueries(['getSubscription'])
      queryClient.invalidateQueries(['getSubInfo'])
    }
    setShowEditDialog(false)
  }

  function Numbers() {
    if (isLoading || !sub)
      return <Skeleton variant="rectangular" width={'100%'} height={40} />

    return (
      <>
        <Typography>{sub.standard?.quantity ?? 0}</Typography>
        <Typography>{sub.starter?.quantity ?? 0}</Typography>
        <Typography>{sub.lite?.quantity ?? 0}</Typography>
      </>
    )
  }

  return (
    <>
      <Grid container>
        <Grid xs={5}>
          <Typography variant='h6'>Seats Purchased</Typography>
        </Grid>
        <Grid xs={5}>
          <Typography>Standard Plan</Typography>
          <Typography>Starter Plan</Typography>
          <Typography>Lite Plan</Typography>
        </Grid>

        {isOrgSubscribed && isAdmin ? (
          <>
            <Grid xs={1}>
              <Numbers />
            </Grid>
            < Grid xs={1}>
              <IconButton onClick={() => setShowEditDialog(true)}>
                <MoreHorizIcon />
              </IconButton>
            </Grid>
          </>
        ) : (
          <Grid xs={2}>
            <Numbers />
          </Grid>
        )}

      </Grid>
      <EditSubscriptionDialog
        open={showEditDialog}
        onClose={onEditClose}
      />
    </>
  )
}