import React, { useState, useEffect } from 'react'
import { Box, Typography, Chip, SxProps } from '@mui/material'
import ProfilePhoto from 'components/settings/ProfilePhoto'
import { gSx } from '../../styles/Theme'
import { Ownership, FirebaseUser } from '../../client'
import { Media } from 'service/media'
import { getFirebaseUser } from 'service'

interface TemplateOwnerProps {
  ownership?: Ownership
}

const TemplateOwner: React.FC<TemplateOwnerProps> = ({ ownership }) => {
  const [owner, setOwner] = useState<FirebaseUser | undefined>()

  useEffect(() => {
    if (ownership) {
      if (ownership?.owner_id) {
        getFirebaseUser(ownership?.owner_id)
          .then((res) => {
            setOwner(res)
          })
      }
    }
  }, [ownership])

  return (
    <>
      {ownership?.is_finmate_owned ? (
        <Box display="flex" alignItems="center">
          <Typography sx={sxText}>Made by </Typography>
          <Box sx={{ paddingX: 0.5 }} />
          <Box component='img' alt='Finmate AI logo' sx={sxIcon} src={Media.logo100} />
          <Typography sx={sxText}>Finmate AI</Typography>
        </Box>
      ) : (
        <Box sx={gSx.Row}>
          <Typography sx={sxText}>Made by </Typography>
          <Box sx={{ paddingX: 0.5 }} />
          <Chip
            avatar={<ProfilePhoto photoUrl={owner?.photo_url} width={25} />}
            label={owner?.display_name ?? owner?.email}
            variant="outlined"
            sx={{ paddingX: 0.5 }}
          />
        </Box>
      )}
    </>

  )
}

const sxIcon: SxProps = {
  width: 15,
  height: 20,
  marginRight: 1,
}

const sxText: SxProps = {
  fontFamily: 'Roboto, sans-serif', // Use your desired font family
  fontWeight: 'bold', // You can set the font weight
  fontSize: '16px', // Set the font size
  color: '#777' // Set the text color
}

export default TemplateOwner
