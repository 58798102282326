import React, { useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { OrgInvite } from '../../../client'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

export default function AddShareSentSnack({ setOpen, open }: Props) {

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        icon={<CheckIcon fontSize="inherit" />}
        severity="success"
      >
        {`Permissions Updated`}

      </Alert>

    </Snackbar>
  )
}