import { logErr, logInfo } from "../../log"
import { Api, config } from "../api"

export function zoomAuthUrl() {
  const baseUrl = "https://zoom.us/oauth/authorize"
  const queryParams = {
    response_type: "code",
    redirect_uri: config.zoomRedirect,
    client_id: config.zoomClientId,
  }
  const queryString = new URLSearchParams(queryParams).toString()
  const url = `${baseUrl}?${queryString}`
  return url
}
