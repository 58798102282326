import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Typography, FormControlLabel, Divider, RadioGroup, Radio } from '@mui/material'
import 'firebase/auth'
import { ActionItem, FlowInput, FlowInputConfig } from 'client'
import { MasterTask } from '../SelectTasks'
import Grid from '@mui/material/Unstable_Grid2'
import SalesforceFlowTaskTitle from './SalesforceFlowTaskTitle'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'
import { useQuery } from '@tanstack/react-query'

interface Props {
  input: FlowInput
  setFlowInputs: Dispatch<SetStateAction<FlowInputConfig[]>>
  tasks: MasterTask[]
}

export default function SalesforceFlowTask({ input, tasks, setFlowInputs }: Props) {
  const [selectedTask, setSelectedTask] = useState<ActionItem | undefined>()

  const { data } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings, enabled: false
  })

  const onChange = (task: MasterTask) => (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedTask(task)
      const newOutput: FlowInputConfig = { input, tasks: [task] }
      setFlowInputs(prev => [...prev.filter(o => o.input !== input), newOutput])
    }
  }

  return (
    <Grid container>
      <Grid xs={4}>
        <SalesforceFlowTaskTitle input={input} />
        <Typography variant='caption' color={!selectedTask ? 'tomato' : 'black'}>Select one</Typography>
      </Grid>
      <Grid xs={8}>

        <RadioGroup>
          {tasks.map(task => {
            const date = new Date(task.due_date_datetime ?? '')
            const assignee = () => {
              if (task.assignee_info?.salesforce_user_id)
                return task.assignee_info.name
              return data?.name ?? ''
            }
            return (
              <FormControlLabel
                key={task.id}
                control={
                  <Radio
                    checked={selectedTask?.id == task.id}
                    onChange={onChange(task)}
                  />
                }
                label={`${task.description} (Assignee: ${assignee()}, Due Date: ${date.toDateString()})`}
                sx={{ paddingY: 0.5 }}
              />
            )
          })}
        </RadioGroup>

      </Grid>
      <Grid xs={12}>
        <Divider sx={{ marginY: 1 }} />
      </Grid>
    </Grid>
  )
}