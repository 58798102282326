import React, { useEffect, useState } from 'react'
import { convert } from 'html-to-text'
import { EditorState } from 'prosemirror-state'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import EditIcon from '@mui/icons-material/Edit'
import { useQuery } from '@tanstack/react-query'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { EditorContent, useEditor } from '@tiptap/react'
import { Box, Card, CardContent, IconButton, Skeleton, SxProps, Tooltip, Typography, CardHeader } from '@mui/material'
import { logInfo } from '../../log'
import { Api, auth, config } from '../../service/api'
import { EditorMenuBar } from '../editor/EditorMenuBar'
import { EventBase } from '../../client'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import TimestampComponent from "../tiptap/TranscriptLinkExtension"
import '../editor/Editor.css'

interface Props {
  eventId?: string
  isModuleSelected?: boolean
  eventMetaData?: EventBase
  isPublic?: boolean
}
export default function EventCondensates({ eventId, isModuleSelected, eventMetaData, isPublic }: Props) {
  const edittedTimer: any = null // Declare timer outside the function scope
  const fbUser = auth.currentUser
  const [isEdit, setEdit] = useState(false)

  const { data: eventCondensates, isLoading: isEventCondensatesLoading } = useQuery({
    queryKey: ['getEventCondensates', eventId],
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
  })
  async function runQuery() {
    if (eventId) {
      const api = await Api()
      const res = await api.events.getEventCondensates(eventId)
      console.log("useQuery EventCondensates", res)
      return res
    }
  }
  const [edittedHtml, setEdittedHtml] = useState("")

  const editor = useEditor({
    extensions: [
      StarterKit,
      CharacterCount,
      TimestampComponent
    ],
    editable: false,
    content: edittedHtml,
    editorProps: {
      attributes: {
        class: 'custom-editor-class',
        style: 'outline-color: #45CBDC; padding: 8px' // Apply styles directly
      }
    },
  })

  const [copyTooltip, setCopyTooltip] = useState('Copy to clipboard')
  let copyTooltipTimer: any = null

  useEffect(() => {
    editor?.setEditable(isEdit)
  }, [isEdit])

  useEffect(() => {
    if (edittedHtml) {
      editor?.commands.setContent(edittedHtml)
    }
  }, [edittedHtml])

  useEffect(() => {
    if (eventCondensates) {
      setEdittedHtml(String(eventCondensates))
      console.log("useEffect eventCondensates edittedHtml")
    }
  }, [eventCondensates])

  function copyText() {
    let textHtml = String(editor?.getHTML())
    textHtml = textHtml.replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, "<li>$1<$2$3>")

    const plainText = convert(textHtml, {
      wordwrap: false, selectors: [
        { selector: 'h3', options: { uppercase: false } },
        { selector: 'ul', options: { itemPrefix: '  • ' } }]
    })
    navigator.clipboard.writeText(plainText)
    setCopyTooltip('Copied!')
    if (copyTooltipTimer) {
      clearTimeout(copyTooltipTimer)
    }
    copyTooltipTimer = setTimeout(async () => {
      setCopyTooltip('Copy to clipboard')
    }, 2500)
    logInfo("Summary Copied", { event_id: eventMetaData?.id, bot_id: eventMetaData?.id, module_name: eventMetaData?.module_name })
  }

  async function closeEdit() {
    if (edittedTimer != null) {
      // clear current timer if exist
      clearTimeout(edittedTimer)
    }
    await saveEventSummary()
    setEdit(false)
  }

  async function saveEventSummary() {
    if (eventId && editor) {
      const eventSummaryHtml = editor.getHTML()
      const api = await Api()
      await api.events.editEventSummaryHtml(eventId, eventSummaryHtml)
      console.log('saveEventSummary')
      setEdittedHtml(eventSummaryHtml)
    }
  }

  function setEditMode() {
    setEdit(!isEdit)
    if (editor) {
      // reset history
      const newEditorState = EditorState.create({
        doc: editor.state.doc,
        plugins: editor.state.plugins,
        schema: editor.state.schema
      })
      editor.view.updateState(newEditorState)
      editor.commands.focus("start")
    }
  }

  const skeletonHeight = 20
  if (isEventCondensatesLoading)
    return (
      <Card sx={sxCard}>
        <Box sx={{ padding: '2vh' }} />
        <Skeleton variant="rectangular" width={'20%'} height={skeletonHeight} />
        <Box sx={{ padding: '2vh' }} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Box sx={{ padding: '2vh' }} />
        {
          isModuleSelected ? (
            <>
              <Typography textAlign={'center'}>To generate a follow-up email, select a <span style={{ color: '#2a7e8a' }}><b>Meeting Type</b></span> in the <span style={{ color: '#2a7e8a' }}><b>SUMMARY tab</b></span>.</Typography>
            </>
          ) : (
            <>
              <Typography textAlign={'center'}>Processing the followup email</Typography>
              <Typography textAlign={'center'}>Please check in a few condensates</Typography>
            </>
          )
        }
        <Box sx={{ padding: '2vh' }} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Box sx={{ padding: '2vh' }} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'30%'} height={skeletonHeight} />
      </Card>
    )

  return (
    <Card sx={sxCard}>
      <CardHeader
        title="Meeting Detail Condensates (BETA)"
        action={
          <>
            <Tooltip title={copyTooltip}>
              <IconButton aria-label='copy' onClick={copyText} >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                aria-label='edit'
                onClick={() => setEditMode()}
                disabled={isPublic || fbUser?.uid != eventMetaData?.owner_id}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>

        }
        sx={{ paddingTop: 1 }}
      />

      {isEdit &&
        <Box sx={menuBarBox}>
          <EditorMenuBar editor={editor} />
        </Box>
      }

      <CardContent sx={sxContent}>
        <EditorContent className='tiptap' editor={editor} />
        <Typography sx={{ color: 'grey', fontSize: '0.875rem' }}>
          {editor ? editor.storage.characterCount.characters() : 0} characters
        </Typography>
        <Typography sx={{ color: 'grey', fontSize: '0.875rem' }}>
          {editor ? editor.storage.characterCount.words() : 0} words
        </Typography>
      </CardContent>
    </Card >
  )
}

const sxCard: SxProps = {}

const sxContent: SxProps = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '75vh',
  paddingTop: "1px"
}

const menuBarBox: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}