import React, { useEffect, useState } from 'react'
import { Box, SxProps, Card, Typography, Switch, Snackbar, Alert, Divider, Link } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUserConfig, updatePremeetingSettings, UserConfigKey } from 'service'
import { gSx } from 'styles/Theme'
import { SkeletonRows } from 'components/Skeletons'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import EventNoteIcon from '@mui/icons-material/EventNote'

export default function PremeetingSettingsCard() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['getUserConfig'],
    queryFn: getUserConfig,
  })

  async function onUpdatePremeetingSettings(key: UserConfigKey, checked: boolean) {
    setErr(undefined)
    setBusy(true)
    const res = await updatePremeetingSettings(key, checked)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
      setSnackSuccess(true)
    }
    setBusy(false)
  }

  return (
    <Card>
      <Box sx={gSx.Row}>
        <Typography variant='h4'>Pre-meeting Note Settings</Typography>
        <Typography sx={{ color: 'tomato', paddingLeft: 1, fontWeight: 'bold' }} variant='caption'>Beta</Typography>
      </Box>

      <Box sx={{ paddingY: 1 }} />

      {
        isLoading
          ? <SkeletonRows count={1} height={65} />
          : <>
            <Box sx={gSx.Row}>
              <Switch
                checked={data?.enable_premeeting_notes}
                onChange={(event, checked) => onUpdatePremeetingSettings(UserConfigKey.EnablePremeetingNotes, checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={busy}
              />
              <Box>
                <Box sx={gSx.Row}><Typography variant='h6'>Enable Pre-meeting Notes </Typography> <EventNoteIcon sx={{ paddingLeft: "4px", color: 'grey' }} /></Box>
                <Typography>Pre-meeting notes will be prepared the night before your scheduled meetings and displayed with each calendar event. Only summaries from previous meetings in Finmate AI will be used, with no data pulled from CRMs.</Typography>
              </Box>
            </Box>
          </>
      }

      <Box sx={{ paddingY: 1 }} />

      {err && <Typography sx={{ color: 'tomato' }}>{err}</Typography>}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Pre-meeting Notes Settings Updated
        </Alert>

      </Snackbar>
    </Card >
  )
}
