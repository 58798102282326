import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Typography, FormControl, Select, MenuItem, FormGroup, Divider, Box, Tooltip } from '@mui/material'
import 'firebase/auth'
import { ClientDetail, FlowInput, FlowInputConfig } from 'client'
import Grid from '@mui/material/Unstable_Grid2'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { gSx } from 'styles/Theme'

interface Props {
  input: FlowInput
  setFlowInputs: Dispatch<SetStateAction<FlowInputConfig[]>>;
  contact?: ClientDetail
}

export default function SalesforceFlowContactOpp({ input, contact, setFlowInputs }: Props) {

  useEffect(() => {
    if (input.sObjectType === "Contact") {
      const newFlowInput: FlowInputConfig = { input, contact }
      setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
    } else if (input.sObjectType === "Opportunity") {
      const newFlowInput: FlowInputConfig = { input, opportunity: contact }
      setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
    }

  }, [])

  return (
    <Grid container>
      <Grid xs={4}>
        <Box sx={gSx.Row}>
          <Typography>{input.name}</Typography>
          <Box sx={{ paddingX: 0.5 }} />
          <Tooltip title={`Defined by Contact at the top of this page`}>
            <InfoOutlinedIcon fontSize='small' />
          </Tooltip>
        </Box>

      </Grid>
      <Grid xs={8}>

        {
          contact ? (
            <FormGroup>
              <FormControl fullWidth>
                <Select
                  value={contact}
                >
                  <MenuItem value={contact as any}>{contact.first_name ?? ''} {contact.last_name ?? ''}, {contact.email}</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>

          ) : <Typography>Please add a contact at the top</Typography>
        }

      </Grid>
      <Grid xs={12}>
        <Divider sx={{ marginY: 1 }} />
      </Grid>
    </Grid>
  )
}