import React, { useState } from 'react'
import { DataGrid, GridColDef, GridActionsCellItem, GridRowId, GridRowModesModel, GridRowModes, GridRowModel } from '@mui/x-data-grid'
import { answerOrgInvite, createOrgInvites, getOrgInvites } from '../../service/orgsApi'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { OrgInvite, OrgUserRole, OrgUserStatus } from '../../client'
import CancelIcon from '@mui/icons-material/Close'
import { getUser } from 'service'
import { OrgUsersSkeleton } from 'components/Skeletons'
import { logInfo } from 'log'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import InviteStatusSnackEmailSent from './InviteStatusSnackEmailSent'

type Action = 'revoke' | 'resend' | undefined

export default function InviteStatus() {
  const queryClient = useQueryClient()
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [busy, setBusy] = useState(false)
  const [action, setAction] = useState<Action>()
  const [openSnackEmailSent, setOpenSnackEmailSent] = useState<string | undefined>()

  const { data: user, isLoading: isLoadingUser } = useQuery({
    queryKey: ['getUser'],
    queryFn: async () => await getUser(),
  })
  const isAdmin = user?.org_user_role == OrgUserRole.ADMIN

  const { data, isLoading } = useQuery({
    queryKey: ['getOrgInvites'],
    queryFn: getOrgInvites
  })

  const mutation = useMutation({
    mutationFn: async (newRow: GridRowModel) => {
      setOpenSnackEmailSent(undefined)
      setBusy(true)
      const invite: OrgInvite = newRow

      if (action == 'revoke')
        await answerOrgInvite(invite.id ?? '', OrgUserStatus.REVOKED)
      else if (action == 'resend') {
        const res = invite.recipient_email && await createOrgInvites(OrgUserRole.MEMBER, [invite.recipient_email])
        if (res && res.length > 0 && res[0].recipient_email)
          setOpenSnackEmailSent(res[0].recipient_email)
      }
      setBusy(false)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getOrgInvites'] })
    },
  })

  const onEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }
  const onRevoke = (id: GridRowId) => () => {
    setAction('revoke')
    const invite = data && data.find(row => row.id === id)
    logInfo('revoke', { owner_id: user?.uid, invite })
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const onResend = (id: GridRowId) => () => {
    setAction('resend')
    const invite = data && data.find(row => row.id === id)
    logInfo('resend', { owner_id: user?.uid, invite })
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const onCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false }
    mutation.mutate(newRow)
    return updatedRow
  }

  const columns: GridColDef[] = [
    {
      field: 'recipient_email',
      headerName: 'Invitee',
      width: 250,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
    },
    {
      field: 'created_at',
      headerName: 'Invited At',
      width: 100,
      valueGetter: (params) => {
        return new Date(params.value).toLocaleDateString()
      },
    },
    {
      field: 'owner_email',
      headerName: 'Invited By',
      width: 250,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (!isAdmin)
          return []

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<Tooltip title="Resend Invitation"><SendIcon /></Tooltip>}
              label="Resend Invitation"
              sx={{
                color: 'primary.main',
              }}
              onClick={onResend(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<Tooltip title="Revoke Invitation"><DeleteIcon color='error' /></Tooltip>}
              label="Revoke Invitation"
              sx={{
                color: 'primary.main',
              }}
              onClick={onRevoke(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<Tooltip title="Cancel Edit (Do Nothing)"><CancelIcon /></Tooltip>}
              label="Cancel"
              className="textPrimary"
              onClick={onCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        if (busy)
          return [<CircularProgress size={15} key={id} />]

        return [
          <GridActionsCellItem
            key={id}
            icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
            label="Edit"
            className="textPrimary"
            onClick={onEditClick(id)}
            color="inherit"
          />
        ]
      },
    },
  ]
  if (isLoading || isLoadingUser || !data)
    return <OrgUsersSkeleton />

  return (
    <>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={data}
          rowModesModel={rowModesModel}
          getRowId={(row) => row.id}
          columns={columns}
          processRowUpdate={processRowUpdate}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          disableRowSelectionOnClick
          disableColumnSelector
          editMode={'row'}
        />
      </Box>
      <Typography variant='caption'>Once accepted, invitees will appear in the members tab.</Typography>

      <InviteStatusSnackEmailSent
        open={openSnackEmailSent}
        setOpen={setOpenSnackEmailSent}
      />
    </>
  )
}