import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Box, Card, Container, Typography } from "@mui/material"
import { logInfo } from 'log'
import DisplayPlan from '../../components/pay/DisplayPlan'
import PricingPlansNew from '../../components/pay/PricingPlansNew'
import StripeCustomerInfo from '../../components/pay/StripeCustomerInfo'
import { getOrg, isAdminRole } from 'service/orgsApi'
import { getSubscription } from 'service/payApi'
import Grid from '@mui/material/Unstable_Grid2'
import ActiveOrgSeats from 'components/pay/ActiveOrgSeats'
import SeatsPurchased from 'components/pay/SeatsPurchased'

export default function BillingScreen() {

  useEffect(() => {
    logInfo('Billing Tab')
  }, [])

  const { data: org, isLoading: isLoadingOrg } = useQuery(['getOrg'], getOrg)
  const { data: sub, isLoading: isLoadSub } = useQuery(['getSubscription'], getSubscription)
  const isLoading = isLoadingOrg || isLoadSub

  const isAdmin = isAdminRole(org)

  return (
    <Container>
      <Typography variant='h1' sx={{ paddingY: '2vh' }} >Billing</Typography>

      <Card>
        <StripeCustomerInfo org={org} />
      </Card>

      <Grid container spacing={2}>
        <Grid xs={12}>
          <Card sx={{ margin: 0 }}>
            <DisplayPlan sub={sub} org={org} isLoading={isLoading} />
          </Card>
        </Grid>
        <Grid xs={12} md={6}>
          <Card sx={{ margin: 0 }}>
            <ActiveOrgSeats org={org} isLoading={isLoading} />
          </Card>
        </Grid>

        <Grid xs={12} md={6}>
          <Card sx={{ margin: 0 }}>
            <SeatsPurchased sub={sub} org={org} isLoading={isLoading} />
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ padding: 1 }} />

      <PricingPlansNew
        org={org}
        isAdmin={isAdmin}
      />

    </Container>
  )
}
