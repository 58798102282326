import React, { useEffect, useState } from 'react'
import { Box, ButtonBase, Card, FormControlLabel, IconButton, Radio, RadioGroup, Skeleton, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { EventBase, WealthboxType } from 'client'
import { gColor, gSx } from 'styles/Theme'
import { useQuery } from '@tanstack/react-query'
import { getWealthboxContact } from 'service/integrations/wealthboxApi'
import ClientEventDialog from 'components/client/ClientCardForEventDialog'
import EditIcon from '@mui/icons-material/Edit'
import { CrmClientBadge } from 'components/client/CrmClientBadge'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

interface Props {
  event?: EventBase
  wbType: WealthboxType
  setWbType: (who: WealthboxType) => void
}

export default function WealthboxContactCard({ event, wbType, setWbType }: Props) {
  const clientEmail = event?.client?.email
  const [showAddClientDialog, setShowAddClientDialog] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['getWealthboxContact', clientEmail],
    queryFn: async () => await getWealthboxContact(clientEmail)
  })
  const noHousehold = !data?.household?.id

  useEffect(() => {
    if (noHousehold) {
      setWbType(WealthboxType.PERSON)
    }
  }, [data])

  return (
    <Card>
      <Box sx={gSx.Row}>
        <PersonOutlineOutlinedIcon sx={{ marginLeft: 0.3, marginRight: 0.7 }} />
        <Typography variant='h5'>Contact</Typography>
      </Box>

      <Box sx={{ padding: 1 }} />
      <Typography>Meeting Summary and Tasks will be saved under Contact either as a Person or a Household.</Typography>
      <Box sx={{ padding: 1 }} />

      <RadioGroup
        aria-label="contact type"
        name="select contact type"
        value={wbType}
        onChange={(event => setWbType(event.target.value as WealthboxType))}
      >

        <Grid container >
          <Grid xs={1}>
            <FormControlLabel
              value={WealthboxType.PERSON}
              control={<Radio />}
              label=""
              sx={{ margin: 0 }}
            />
          </Grid>

          <Grid xs={2}>
            <Box sx={{ padding: 0.5 }} />
            <Typography variant='h5'>Person</Typography>
          </Grid>

          <Grid xs={8}>

            <Typography color={gColor.green500}>
              {event?.client?.first_name ?? ''} {event?.client?.last_name ?? ''}
            </Typography>
            <Box sx={gSx.Row}>
              <Typography color={gColor.green500}>{event?.client?.email ?? ''}</Typography>
              <CrmClientBadge client={event?.client} />
            </Box>

            {!clientEmail &&
              <>
                <ButtonBase onClick={() => setShowAddClientDialog(true)}>
                  <Typography sx={{ color: 'red', cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    Add a contact
                  </Typography>
                </ButtonBase>
                <Typography >
                  The Email should match your existing CRM contact, or Finmate will automatically create a new CRM contact for you.
                </Typography>
              </>
            }
          </Grid>

          <Grid xs={1}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                size='small'
                aria-label='edit'
                onClick={() => setShowAddClientDialog(true)}
                sx={{ paddingY: 0 }}
              >
                <EditIcon fontSize='small' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <ClientEventDialog
          event={event}
          open={showAddClientDialog}
          onClose={() => setShowAddClientDialog(false)}
        />

        <Box sx={{ padding: 1 }} />
        <Grid container>
          <Grid xs={1}>
            <FormControlLabel
              value={WealthboxType.HOUSEHOLD}
              control={<Radio />}
              label=""
              disabled={isLoading || noHousehold}
              sx={{ margin: 0 }}
            />
          </Grid>

          <Grid xs={2}>
            <Box sx={{ padding: 0.5 }} />
            <Typography variant='h5' color={noHousehold ? 'lightgray' : 'black'}>Household</Typography>
          </Grid>

          <Grid xs={9}>
            {
              isLoading
                ? <Skeleton variant="rectangular" width={'90%'} height={20} />
                : noHousehold
                  ? <Typography sx={{ paddingTop: 1 }}>No Wealthbox Household Found</Typography>
                  : (
                    <>
                      <Box sx={{ padding: 0.5 }} />
                      <Typography color={gColor.green500} variant='h6'>
                        {data?.household?.name}
                      </Typography>
                      {data.household?.members?.map(m => (
                        <Typography key={m.id} color={gColor.green500}>
                          {`${m.first_name} ${m.last_name} (${m.title})`}
                        </Typography>
                      ))}
                    </>
                  )

            }
          </Grid>
        </Grid>
      </RadioGroup>
    </Card>
  )
}
