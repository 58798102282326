import { types } from 'mobx-state-tree'

const Security = types.model('SecurityStore', {
  showAdmin: types.maybe(types.boolean)
}).actions(self => ({
  setShowAdmin(show: boolean) {
    self.showAdmin = show
  },
})).views(self => ({
  getShowAdmin(): boolean {

    return self.showAdmin ?? false
  }
}))

export default Security