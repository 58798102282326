import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import { Box, SxProps, Typography, ListItemButton, Chip, Tooltip, Stack, CircularProgress } from '@mui/material'
import { formatDateByIso, isoDateToReadable } from '../../utils/date'
import Grid from '@mui/material/Unstable_Grid2'
import CheckIcon from '@mui/icons-material/Check'
import { EventSimpleBase, ProcessingStatus, SalesforceApp } from '../../client'
import { isImageURLReal } from '../../utils'
import { Media } from 'service/media'
import useWindowWidth from 'hooks/useWindowWidth'

interface Props {
  data: EventSimpleBase
}
type Status = 'Processing Transcript' | 'No Transcript' | 'Transcript Error' | 'Select Meeting' | 'Processing Summary' | 'Done' | 'Unknown'

export default function EventListItem({ data }: Props) {
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()
  const thumbnailUrl = data.video_bucket_info?.thumbnail_filename_signed
  const [isReal, setReal] = useState(false)
  const salesforceSent = !!(data.salesforce_flows_sent || data.salesforce_sent || data.salesforce_tasks_sent)

  const status = (): Status => {
    if (data.status?.transcript_status === ProcessingStatus.PROCESSING)
      return 'Processing Transcript'
    if (data.status?.transcript_status === ProcessingStatus.DONE_EMPTY_CONTENT)
      return 'No Transcript'
    if (data.status?.transcript_status === ProcessingStatus.ABORTED)
      return 'Transcript Error'
    if (data.status?.transcript_status === ProcessingStatus.DONE && !data.module_ui_title)
      return 'Select Meeting'
    if (data.status?.summary_overall_status === ProcessingStatus.PROCESSING)
      return 'Processing Summary'
    if (data.status?.summary_overall_status === ProcessingStatus.DONE)
      return 'Done'
    return 'Unknown' // could be: brand new, empty, yet to start event.
  }

  const salesforceApp = data.salesforce_app ?? SalesforceApp.SALESFORCE

  useEffect(() => {
    async function checkImage() {
      setReal(thumbnailUrl ? await isImageURLReal(thumbnailUrl) : false)
    }
    checkImage()
  }, [])

  const sxTitle: SxProps = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: `${windowWidth - 100}px`
  }

  return (
    <ListItemButton
      sx={{ display: 'flex', flexDirection: 'column', minWidth: '500px' }}
      onClick={() => navigate(`${Path.Event}/${data.id}`)}
      aria-label={data.title}
    >
      <Grid container sx={sxLine} >
        <Grid xs={1.5} md={1.5} sx={{ display: { xs: 'none', md: 'block' } }}>
          {
            isReal && thumbnailUrl && thumbnailUrl?.length > 0 ? (
              <Box
                component="img"
                sx={sxThumbnail}
                src={thumbnailUrl}
              />
            ) : (<Box sx={sxThumbnail} />)
          }
        </Grid>
        <Grid xs={10.5} md={5.5}>
          <Typography sx={sxTitle}>{data.title}</Typography>
          <Box sx={sxStatusRow}>
            {status() == 'Processing Transcript' &&
              <Chip
                label="Processing Transcript"
                variant="outlined"
                icon={
                  <Stack direction="row" spacing={1}>
                    <CircularProgress size={20} color="primary" />
                  </Stack>
                }
              />
            }
            {status() == 'No Transcript' &&
              <Chip
                color="warning"
                label={'No Transcript'}
                variant='outlined'
              />
            }
            {status() == 'Transcript Error' &&
              <Chip
                color="error"
                label={'Transcript Error'}
                variant='outlined'
              />
            }
            {status() == 'Select Meeting' &&
              <Chip
                color="primary"
                label={'Select Meeting Type'}
                variant='outlined'
              />
            }
            {status() == 'Processing Summary' &&
              <Chip
                label="Processing Summary"
                variant="outlined"
                icon={
                  <Stack direction="row" spacing={1}>
                    <CircularProgress size={20} color="primary" />
                  </Stack>
                }
              />
            }
            {status() == 'Done' &&
              <Chip
                label={data.module_ui_title}
                variant="outlined"
                icon={
                  <Stack direction="row" spacing={1}>
                    <CheckIcon sx={{ color: '#999' }} />
                  </Stack>
                }
                sx={{ color: '#777' }}
              />
            }
            {
              data.redtail_sent &&
              <Tooltip title='Redtail Sent'>
                <Box
                  component="img"
                  sx={{ width: 25, marginLeft: '10px' }}
                  src={Media.redtailIconWhite}
                  alt="Redtail"
                />
              </Tooltip>
            }
            {
              data.wealthbox_sent &&
              <Tooltip title='Wealthbox Sent'>
                <Box
                  component="img"
                  sx={{ width: 25, marginLeft: '10px' }}
                  src={Media.wealthboxIcon}
                  alt="Wealthbox"
                />
              </Tooltip>
            }
            {
              salesforceSent &&
              <>
                {salesforceApp == SalesforceApp.SALESFORCE &&
                  <Tooltip title={`${salesforceApp} Note Sent`}>
                    <Box
                      component="img"
                      sx={{ width: 22, marginLeft: '10px' }}
                      src={Media.salesforceIcon}
                      alt="Salesforce"
                    />
                  </Tooltip>
                }
                {salesforceApp == SalesforceApp.PRACTIFI &&
                  <Tooltip title={`${salesforceApp} Note Sent`}>
                    <Box
                      component="img"
                      sx={{ width: 22, marginLeft: '10px' }}
                      src={Media.practifiIcon}
                      alt="Practifi"
                    />
                  </Tooltip>
                }
                {salesforceApp == SalesforceApp.XLR8 &&
                  <Tooltip title={`${salesforceApp} Note Sent`}>
                    <Box
                      component="img"
                      sx={{ width: 20, marginLeft: '10px' }}
                      src={Media.xlr8Icon}
                      alt="Xlr8"
                    />
                  </Tooltip>
                }
              </>
            }
          </Box>
        </Grid>
        <Grid xs={6} md={3}>
          <Typography sx={sxText}>{`${data.client?.first_name ?? ''} ${data.client?.last_name ?? ''}`}</Typography>
          <Typography sx={sxText}>{data.client?.email ?? ''}</Typography>
        </Grid>
        <Grid xs={6} md={2}>
          <Typography>{formatDateByIso(data.start_time)}</Typography>
        </Grid>
      </Grid>

    </ListItemButton>

  )
}

const sxThumbnail: SxProps = {
  width: 70,
  height: 55,
  backgroundColor: 'grey.100',
  borderRadius: '10px'
}

const sxLine: SxProps = {
  padding: '1vh',
  width: '100%',
}

const sxText: SxProps = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const sxStatusRow: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
}