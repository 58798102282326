import React from 'react'
import { Typography, Box, Card, SxProps, Button, Skeleton, ButtonBase } from '@mui/material'
import 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery } from '@tanstack/react-query'
import { Media } from 'service/media'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { getUserConfig } from 'service'
import { getRingCentralProfile } from 'service/integrations/ringCentralApi'
import { config } from '../../../service/api'
import { gSx } from 'styles/Theme'

export default function RingCentralIntegrationLine() {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['getRingCentralProfile'],
    queryFn: getRingCentralProfile,
  })

  const isConnected = !!data?.id

  return (
    <Card>
      <ButtonBase sx={sxContainer} onClick={() => navigate(Path.RingCentralSettings)}>
        <Box
          component="img"
          sx={sxLogo}
          src={Media.ringCentralLogo}
        />
        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={50} />
            : (
              isConnected ? (
                <Box sx={gSx.CenterVertical}>
                  <Typography>RingCentral Connected</Typography>
                  <Typography>{data.email}</Typography>
                </Box>

              ) : (
                <Button>RingCentral Sign In</Button>
              )
            )
        }
        <ChevronRightIcon />
      </ButtonBase>
    </Card>
  )
}

const sxLogo: SxProps = {
  width: 38,
}

const sxContainer: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}