import React from 'react'
import { OrgBase, StripeDiscount } from '../../client'
import { Card, Typography } from '@mui/material'
import { gColor } from '../../styles/Theme'

interface Props {
  org?: OrgBase
  discount?: StripeDiscount
}

export default function Discount({ discount, org }: Props) {

  if (!org || !discount?.coupon)
    return null

  return (
    <Card sx={{ borderColor: gColor.light }}>
      <Typography>Exclusive Discount for {org.org_name} </Typography>
      {discount?.coupon?.amount_off && (
        <Typography>
          {`Amount off: $${discount?.coupon.amount_off / 100}`}
        </Typography>
      )}

      {discount?.coupon?.percent_off && (
        <Typography>
          {`Percent off: ${discount?.coupon.percent_off}%`}
        </Typography>
      )}

      {discount?.coupon?.duration_in_months && (
        <Typography>
          {`Num of Months: ${discount?.coupon.duration_in_months}`}
        </Typography>
      )}
    </Card>
  )
}