/**
 * For all environments(local, dev, stage), we save brand Icons and Logos
 * in finmate-prod GCP porject.
 */

import { SalesforceApp } from "client"

// app image hosted
const url = `${process.env.PUBLIC_URL}/media`

export const Media = {
  logo100: `${url}/logo_100.png`,
  logoRaw: `${url}/logo_raw.png`,
  google100: `${url}/google_100.png`,
  googleCalendar: `${url}/google_calendar.png`,
  microsoft100: `${url}/microsoft_100.png`,
  videoLogos: `${url}/video_logos.png`,
  addToZoom: `https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png`,
  zoomConnect: `${url}/zoom_finmate_connect.png`,
  zoomLogo: `${url}/zoom_logo.png`,
  zoomLocalRecording: `${url}/zoom_local_recording.png`,
  meetLogo: `${url}/meet_logo.png`,
  teamsLogo: `${url}/teams_logo.png`,
  webexLogo: `${url}/webex_logo.png`,
  appleAppStore: `https://1000logos.net/wp-content/uploads/2020/08/apple-app-store-logo-500x173.jpg`,
  googlePlayStore: `${url}/google_play.png`,

  sampleYoutubeStill: `${url}/sample/sample-youtube-still.png`,

  signupCarousel1: `${url}/signup_carousel_1.png`,
  signupCarousel2: `${url}/signup_carousel_2.png`,
  signupCarousel3: `${url}/signup_carousel_3.png`,

  redtail: `${url}/Redtail-Orion-Logo_Red-52.svg`,
  redtailIconWhite: `${url}/redtail_icon_white.png`,

  salesforce: `${url}/salesforce.svg`,
  salesforceIcon: `${url}/salesforce_icon.webp`,
  practifi: `${url}/practifi_logo.png`,
  practifiIcon: `${url}/practifi_icon.png`,
  xlr8: `${url}/xlr8_logo.png`,
  xlr8Icon: `${url}/xlr8_icon.png`,

  wealthbox: `${url}/wealthbox_logo.png`,
  wealthboxIcon: `${url}/wealthbox_icon.png`,

  ringCentralLogo: `${url}/ringcentral_logo.png`,
  ringCentralRecord: `${url}/ringcentral_record.png`,

  finmate_logo_detailed: `${url}/finmate_logo_detailed.png`, // used by wealthbox for Auth

  canada_flag: `${url}/canada_flag.png`,

  testimonial_libby_griewe: `${url}/testimonials/libby_griewe.png`,
  testimonial_corey_cyr: `${url}/testimonials/corey_cyr.jpeg`,
  testimonial_zac_daulton: `${url}/testimonials/zac_daulton.jpg`,
  testimonial_carly_kelly: `${url}/testimonials/carly_kelly.png`,
  testimonial_dan_solin: `${url}/testimonials/dan_solin.jpg`,
  testimonial_joe_moss: `${url}/testimonials/joe_moss.jpg`,
}
export function salesforceAppIconUrl(app: SalesforceApp) {
  if (app == SalesforceApp.PRACTIFI)
    return Media.practifiIcon
  if (app == SalesforceApp.XLR8)
    return Media.xlr8Icon
  return Media.salesforceIcon
}