import React from 'react'
import { Box, SxProps, Skeleton } from '@mui/material'
import { Path } from 'routes'
import { Media } from 'service/media'
import SideNavItem from './SideNavItem'
import { SalesforceApp } from 'client'

interface Props {
  setOpen: (open: boolean) => void
  app?: SalesforceApp
}

export function SideNavSalesforce({ setOpen, app }: Props) {

  if (app == SalesforceApp.PRACTIFI)
    return (
      <SideNavItem
        icon={<Box
          component="img"
          sx={sxIcon}
          src={Media.practifiIcon}
          alt="Practifi"
        />}
        path={Path.PractifiSettings}
        title={'Practifi'}
        setOpen={setOpen}
      />
    )

  if (app == SalesforceApp.XLR8)
    return (
      <SideNavItem
        icon={<Box
          component="img"
          sx={sxIcon}
          src={Media.xlr8Icon}
          alt="Xlr8"
        />}
        path={Path.Xlr8Settings}
        title={'Xlr8'}
        setOpen={setOpen}
      />
    )

  return (
    <SideNavItem
      icon={<Box
        component="img"
        sx={sxIcon}
        src={Media.salesforceIcon}
        alt="Salesforce"
      />}
      path={Path.SalesforceSettings}
      title={'Salesforce'}
      setOpen={setOpen}
    />
  )
}

export function SideNavRedtail({ setOpen }: Props) {
  return (
    <SideNavItem
      icon={<Box
        component="img"
        sx={sxIcon}
        src={Media.redtailIconWhite}
        alt="Redtail"
      />}
      path={Path.RedtailSettings}
      title={'Redtail'}
      setOpen={setOpen}
    />
  )
}

export function SideNavWealthbox({ setOpen }: Props) {
  return (
    <SideNavItem
      icon={<Box
        component="img"
        sx={sxIcon}
        src={Media.wealthboxIcon}
        alt="Wealthbox"
      />}
      path={Path.WealthboxSettings}
      title={'Wealthbox'}
      setOpen={setOpen}
    />
  )
}

export function SideNavRingCentral({ setOpen }: Props) {
  return (
    <SideNavItem
      icon={<Box
        component="img"
        sx={sxIcon}
        src={Media.ringCentralLogo}
        alt="RingCentral"
      />}
      path={Path.RingCentralSettings}
      title={'RingCentral'}
      setOpen={setOpen}
    />
  )
}

const sxIcon: SxProps = {
  width: 25,
  height: 25,
}