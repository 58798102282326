/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SalesforceFlowTextEnum {
    MEETING_TITLE = 'Meeting Title',
    SUMMARY_NOTES_PLAIN_TEXT_ = 'Summary Notes (Plain Text)',
    SUMMARY_NOTES_HTML_ = 'Summary Notes (Html)',
    TRANSCRIPT_PLAIN_TEXT_ = 'Transcript (Plain Text)',
    TRANSCRIPT_HTML_ = 'Transcript (Html)',
}
