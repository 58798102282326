import React from 'react'
import { Link, Box, Button, Card, CircularProgress, SxProps, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import { setZoomAuthCred } from 'service'
import { Media } from 'service/media'
import AddToZoomButton from 'components/settings/AddToZoomButton'

/**
 * After connecting Zoom, zoom redirects to here with param code
 * @returns 
 */
export default function ZoomCallback() {
  const navigate = useNavigate()
  const url = window.location.href
  const urlParams = new URLSearchParams(new URL(url).search)
  const code = urlParams.get("code") ?? ''

  const { data, isLoading } = useQuery({
    queryKey: ['setZoomAuthCred'],
    queryFn: async () => await setZoomAuthCred(code),
    refetchOnWindowFocus: false
  })

  if (isLoading) {
    return (
      <Box sx={sxWrap}>
        <Typography variant='h3'>Finalizing Finmate and Zoom Connections</Typography>
        <Box sx={{ padding: '10px' }} />
        <CircularProgress />
      </Box>
    )
  }

  if (data)
    return (
      <Box sx={sxWrap}>
        <Typography variant='h3'>Zoom is Authenticated</Typography>
        <Card>
          <Box
            component="img"
            sx={sxIcon}
            src={Media.zoomConnect}
            alt="Zoom"
          />
        </Card>

        <Box sx={{ padding: '10px' }} />

        <Typography sx={{ maxWidth: '500px' }}>{`In Your `}
          <Link rel='noopener' target='_blank' href="https://zoom.us/profile/setting">Zoom Settings</Link>, Make sure <b>Local Recording</b> is turned on.
        </Typography>

        <Box sx={{ padding: '10px' }} />

        <Typography sx={{ maxWidth: '500px' }}>Optional: Turn on <b>Automatic Recording</b> as a backup in case the Notetaker gets removed from the meeting.</Typography>

        <Box sx={{ padding: '10px' }} />

        <Link rel='noopener' target='_blank' href="https://zoom.us/profile/setting">
          <Box
            component="img"
            sx={sxZoomSetting}
            src={Media.zoomLocalRecording}
            alt="Zoom"
          />
        </Link>

        <Box sx={{ padding: '10px' }} />

        <Button variant='outlined' onClick={() => navigate(Path.Home)}>Home</Button>
      </Box >
    )

  return (
    <Box sx={sxWrap}>
      <Typography variant='h3'>Something went wrong
      </Typography>

      <ol>
        <li>Please sign into <Link rel='noopener' target='_blank' href='https://marketplace.zoom.com'>
          Zoom Marketplace</Link> and remove the Finmate app from your zoom account.</li>
        <ol>
          <li>{`Once signed in, at the top right, select "Manage" button`}</li>
          <li>{`On the Left Menu, select "Added Apps"`}</li>
          <li>{`Remove Finmate AI"`}</li>
        </ol>
        <br />
        <li>Come back and press here to reconnect <AddToZoomButton /></li>
      </ol>
    </Box >
  )
}

const sxWrap: SxProps = {
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
}

const sxIcon: SxProps = {
  width: '100px'
}

const sxZoomSetting: SxProps = {
  width: '600px'
}
