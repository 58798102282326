import React from 'react'
import { Button, Dialog, DialogTitle, IconButton, SxProps, DialogContent, DialogActions, DialogContentText } from '@mui/material'
import { EmailRerunIcon } from '../../CustomIcons'
import CloseIcon from '@mui/icons-material/Close'
import FollowupEmailCustomizer from '../../templates/FollowupEmailCustomizer'

interface RerunFollowupEmailConfirmDialogProps {
  open: boolean
  onCancel: () => void
  onRerunFollowupEmailOpen: () => void
}

interface RerunFollowupEmailDialogProps {
  eventId: string
  disabled?: boolean
  open: boolean
  onClose: () => void
  onCancel: () => void
}

const sxClose: SxProps = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'grey.500',
}

export function RerunFollowupEmailConfirmDialog({ open, onCancel, onRerunFollowupEmailOpen }: RerunFollowupEmailConfirmDialogProps) {
  return (
    <>
      {/* Rerun Meeting Followup Email Confirm Dialog */}
      <Dialog open={open} onClose={onCancel} fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span><EmailRerunIcon />Proceed with Followup Email Rerun?</span>
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={sxClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: 16 }}>
            Customize the followup email in the next step.
          </DialogContentText>
          <DialogContentText sx={{ fontSize: 15 }}>
            <span style={{ color: '#2a7e8a' }}>Note: This will incur <b>additional usage minutes</b>.</span>
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: 0 }}>
          <Button
            onClick={onCancel}
            color="primary"
            aria-label='Cancel'
            sx={{ margin: '10px' }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onRerunFollowupEmailOpen()}
            color="primary"
            aria-label='Proceed'
            variant='contained'
            sx={{ margin: '10px' }}
          >
            Customize followup email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export function RerunFollowupEmailDialog({ eventId, open, disabled, onCancel, onClose }: RerunFollowupEmailDialogProps) {
  return (
    <>
      {/* Rerun Meeting Followup Email Dialog */}
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <DialogTitle>Rerun Follow-up Email</DialogTitle>
        <DialogContent>
          <FollowupEmailCustomizer eventId={eventId} disabled={disabled ?? false} isRerun={true} onBuildEmail={onClose} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCancel}
            color="primary"
            aria-label='Cancel'
            sx={{ margin: '10px' }}
          >
            Cancel
          </Button>

        </DialogActions>
      </Dialog>

    </>
  )
}
