import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Typography, FormControl, Select, MenuItem, SelectChangeEvent, Divider } from '@mui/material'
import 'firebase/auth'
import { FlowInput, FlowInputConfig, SalesforceFlowTextEnum } from 'client'
import Grid from '@mui/material/Unstable_Grid2'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getEventSummaryBase, getSummarySections } from 'service/eventApi'

interface Props {
  eventId: string,
  input: FlowInput
  setFlowInputs: Dispatch<SetStateAction<FlowInputConfig[]>>
  isTranscriptValid: boolean
}

export default function SalesforceFlowText({ eventId, input, setFlowInputs, isTranscriptValid }: Props) {
  const queryClient = useQueryClient()

  const { data: summary } = useQuery({
    queryKey: ['getEventSummary', eventId],
    queryFn: async () => await getEventSummaryBase(eventId), // skip cache.
  })
  const sections = getSummarySections(summary)

  const [selected, setSelected] = useState<string>(SalesforceFlowTextEnum.MEETING_TITLE)

  const isArray = !!(input.maxOccurs && input.maxOccurs > 1)

  useEffect(() => {
    if (!isArray) {
      setSelected(SalesforceFlowTextEnum.MEETING_TITLE) // init
      const newFlowInput: FlowInputConfig = { input, text_label: SalesforceFlowTextEnum.MEETING_TITLE }
      setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
    }
  }, [])

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string
    const isSalesforceFlowTextEnum = Object.values(SalesforceFlowTextEnum).includes(value as SalesforceFlowTextEnum)

    const newFlowInput: FlowInputConfig = { input }
    setSelected(value)
    if (isSalesforceFlowTextEnum) {
      newFlowInput.text_label = value
    } else {
      const section = sections.find(s => s.title == value)
      newFlowInput.text_label = section?.title
      newFlowInput.text_output = section?.title + '\n\n' + section?.description
    }
    setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
  }

  return (
    <Grid container>
      <Grid xs={4}>
        <Typography>{input.name}</Typography>
      </Grid>
      <Grid xs={8}>

        {
          isArray ? (
            <Typography>Not Available. Please Contact us to assist in configuration</Typography>
          ) : (
            <FormControl fullWidth>
              <Select
                value={selected}
                onChange={onChange}
                defaultValue='notes'
              >
                <MenuItem value={SalesforceFlowTextEnum.MEETING_TITLE}>{SalesforceFlowTextEnum.MEETING_TITLE}</MenuItem>

                <MenuItem value={SalesforceFlowTextEnum.SUMMARY_NOTES_HTML_}>{SalesforceFlowTextEnum.SUMMARY_NOTES_HTML_}</MenuItem>

                <MenuItem value={SalesforceFlowTextEnum.SUMMARY_NOTES_PLAIN_TEXT_}>{SalesforceFlowTextEnum.SUMMARY_NOTES_PLAIN_TEXT_}</MenuItem>

                {isTranscriptValid &&
                  <MenuItem value={SalesforceFlowTextEnum.TRANSCRIPT_HTML_}>{SalesforceFlowTextEnum.TRANSCRIPT_HTML_}</MenuItem>
                }

                {isTranscriptValid &&
                  <MenuItem value={SalesforceFlowTextEnum.TRANSCRIPT_PLAIN_TEXT_}>{SalesforceFlowTextEnum.TRANSCRIPT_PLAIN_TEXT_}</MenuItem>
                }

                {sections && sections.map(s =>
                  <MenuItem key={s.title} value={s.title}>{s.title}</MenuItem>
                )}
              </Select>
            </FormControl>
          )
        }

      </Grid>
      <Grid xs={12}>
        <Divider sx={{ marginY: 1 }} />
      </Grid>
    </Grid>
  )
}