import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogTitle, IconButton, Menu, MenuItem, Snackbar, SxProps, Typography, Divider, DialogContent, DialogActions, DialogContentText, Card } from '@mui/material'
import { SummaryRerunIcon, EmailRerunIcon } from '../../CustomIcons'
import CloseIcon from '@mui/icons-material/Close'
import EventModuleSelect from '../EventModuleSelect'

interface RerunSummaryConfirmDialogProps {
  open: boolean
  onCancel: () => void
  onRerunSummaryOpen: () => void
}

interface RerunSummaryDialogProps {
  eventId: string
  disabled?: boolean
  open: boolean
  onClose: () => void
  onCancel: () => void
}

const sxClose: SxProps = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'grey.500',
}

export function RerunSummaryConfirmDialog({ open, onCancel, onRerunSummaryOpen }: RerunSummaryConfirmDialogProps) {
  return (
    <>
      {/* Rerun Meeting Summary Confirm Dialog */}
      <Dialog open={open} onClose={() => onCancel()} fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span><SummaryRerunIcon />Proceed with Meeting Summary Rerun?</span>
          <IconButton
            aria-label="close"
            onClick={() => onCancel()}
            sx={sxClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: 16 }}>
            Choose a meeting type in the next step.
          </DialogContentText>
          <DialogContentText sx={{ fontSize: 15 }}>
            <span style={{ color: '#2a7e8a' }}>Note: This will incur <b>additional usage minutes</b>.</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: 0 }}>
          <Button
            onClick={() => onCancel()}
            color="primary"
            aria-label='Cancel'
            sx={{ margin: '10px' }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onRerunSummaryOpen()}
            color="primary"
            aria-label='Proceed'
            sx={{ margin: '10px' }}
            variant='contained'
          >
            Select Meeting Type
          </Button>

        </DialogActions>
      </Dialog>
    </>
  )
}

export function RerunSummaryDialog({ eventId, open, disabled, onCancel, onClose }: RerunSummaryDialogProps) {
  return (
    <>
      {/* Rerun Meeting Summary Dialog */}
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <DialogTitle>Rerun Meeting Summery - Select Meeting Type</DialogTitle>
        <DialogContent>
          <Card>
            <EventModuleSelect eventId={eventId} disabled={disabled} isRerun={true} onBuildModule={onClose} />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onCancel}
            color="primary"
            aria-label='Cancel'
            sx={{ margin: '10px' }}
          >
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
    </>
  )
}
