import { NodeViewWrapper } from '@tiptap/react'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, ButtonBase } from '@mui/material'
import { numSecToTime } from '../../utils'
import { useStore } from '../../hooks/useStore'

export default observer(TimestampComponent)

function TimestampComponent(props: any) {
  const store = useStore()
  const timestampLink = () => {
    console.log("timestampLink", props.node.attrs.timestamp)
    store.User.setSeekSeconds(props.node.attrs.timestamp ?? 0)
    store.User.setTranscriptSeekSeconds(props.node.attrs.timestamp ?? 0)
  }

  return (
    <NodeViewWrapper>
      <Button onClick={timestampLink}>
        {numSecToTime(props.node.attrs.timestamp)}
      </Button>
    </NodeViewWrapper>
  )
}