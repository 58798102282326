import React, { useEffect } from 'react'
import { useStore } from 'hooks/useStore'
import EventsList from 'components/event/EventsList'
import MyEventsList from 'components/event/MyEventsList'

interface Props {
  clientEmail?: string
}

export default function ClientMeetings({ clientEmail }: Props) {
  const store = useStore()

  useEffect(() => {
    if (clientEmail)
      store.User.setEventSearchParams({ client_email: clientEmail })
  }, [clientEmail])

  return (
    <EventsList />
  )
}
