import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { Pension } from '../../client'

interface Props {
  eventId?: string
  pensionList?: Pension[]
}

export default function PensionTable({ eventId, pensionList }: Props) {

  const cellRef = useRef(null)
  const [data, setPensionList] = useState<Pension[]>([])

  useEffect(() => {
    if (pensionList) {
      console.log("pensionList", pensionList)
      setPensionList(pensionList)
    }
  }, [pensionList])

  const columns = useMemo<MRT_ColumnDef<Pension>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        size: 100
      },
      {
        accessorKey: 'Distribution Type',
        header: 'Distribution Type',
        size: 100
      },
      {
        accessorKey: 'Distribution Amount',
        header: 'Distribution Amount',
        size: 100
      },
      {
        accessorKey: 'Distribution Year',
        header: 'Distribution Year',
        size: 100
      },
      {
        accessorKey: 'Account Value',
        header: 'Account Value',
        size: 100
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data,
  })

  return (
    <MRT_Table table={table} />
  )
}
