/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_sendNotesToSalesforce } from '../models/Body_sendNotesToSalesforce';
import type { Body_sendTasksToSalesforce } from '../models/Body_sendTasksToSalesforce';
import type { Body_startSalesforceFlow } from '../models/Body_startSalesforceFlow';
import type { SalesforceApp } from '../models/SalesforceApp';
import type { SalesforceBase } from '../models/SalesforceBase';
import type { SalesforceFlow } from '../models/SalesforceFlow';
import type { SalesforceUser } from '../models/SalesforceUser';
import type { SalesforceWho } from '../models/SalesforceWho';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesforceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Exchange Auth Code For Tokens
     * @param code
     * @param salesforceApp
     * @returns any Successful Response
     * @throws ApiError
     */
    public exchangeAuthCodeForTokens(
        code: string,
        salesforceApp: SalesforceApp,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/auth/code',
            query: {
                'code': code,
                'salesforce_app': salesforceApp,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Salesforce Auth Localhost
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public saveSalesforceAuthLocalhost(
        requestBody: SalesforceBase,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/auth/save',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Salesforce
     * @returns SalesforceBase Successful Response
     * @throws ApiError
     */
    public getSalesforceSettings(): CancelablePromise<SalesforceBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/salesforce/',
        });
    }

    /**
     * Disconnect
     * @returns any Successful Response
     * @throws ApiError
     */
    public disconnectSalesforce(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/integrations/salesforce/',
        });
    }

    /**
     * Get Salesforce Users
     * @returns SalesforceUser Successful Response
     * @throws ApiError
     */
    public getSalesforceUsers(): CancelablePromise<Array<SalesforceUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/salesforce/users',
        });
    }

    /**
     * Send Notes
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendNotesToSalesforce(
        requestBody: Body_sendNotesToSalesforce,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/notes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Tasks
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendTasksToSalesforce(
        requestBody: Body_sendTasksToSalesforce,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/tasks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Salesforce Flows
     * @returns SalesforceFlow Successful Response
     * @throws ApiError
     */
    public getSalesforceFlows(): CancelablePromise<Array<SalesforceFlow>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/salesforce/flows',
        });
    }

    /**
     * Start Flow
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public startSalesforceFlow(
        requestBody: Body_startSalesforceFlow,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/flow',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Default Create Who
     * @param who
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateDefaultCreateWho(
        who: SalesforceWho,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/config/who',
            query: {
                'who': who,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Contacts
     * @returns number Successful Response
     * @throws ApiError
     */
    public importContacts(): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/salesforce/contacts/import',
        });
    }

}
