/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SalesforceApp {
    SALESFORCE = 'Salesforce',
    XLR8 = 'Xlr8',
    PRACTIFI = 'Practifi',
}
