export interface ApiError {
  url: string,
  status: number,
  statusText: string,
  body: {
    detail: string
  },
  request: any
}

export function getErrorMessage(error: any): Error {
  try {
    // for openapi errors
    const errorJSON = JSON.stringify(error)
    const obj = JSON.parse(errorJSON) as ApiError
    return new Error(obj.body.detail)
  } catch (notused) {
    // for JS throw errors
    return new Error(error.message)
  }
}
