import React, { useEffect, useState } from 'react'
import { Card, Typography, SxProps, TextField, Snackbar, Alert, LinearProgress } from '@mui/material'
import { getUserConfigBotName, updateBotName } from '../../service'
import { observer } from 'mobx-react-lite'
import { logInfo } from '../../log'

export default function RecorderAppearance() {
  const [snackSuccess, setSnackSuccess] = useState(false)
  const [botName, setBotName] = useState('')
  const [err, setErr] = useState<string | undefined>()
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    async function run() {
      const currentBotName = await getUserConfigBotName()
      if (currentBotName) {
        setBotName(currentBotName)
      }
    }
    run()
  }, [])

  async function update() {
    setBusy(true)
    setErr(undefined)
    logInfo('RecorderAppearance', { botName })
    const res = await updateBotName(botName)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      setBotName(res)
    }
    setBusy(false)
  }

  return (
    <Card>
      <Typography variant='h5'>Appearance</Typography>
      <Typography>Customize how FinMate recorder appears during your meetings.</Typography>

      <TextField
        onChange={(event) => {
          setBotName(event.target.value)
        }}
        onBlur={update}
        sx={sxInput}
        label="Recorder Name"
        variant="filled"
        value={botName}
      />

      {busy && <LinearProgress />}
      {err && <Typography color={'tomato'} >{err}</Typography>}

      <Snackbar
        open={snackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          FinMate Recorder Name Updated
        </Alert>
      </Snackbar>
    </Card>
  )
}

const sxInput: SxProps = {
  margin: 1,
  width: '60%'
}