interface QueryError { // TODO: consolidate
  message: string;
  description: string;
  statusCode: string | number;
  status: string | number;
}

/**
 * for useQuery retry: never stops retrying unless it gets code 410 meaning expired
 * @param failureCount 
 * @param error 
 * @returns 
 */
export function retryStopOnlyExpired(failureCount: number, error: any): boolean {
  const queryError = error as QueryError
  // If error is a 410, do not retry
  if (queryError.status === 410) {
    return false
  }
  return true
}

/**
 * for useQuery retryDelay: starts from 2 secs and exponential back off max 7 secs
 * @param attemptIndex 
 * @returns delay in milliseconds 
 */
export function retryDelayExpBackOffUptoSevenSecs(attemptIndex: number): number {
  return Math.min(2000 ** attemptIndex, 7000)
}
