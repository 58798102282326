/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SubPromptOptionPointOfView {
    THIRD_PERSON = 'Third Person',
    FIRST_PERSON = 'First Person',
}
