import React from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { auth } from '../../service/api'
import { signOut } from 'firebase/auth'

export default function BlockedInactiveScreen() {

  return (
    <Box sx={sxBox}>
      <Typography>Your administrator deactivated your account</Typography>
      <Box sx={{ padding: 2 }} />

      <Typography>Please contact your administrator for access</Typography>
      <Box sx={{ padding: 1 }} />

    </Box>
  )
}

const sxBox: SxProps = {
  display: 'flex',
  height: '90vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}
