import React, { useEffect, useState } from 'react'
import { Typography, SxProps, TextField, Box, Dialog, DialogTitle, IconButton, DialogContent, Autocomplete, DialogActions, Snackbar, Alert, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import { addAutoShare } from '../../../service'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from 'styles/Theme'
import CloseIcon from '@mui/icons-material/Close'
import ProfilePhoto from '../../settings/ProfilePhoto'
import { Access, OrgUser, OrgUserRole } from 'client'
import { getOrg } from 'service/orgsApi'
import { LoadingButton } from '@mui/lab'
import CheckIcon from '@mui/icons-material/Check'
import { logInfo } from 'log'
import { auth } from 'service/api'

interface Props {
  open: boolean
  onClose: () => void
}

export default function AddAutoShareDialog({ open, onClose }: Props) {
  const queryClient = useQueryClient()
  const fbUser = auth.currentUser
  const [targetUser, setTargetUser] = useState<OrgUser | null>(null)
  const [access, setAccess] = useState<Access>(Access.VIEWER)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg, enabled: false
  })

  useEffect(() => {
    if (!open)
      return

    logInfo('AutoShareDialog, Add User Opened')
    setTargetUser(null)
  }, [open])

  function validUsersToShare() {
    if (!org?.org_user_list)
      return []
    return org.org_user_list
      .filter(u => u.org_user_role != OrgUserRole.INACTIVE && u.uid != fbUser?.uid)
  }

  async function onAdd() {
    setBusy(true)
    setErr(undefined)
    const res = await addAutoShare(targetUser, access)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
      setSnackSuccess(true)
      onClose()
    }
    setBusy(false)
  }

  return (
    <>

      <Dialog open={open} onClose={onClose}>
        <Box sx={gSx.RowBetween}>
          <DialogTitle>Select a User to Auto Share Meetings</DialogTitle>
          <IconButton
            sx={sxClose}
            aria-label='close'
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent>

          <Autocomplete
            id="add-people"
            options={validUsersToShare()}
            getOptionLabel={(option: OrgUser) => option.name ? `${option.name}, ${option.email}` : `${option.email}`}
            onChange={(e, t) => setTargetUser(t)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Share with a Person"
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" sx={sxSearchLineItem} {...props}>
                <Box sx={{ width: 50, paddingTop: '6px' }}>
                  <ProfilePhoto photoUrl={option.photo_url} width={40} />
                </Box>
                <Typography>
                  {option.name ? `${option.name},  ${option.email}` : `${option.email}`}
                </Typography>
              </Box>
            )}
            sx={{ width: 400 }}
          />

          <Box sx={{ padding: 2 }} />

          <FormControl sx={{ width: 100 }}>
            <InputLabel >
              Permission
            </InputLabel>
            <Select
              label="Permission"
              id="access"
              value={access}
              onChange={e => setAccess(e.target.value as Access)}
            >
              <MenuItem value={Access.VIEWER}>{Access.VIEWER}</MenuItem>
              <MenuItem value={Access.EDITOR}>{Access.EDITOR}</MenuItem>
            </Select>
          </FormControl>

        </DialogContent>

        <DialogActions>
          {err && <Typography color={'tomato'} >{err}</Typography>}
          <LoadingButton
            onClick={onAdd}
            disabled={busy || !targetUser}
            loading={busy}
            variant='contained'
            sx={{ width: 150 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog >

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Auto Share Successfully Updated
        </Alert>
      </Snackbar>
    </>
  )
}

const sxClose: SxProps = {
  alignSelf: 'flex-start',
}

const sxSearchLineItem: SxProps = {
  '& > img': { mr: 2, flexShrink: 0 },
}