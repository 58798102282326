import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getUsage } from '../service'
import { OrgUserRole, ProductName } from '../client'
import { getOrg, getOrgUser } from '../service/orgsApi'
import { BlockPlan } from './useIsBlockedByPlan'

export default function useIsEventOwnerBlocked(eventOwnerId?: string) {

  const { data: org, isLoading, refetch } = useQuery({
    queryKey: ['getOrg'],
    queryFn: getOrg,
    enabled: false
  })
  const orgUser = getOrgUser(org, eventOwnerId)

  const { data: usage, isLoading: isLoadingUsage, refetch: refetchUsage } = useQuery({
    queryKey: ['getUsage'],
    queryFn: getUsage,
    enabled: false
  })

  useEffect(() => {
    if (!org)
      refetch()
    if (!usage)
      refetchUsage()
  }, [org, usage])

  function disable() {

    const userPlan = orgUser?.org_user_plan
    if (!eventOwnerId)
      return false
    if (userPlan === ProductName.EXPIRED)
      return true
    if (orgUser?.org_user_role == OrgUserRole.INACTIVE)
      return true
    return false
  }

  const res: BlockPlan = {
    isBlocked: disable(),
    isLoading: isLoading || isLoadingUsage,
    userPlan: orgUser?.org_user_plan
  }
  return res
}