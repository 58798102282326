import React from 'react'
import { Typography, Box, Card, SxProps, Button, Skeleton, ButtonBase } from '@mui/material'
import 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery } from '@tanstack/react-query'
import { Media } from 'service/media'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { GetCalendarUser } from 'service/calendarApi'
import { isCalendarConnected, isFirebaseUserMicrosoft } from 'utils'

export default function MicrosoftCalendarIntegrationLine() {
  const navigate = useNavigate()

  const isMicrosoft = isFirebaseUserMicrosoft()

  const { data, isLoading } = useQuery({
    queryKey: ['GetCalendarUser'],
    queryFn: async () => {
      if (isMicrosoft) {
        return await GetCalendarUser()
      }
    },
  })
  const isCalConnected = isCalendarConnected(data)
  const ms = data?.connections?.filter(conn => conn.platform === 'microsoft')[0]

  return (
    <Card>
      <ButtonBase sx={sxContainer}
        onClick={() => navigate(Path.CalendarAuth)}
        disabled={!isMicrosoft}
      >
        <Box
          component="img"
          sx={sxLogo}
          src={Media.microsoft100}
        />
        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={50} />
            : (
              isMicrosoft ? (
                isCalConnected ? (
                  <Box>
                    <Typography>Microsoft Calendar Connected</Typography>
                    <Typography>{ms?.email}</Typography>
                  </Box>
                ) : (
                  <Button>Outlook Calendar Sign In</Button>
                )
              ) : (
                <Typography color={'lightgray'}>Please Use Microsoft Single Sign On</Typography>
              )
            )
        }
        <ChevronRightIcon sx={{ color: isMicrosoft ? 'black' : 'lightgray' }} />
      </ButtonBase>
    </Card>
  )
}

const sxLogo: SxProps = {
  width: 38,
}

const sxContainer: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}