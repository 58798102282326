/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RingCentralContact } from '../models/RingCentralContact';
import type { RingCentralRecord } from '../models/RingCentralRecord';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RingcentralService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Auth
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public ringCentralAuth(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/ringcentral/auth',
            query: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Disconnect
     * @returns any Successful Response
     * @throws ApiError
     */
    public ringCentralDisconnect(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/integrations/ringcentral/auth',
        });
    }

    /**
     * Get Profile
     * @returns RingCentralContact Successful Response
     * @throws ApiError
     */
    public ringCentralProfile(): CancelablePromise<RingCentralContact> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/ringcentral/profile',
        });
    }

    /**
     * Get Call Logs
     * @returns RingCentralRecord Successful Response
     * @throws ApiError
     */
    public ringCentralCallLogs(): CancelablePromise<Array<RingCentralRecord>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/ringcentral/call_log',
        });
    }

    /**
     * Create Event From Call Log
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createEventFromCallLog(
        requestBody: RingCentralRecord,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/ringcentral/create_event_from_call_log',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
