import React from 'react'
import { Box, Button } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'
import SalesforceImportContacts from 'components/integrations/salesforce/SalesforceImportContacts'
import { getWealthboxProfile } from 'service/integrations/wealthboxApi'
import WealthboxImportContacts from 'components/integrations/wealthbox/WealthboxImportContacts'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import RedtailImportContacts from './redtail/RedtailImportContacts'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'

export default function ImportContactsListButtons() {
  const navigate = useNavigate()

  const { data: salesforce } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
  })
  const isSalesforceSignedIn = !!salesforce?.scope

  const { data: wealthboxProfile } = useQuery({
    queryKey: ['getWealthboxProfile'], queryFn: getWealthboxProfile,
  })
  const isWealthboxSignedIn = !!wealthboxProfile?.id

  const { data: redtailSettings } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
  })
  const isRedtailSignedIn = !!redtailSettings?.user_id

  return (
    <>
      {isSalesforceSignedIn && salesforce.app &&
        <>
          <Box sx={{ padding: 1 }} />
          <SalesforceImportContacts app={salesforce.app} />
        </>
      }
      {isWealthboxSignedIn &&
        <>
          <Box sx={{ padding: 1 }} />
          <Button onClick={() => navigate(Path.WealthboxSettings)} variant='outlined'>
            Import Wealthbox Contacts
          </Button>
        </>
      }

      {isRedtailSignedIn &&
        <>
          <Box sx={{ padding: 1 }} />
          <RedtailImportContacts />
        </>
      }
    </>
  )
}
