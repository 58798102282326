import React, { useEffect, useState } from 'react'
import { Box, SxProps, Typography, IconButton, TextField, Button, Skeleton, Chip, Avatar, Snackbar, Alert } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { formatDateToMMMDDYYYY } from '../../utils'
import EditIcon from '@mui/icons-material/Edit'
import { EventBase, FirebaseUser } from '../../client'
import { gSx } from '../../styles/Theme'
import CheckIcon from '@mui/icons-material/Check'
import { updateEventTitle } from '../../service/eventApi'
import ProfilePhoto from 'components/settings/ProfilePhoto'

interface Props {
  owner?: FirebaseUser
  data?: EventBase
  disabled?: boolean
}

export default observer(EventTitle)
function EventTitle({ data, disabled, owner }: Props) {
  const startDateObj = new Date(data?.start_time ?? '0')
  const [title, setTitle] = useState(data?.title ?? '')
  const [editTitle, setEditTitle] = useState(data?.title ?? '')
  const [isEdit, setEdit] = useState(false)

  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  useEffect(() => {
    if (data) {
      setTitle(data?.title ?? '')
      setEditTitle(data?.title ?? '')
    }
  }, [data])

  async function handleSave() {
    setErr(undefined)
    if (data) {
      setEdit(false)
      const res = await updateEventTitle(data.id, editTitle)
      if (res instanceof Error) {
        setErr(res.message)
      } else {
        setTitle(res)
        setSnackSuccess(true)
      }
    }
  }

  function handleCancel() {
    setEdit(false)
    setEditTitle(title)
  }

  return (
    <Box sx={{ width: '100%', paddingBottom: 2 }}>

      {isEdit ? (
        <Box sx={gSx.RowBetween}>
          <TextField
            value={editTitle}
            onChange={(t) => {
              setEditTitle(t.target.value)
            }}
            sx={sxTextField}
          />
          <Button aria-label='save' variant='contained' onClick={handleSave}>
            Save
          </Button>

          <Box sx={{ paddingX: '1vh' }} />

          <Button aria-label='cancel' variant='outlined' onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      ) : (
        <Box sx={gSx.RowBetween}>
          <Box sx={gSx.Row}>

            {(!data || !data.owner_id)
              ? <Skeleton variant="rectangular" width={350} height={40} />
              : <Typography variant='h3'>{title}</Typography>
            }

            <IconButton
              aria-label='edit title'
              onClick={() => setEdit(true)}
              disabled={disabled || !data}
            >
              <EditIcon fontSize='small' />
            </IconButton>

          </Box>
        </Box>
      )}

      {err && <Typography sx={{ color: 'tomato' }}>{'gvfsafsdfasdf'}</Typography>}

      {!data
        ? <Skeleton variant="rectangular" width={100} height={20} />
        : (
          <Box sx={gSx.Row}>
            <Chip
              avatar={<ProfilePhoto photoUrl={owner?.photo_url} width={25} />}
              label={owner?.display_name}
              variant="outlined"
              sx={{ paddingX: 0.5 }}
            />
            <Box sx={{ padding: 1 }} />
            <Typography sx={sxDate}>
              {`${formatDateToMMMDDYYYY(startDateObj)}, ${startDateObj.toLocaleTimeString()}`}
            </Typography>
          </Box>
        )
      }

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Record Preference Updated Succesfully
        </Alert>
      </Snackbar>
    </Box>
  )
}

const sxTextField: SxProps = {
  width: '100%',
  marginRight: '3vh',
}

const sxDate: SxProps = {
}