import React from 'react'
import { Box } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import ContactMailIcon from '@mui/icons-material/ContactMail'

export const SummaryRerunIcon = () => {
  return (
    <Box position="relative" display="inline-block" sx={{ marginRight: "15px" }}>
      <ReplayIcon sx={{ color: 'gray' }} />
      <TextSnippetIcon
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          fontSize: 16,
          color: 'gray',
          backgroundColor: 'background.paper',
          borderRadius: '50%',
        }}
      />
    </Box>
  )
}
export const EmailRerunIcon = () => {
  return (
    <Box position="relative" display="inline-block" sx={{ marginRight: "15px" }}>
      <ReplayIcon sx={{ color: 'gray' }} />
      <ContactMailIcon
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          fontSize: 16,
          color: 'gray',
          backgroundColor: 'background.paper',
          borderRadius: '50%',
        }}
      />
    </Box>
  )
}
