import React, { useState } from 'react'
import { Box, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, SxProps, Typography } from '@mui/material'
import 'firebase/auth'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { authenticate } from '../../../service/integrations/redtailApi'
import { useQueryClient } from '@tanstack/react-query'
import { logInfo } from 'log'
import { LoadingButton } from '@mui/lab'
import { gSx } from 'styles/Theme'

export default function RedtailSignIn() {
  const queryClient = useQueryClient()
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  async function onConnect() {
    setBusy(true)
    setErr(undefined)
    const res = await authenticate(user, password)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getRedtailSettings'] })
    }
    setBusy(false)
  }

  return (
    <Box sx={sxWrapper}>

      <TextField
        sx={{ margin: 1 }}
        onChange={(t) => setUser(t.target.value)}
        id="username"
        label="Username"
        variant="outlined"
        value={user}
      />

      <FormControl sx={{ margin: 1 }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormControl>

      {err &&
        <Typography sx={{ color: 'tomato' }}>{err}</Typography>
      }
      <Box sx={gSx.RowBetween}>
        <Box />
        <LoadingButton
          sx={{ width: 200 }}
          variant='contained'
          onClick={onConnect}
          loading={busy}
        >
          Connect
        </LoadingButton>
      </Box>

      <Box>
        <Button
          href='https://smf.accounts.redtailtechnology.com/forgotpassword'
          target="_blank"
          rel="noopener"
        >
          Forgot your Password?
        </Button>
      </Box>
    </Box>
  )
}

const sxWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
}
