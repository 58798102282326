import React from 'react'
import { Box, ButtonBase, Card, Link, SxProps, Tooltip, Typography } from '@mui/material'
import { gColor, gSx } from '../../styles/Theme'
import { Media } from '../../service/media'
import { auth } from 'service/api'
import Grid from '@mui/material/Unstable_Grid2'
import { useQuery } from '@tanstack/react-query'
import { getUsage } from 'service'

export default function Guide() {
  const fbUser = auth.currentUser

  const { data: usage, isLoading } = useQuery({
    queryKey: ['getUsage'], queryFn: getUsage,
  })
  const numEvents = usage?.events?.length ?? 0

  if (isLoading || numEvents > 0)
    return null

  return (
    <Card sx={sxCard}>
      {
        fbUser?.displayName
          ? <Typography variant='h3'>Welcome {fbUser.displayName}! </Typography>
          : <Typography variant='h3'>Welcome!</Typography>
      }

      <Box sx={{ padding: 1 }} />
      <Typography>{`To get started, have FinMate AI join your conversations, whether online, over the phone, or in person.`}</Typography>

      <Box sx={{ padding: 1 }} />

      <Grid container spacing={2}>

        <Grid xs={12} md={6} sx={sxGridCell}>

          <Box sx={gSx.CenterVertical}>
            <Box>
              <Box sx={gSx.Row}>
                <Tooltip title='Zoom'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.zoomLogo}
                    alt="Zoom"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>Zoom</Typography>
              </Box>

              <Box sx={gSx.Row}>
                <Tooltip title='Google Meet'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.meetLogo}
                    alt="Google Meet"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>Google Meet</Typography>
              </Box>

              <Box sx={gSx.Row}>
                <Tooltip title='Microsoft Teams'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.teamsLogo}
                    alt="Teams Logo"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>Microsoft Teams</Typography>
              </Box>

              <Box sx={gSx.Row}>
                <Tooltip title='Webex'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.webexLogo}
                    alt="Webex Logo"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>Webex</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid xs={12} md={6} sx={sxGridCell}>
          <ButtonBase
            href='https://apps.apple.com/us/app/finmate-ai/id6469643088'
            target="_blank"
            rel="noopener"
          >
            <Box
              component="img"
              sx={{ width: 135, height: 43 }}
              src={Media.appleAppStore}
            />
          </ButtonBase>

          <Box sx={{ padding: 1 }} />

          <ButtonBase
            href='https://play.google.com/store/apps/details?id=com.finmate.ai'
            target="_blank"
            rel="noopener"
          >
            <Box
              component="img"
              sx={{ width: 135, height: 43 }}
              src={Media.googlePlayStore}
            />
          </ButtonBase>
        </Grid>
      </Grid>
    </Card>
  )
}

const sxGridCell: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%'
}

const sxCard: SxProps = {
  border: '5px solid',
  borderColor: gColor.light,
  display: 'flex',
  flexDirection: 'column',
}

const sxIcon: SxProps = {
  marginLeft: '5px',
  width: '40px'
}

const sxVideoText: SxProps = {
  paddingLeft: 2,
  fontWeight: 'bold'
}