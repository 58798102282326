import React, { useState } from 'react'
import { Box, Button, Container, SxProps, TextField, Typography } from '@mui/material'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../service/api'

function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [msg, setMsg] =  useState('')
  const [err, setErr] = useState('')

  async function submit() {
    setMsg('')
    setErr('')
    try {
      await sendPasswordResetEmail(auth, email)
      setMsg('If you have an account, an email will be sent to reset your password')
    } catch (e) {
      (e instanceof Error) ? setErr(e.message) : setErr(JSON.stringify(e))
    }
  }

  return (
    <Box sx={sx.page}>
      <Box sx={sx.content}>
        <Typography variant='h5'>FinMate AI</Typography>
        <Typography variant='h4'>Forgot Password</Typography>

        <Box sx={{ height: 30 }} />
        <Typography>{'Enter your Email, and we\'ll send password reset instructions'}</Typography>
        <Box sx={{ height: 30 }} />

        <TextField
          autoFocus
          onChange={(t) => setEmail(t.target.value)}
          sx={sx.input} 
          id="email" 
          label="Email" 
          variant="filled"
          value={email} 
        />

        { msg ? <Typography color='success.main'>{msg}</Typography> : null }
        { err ? <Typography color='error.main'>{err}</Typography> : null }

        <Button
          onClick={async () => await submit()} 
          sx={sx.button} 
          variant="contained" 
          size="large"
        >
          Reset Password
        </Button>
      </Box>
    </Box>
  )
}

export default ForgotPassword

const sx = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: 'success.dark'
  } as SxProps,
  content: {
    margin: 'auto',
    marginTop: 10,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    backgroundColor: '#f0f0f0',
    borderRadius: 10
  } as SxProps,
  input: {
    margin: 1,
  } as SxProps,
  button: {
    margin: 2,
  } as SxProps,
}
