import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, SxProps, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import SearchEventTitle from './SearchEventTitle'
import SearchEventDates from './SearchEventDates'
import { useStore } from 'hooks/useStore'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getClientDetail } from 'service/clientApi'

interface Props {
  setPage: (pageNum: number) => void
}
export default observer(SearchEventBar)
function SearchEventBar({ setPage }: Props) {
  const store = useStore()
  const queryClient = useQueryClient()
  const params = store.User.getEventSearchParams()
  const { id: clientId } = useParams() // client_id

  const [title, setTitle] = useState(params.title ?? '') // pre-debounce
  const [isShowDate, setIsShowDate] = useState(true)
  const [isRefreshBusy, setRefreshBusy] = useState(false)

  useEffect(() => {
    // if search by title, hide dates
    if (!title || (title.length == 0))
      setIsShowDate(true) // for searching by dates
    else if (title.length == 1) {
      // using 1 letter as a transition between search by title and date
      setPage(1)
      setIsShowDate(false)
      store.User.setEventSearchParams({ ...params, earliest: undefined, latest: undefined })
    }
    // title search starts 2+ letters.

  }, [title])

  async function onClearSearch() {
    setTitle('')
    setPage(1)

    if (clientId) {
      const client = await getClientDetail(clientId)
      store.User.setEventSearchParams({ client_email: client?.email })
    } else {
      store.User.setEventSearchParams({})
    }
  }

  async function onRefresh() {
    setRefreshBusy(true)

    if (clientId) {
      const client = await getClientDetail(clientId)
      store.User.setEventSearchParams({ client_email: client?.email })
    } else {
      store.User.setEventSearchParams({})
    }

    await queryClient.invalidateQueries({ queryKey: ['getEvents'] })
    setRefreshBusy(false)
  }

  return (
    <>
      <Box sx={sxBar}>
        <Box>
          <SearchEventTitle
            title={title}
            setTitle={setTitle}
          />
          {title && title.length == 1 &&
            <Typography sx={{ color: 'tomato' }}>Please type one more letter to search</Typography>
          }
        </Box>

        <Box sx={{ paddingX: '2vh' }} />

        <SearchEventDates
          isShowDate={isShowDate}
        />

        <Box sx={{ paddingX: '2vh' }} />
        {
          (params.earliest || params.latest || params.title) &&
          <Button
            variant='outlined'
            onClick={onClearSearch}
            sx={{ color: 'tomato', borderColor: 'tomato' }}
          >
            Clear
          </Button>
        }

        <Box sx={{ paddingX: '2vh' }} />

        <Button
          variant='outlined'
          onClick={onRefresh}
          startIcon={isRefreshBusy ? <CircularProgress size={15} /> : <RefreshIcon />}
          disabled={isRefreshBusy}
        >
          {isRefreshBusy ? 'Refreshing' : 'Refresh'}
        </Button>

      </Box >

    </>
  )
}

const sxBar: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: '2vh'
}