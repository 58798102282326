import React from 'react'
import { CSSTransition } from 'react-transition-group'
import "./styles.css"
import { Box } from '@mui/material'

interface Props {
  children: React.ReactNode
  active: boolean
}

export default function TransitionWrapper({ children, active }: Props) {

  return (
    <CSSTransition
      in={active}
      timeout={1000}
      classNames="fade"
      mountOnEnter
      unmountOnExit
    >
      <div className="fade">
        <Box sx={{
          padding: 10,
          minWidth: '380px',
          maxWidth: '500px',
          display: 'flex',
          flexDirection: 'column'
        }}
        >
          {children}
        </Box>
      </div>
    </CSSTransition>

  )
}