import React, { useState } from 'react'
import { Dialog } from '@mui/material'
import { EventBase, OrgUser } from '../../../client'
import ShareEventAddAccess from './ShareEventAddAccess'
import ShareEventMain from './ShareEventMain'

interface Props {
  open: boolean
  onClose: () => void
  data?: EventBase
  disabled?: boolean
  setOpenSuccessSnack: (isOpen: boolean) => void
}

export default function ShareEventDialog({ onClose, open, data, disabled, setOpenSuccessSnack }: Props) {
  const [input, setInput] = useState<OrgUser>({})
  const [isAddStep, setIsAddStep] = useState(false)

  function handleClose() {
    setIsAddStep(false)
    onClose()
  }
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>

      {
        isAddStep
          ? <ShareEventAddAccess
            input={input}
            data={data}
            onShow={setIsAddStep}
            onClose={handleClose}
            disabled={disabled}
            setOpenSuccessSnack={setOpenSuccessSnack}
          />
          : (
            <ShareEventMain
              setInput={setInput}
              onAdd={setIsAddStep}
              onClose={handleClose}
              eventId={data?.id}
              disabled={disabled}
              setOpenSuccessSnack={setOpenSuccessSnack}
            />
          )

      }
    </Dialog>
  )
}
