/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WebhooksService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Recall Webhook
     * @returns any Successful Response
     * @throws ApiError
     */
    public recallWebhookWebhooksBotsPost(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhooks/bots',
        });
    }

    /**
     * Post Webhook Realtime
     * @returns any Successful Response
     * @throws ApiError
     */
    public postWebhookRealtimeWebhooksBotsRealtimePost(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhooks/bots/realtime',
        });
    }

    /**
     * Stripe Webhook
     * @param stripeSignature
     * @returns any Successful Response
     * @throws ApiError
     */
    public stripeWebhookWebhooksPayPost(
        stripeSignature?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhooks/pay',
            headers: {
                'stripe-signature': stripeSignature,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Zoom Webhook
     * @param zoomSignature
     * @returns any Successful Response
     * @throws ApiError
     */
    public zoomWebhookWebhooksZoomPost(
        zoomSignature?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhooks/zoom',
            headers: {
                'zoom-signature': zoomSignature,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ringcentral Webhook
     * @param validationToken
     * @returns any Successful Response
     * @throws ApiError
     */
    public ringcentralWebhookWebhooksRingcentralPost(
        validationToken?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhooks/ringcentral',
            headers: {
                'validation-token': validationToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
