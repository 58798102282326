import React, { useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, SxProps, Typography } from '@mui/material'
import { logErr, logInfo } from '../../../log'
import { EventBase, SalesforceApp } from '../../../client'
import { salesforceAppIconUrl } from '../../../service/media'
import { Path } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { MasterTask } from '../SelectTasks'
import SalesforceNotesCard from './SalesforceNotesCard'
import SalesforceTasksCard from './SalesforceTasksCard'
// import SalesforceFlowsCard from './SalesforceFlowsCard'
import { gSx } from 'styles/Theme'
import CrmContactCard from '../CrmContactCard'
import SalesforceFlowsCard from './SalesforceFlowsCard'

interface Props {
  open: boolean
  onClose: () => void
  event: EventBase
  eventSummaryHtml?: string
  app: SalesforceApp
}

export default function SalesforceDialog({ open, onClose, event, eventSummaryHtml, app }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { isBlocked } = useIsBlockedByPlan(true)
  const [masterTasks, setMasterTasks] = useState<MasterTask[]>([])

  useEffect(() => {
    if (open) {
      logInfo(`${app} NoteSender Dialog Open`)
      queryClient.invalidateQueries({ queryKey: ['getEvent', event?.id] })
    }
  }, [open])

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}
      PaperProps={{ style: { height: '98vh', margin: 0, maxHeight: '98vh' } }}
    >
      <DialogTitle sx={gSx.Row}>
        <Box
          component="img"
          sx={icon}
          src={salesforceAppIconUrl(app)}
          alt={app}
        />
        <Typography variant='h3'>
          Send to {app}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {isBlocked &&
          <Typography sx={sxText}>{`Please `}
            <Link onClick={() => navigate(Path.Billing)}>Upgrade your plan</Link>
          </Typography>
        }
        <Box sx={{ padding: 1 }} />

        <CrmContactCard event={event} />

        <SalesforceNotesCard
          event={event}
          eventSummaryHtml={eventSummaryHtml}
          app={app}
        />

        <SalesforceTasksCard
          event={event}
          app={app}
          masterTasks={masterTasks}
          setMasterTasks={setMasterTasks}
        />

        <SalesforceFlowsCard
          event={event}
          app={app}
          contact={event.client}
          tasks={masterTasks}
        />

      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>

    </Dialog >
  )
}

const icon: SxProps = {
  width: 40,
  height: 40,
  marginRight: 2
}

const sxText: SxProps = {
  paddingY: '5px',
  cursor: 'pointer'
}
