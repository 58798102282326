import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { CertificateOfDeposit } from '../../client'

interface Props {
  eventId?: string
  certificateOfDepositList?: CertificateOfDeposit[]
}

export default function CertificatesOfDepositTable({ eventId, certificateOfDepositList }: Props) {

  const cellRef = useRef(null)
  const [data, setCertificateOfDepositList] = useState<CertificateOfDeposit[]>([])

  useEffect(() => {
    if (certificateOfDepositList) {
      console.log("certificateOfDepositList", certificateOfDepositList)
      setCertificateOfDepositList(certificateOfDepositList)
    }
  }, [certificateOfDepositList])

  const columns = useMemo<MRT_ColumnDef<CertificateOfDeposit>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        size: 100
      },
      {
        accessorKey: 'Where Held?',
        header: 'Where Held?',
        size: 100
      },

      {
        accessorKey: 'Interest Rate',
        header: 'Interest Rate',
        size: 100
      },
      {
        accessorKey: 'Current Value',
        header: 'Current Value',
        size: 100
      },
      {
        accessorKey: 'Maturity Date',
        header: 'Maturity Date',
        size: 100
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data,
  })

  return (
    <MRT_Table table={table} />
  )
}
