import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import CheckIcon from '@mui/icons-material/Check'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Snackbar, Typography } from '@mui/material'
import { EventBase, WealthboxType } from '../../../client'
import { gColor, gSx } from '../../../styles/Theme'
import { LoadingButton } from '@mui/lab'
import { sendNotesToWealthbox } from 'service/integrations/wealthboxApi'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface Props {
  event: EventBase
  eventSummaryHtml: string
  wbType: WealthboxType
}

export default function WealthboxNotesCard({ event, eventSummaryHtml, wbType }: Props) {
  const queryClient = useQueryClient()
  const hasClient = !!event?.client?.id
  const { isBlocked } = useIsBlockedByPlan(true)
  const isSent = !!event.wealthbox_sent
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [expanded, setExpanded] = useState(!isSent)
  const disableSend = isBlocked || !hasClient || busy

  async function onSend() {
    setBusy(true)
    setErr(undefined)

    const res = await sendNotesToWealthbox(event.id, eventSummaryHtml, event.client, wbType)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      queryClient.invalidateQueries(['getEvent', event.id])
      setSnackSuccess(true)
      setExpanded(false)
    }
    setBusy(false)
  }

  return (
    <>
      <Accordion
        sx={gSx.Crm.Accordian}
        defaultExpanded={!isSent}
        expanded={expanded}
        onChange={() => setExpanded(state => !state)}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={gSx.Crm.AccordianSummary}
        >
          <Box sx={gSx.Row}>
            {isSent
              ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
              : <TextSnippetOutlinedIcon sx={gSx.Crm.LeftIcon} />
            }
            <Typography variant='h5'>Meeting Summary</Typography>
          </Box>
          {isSent &&
            <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
          }
        </AccordionSummary>

        <AccordionDetails>
          <Typography>Send the Meeting Summary as a Wealthbox Note.</Typography>

          {err && <Typography color={'tomato'}>{err}</Typography>}

          <Box sx={gSx.RowBetween}>
            <Box />
            <Box sx={gSx.Row}>

              <LoadingButton
                variant={'contained'}
                onClick={onSend}
                disabled={disableSend}
                loading={busy}
                sx={{ marginLeft: 3 }}
              >
                Send Notes
              </LoadingButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Meeting Summary Sent
        </Alert>
      </Snackbar>
    </>
  )
}
