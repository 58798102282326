import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import { logInfo } from '../../log'

export default function UpgradeButton() {
  const navigate = useNavigate()

  function onBilling() {
    logInfo('UpgradeButton Pressed')
    navigate(Path.Organization)
  }

  return (
    <Button
      variant='contained'
      onClick={onBilling}
    >
      Upgrade
    </Button>
  )
}
