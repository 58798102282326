import React, { useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Box, Button, Card, IconButton, Skeleton, SxProps, TextField, Typography } from '@mui/material'
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { gSx } from '../../styles/Theme'
import { OrgUserRole } from '../../client'
import { getOrg, getOrgUser, setOrgName } from '../../service/orgsApi'
import { getUser } from 'service'
import { auth } from 'service/api'

const OrgName = () => {
  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg
  })
  const fbUser = auth.currentUser
  const orgUser = getOrgUser(org, fbUser?.uid)
  const isAdmin = orgUser?.org_user_role == OrgUserRole.ADMIN

  const queryClient = useQueryClient()

  const [editName, setEditName] = useState('')
  const [isEdit, setEdit] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['getOrg'],
    queryFn: async () => await getOrg(),
  })

  const mutation = useMutation({
    mutationFn: async (newOrgName: string) => {
      await setOrgName(newOrgName)
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['getOrg'] })
    },
  })

  async function handleSave() {
    mutation.mutate(editName)
    setEdit(false)
  }

  async function handleCancel() {
    setEdit(false)
  }

  function startEdit() {
    if (data) {
      setEditName(data.org_name)
    }
    setEdit(true)
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'row' }}>

      <Grid container alignItems="center" sx={{ width: '100%' }}>
        <Grid xs={12} md={3}>
          <Typography variant='h6'>Organization Name</Typography>
        </Grid>

        {
          isLoading
            ? (<Grid xs={12} md={9}>
              <Skeleton variant="rectangular" width={'80%'} height={30} />
            </Grid>
            )
            : (
              isEdit ? (
                <Grid xs={12} md={9} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    value={editName}
                    onChange={(t) => {
                      setEditName(t.target.value)
                    }}
                    sx={sxTextField}
                  />
                  <Button aria-label='save' variant='contained' onClick={handleSave} sx={{ paddingX: 6 }}>
                    Save
                  </Button>

                  <Box sx={{ paddingX: '1vh' }} />

                  <Button aria-label='cancel' variant='outlined' onClick={handleCancel} sx={{ paddingX: 5 }}>
                    Cancel
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid xs={12} md={8}>
                    <Typography variant='h4'>{data?.org_name}</Typography>
                  </Grid>
                  <Grid xs={12} md={1} sx={sxEditIcon}>
                    {isAdmin ?
                      (<IconButton onClick={() => startEdit()} aria-label='edit organization name'>
                        <EditIcon fontSize='small' />
                      </IconButton>
                      ) : (<></>)
                    }
                  </Grid>
                </>
              )
            )
        }

      </Grid>
    </Card>
  )
}

const sxTextField: SxProps = {
  width: '70vh',
  marginRight: '3vh',
}

const sxEditIcon: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end'
}

export default OrgName