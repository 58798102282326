import React, { useState, useRef, useEffect } from 'react'
import { List, ListItem, ListItemText, IconButton, TextField, Grid, Box, Typography, Checkbox, Snackbar, Alert, Tooltip, Skeleton, Chip, SxProps } from '@mui/material'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getEventTasks, getTaskAssignee, updateEventTasks } from '../../service/eventApi'
import { ActionItem, ActionItemList, ActionItemStatus, AssigneeInfo } from '../../client'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { retryDelayExpBackOffUptoSevenSecs } from '../../hooks/hookHelpers'
import TaskAssigneeChip from './TaskAssigneeChip'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

interface Props {
  eventId?: string,
  updateTasksForHtml?: (tasks: ActionItem[]) => void;
  disabled?: boolean
  tasksListOverride?: ActionItem[] // for examples
}

export default EventTaskList
function EventTaskList({ eventId, updateTasksForHtml, disabled, tasksListOverride }: Props) {
  const [tasks, setTasks] = useState<ActionItem[]>([])
  const [newItemDescription, setNewItemDescription] = useState<string>('')
  const [isEdited, setIsEdited] = useState(false)
  const [editItemId, setEditItemId] = useState<number | undefined>(undefined)
  const [editedDescription, setEditedDescription] = useState<string>('')
  const [hoveredItemId, setHoveredItemId] = useState<number | undefined>(undefined)
  const updatePendingTimeout = useRef<NodeJS.Timeout | undefined>(undefined)
  const refreshPendingTimeout = useRef<NodeJS.Timeout | undefined>(undefined)
  const queryClient = useQueryClient()
  const [showSaveNotif, setShowSaveNotif] = useState(false)
  const [errMsg, setErrMsg] = useState<string | undefined>(undefined)
  const [busy, setBusy] = useState(false)

  const { data: tasksList, isLoading } = useQuery({
    queryKey: ['getEventTasks', eventId],
    queryFn: async () => await getEventTasks(eventId),
    retry: 3,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
    refetchOnWindowFocus: tasks.length == 0,
    enabled: !tasksListOverride
  })

  useEffect(() => {
    if (tasksListOverride) {
      setTasks(tasksListOverride)
    } else if (tasksList && tasksList.items_edited) {
      setTasks(tasksList.items_edited)
    }
  }, [tasksList])

  useEffect(() => {
    if (tasks && updateTasksForHtml) {
      updateTasksForHtml(tasks)
    }
  }, [tasks])

  const handleAddItem = async () => {
    if (newItemDescription.trim() !== '') {
      const newItem: ActionItem = {
        id: tasks.length,
        description: newItemDescription,
      }
      const updatedTasks = [...tasks, newItem]
      setTasks(updatedTasks)
      updatePending(updatedTasks)
      setNewItemDescription('')
    }
  }

  const handleDeleteItem = async (id: number | undefined) => {
    if (id != undefined) {
      const updatedTasks = tasks.filter((item) => item.id !== id)
      setTasks(updatedTasks)
      updatePending(updatedTasks)
    }
  }

  const handleItemClick = async (item: ActionItem) => {
    await handleUpdateDescription()
    setEditItemId(item.id)
    setEditedDescription(item.description ?? "")

  }

  const handleUpdateDescription = async () => {
    if (isEdited) {
      const updatedTasks = tasks.map((item) =>
        item.id === editItemId ? { ...item, description: editedDescription } : item
      )
      setTasks(updatedTasks)
      updatePending(updatedTasks)
    }
  }

  const handleUpdateDescriptionAndDeselect = async () => {
    await handleUpdateDescription()
    setEditItemId(undefined)
    setIsEdited(false)
  }

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      await handleUpdateDescriptionAndDeselect()
    }
  }

  const handleKeyPressNewItem = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      await handleAddItem()
    }
  }

  const handleMouseEnter = (itemId: number | undefined) => {
    if (itemId != undefined) {
      setHoveredItemId(itemId)
    }
  }

  const updatePending = (updatedTasks: ActionItem[]) => {
    if (updatePendingTimeout.current) {
      clearTimeout(updatePendingTimeout.current)
    }
    updatePendingTimeout.current = setTimeout(async () => {
      setBusy(true)
      const res = await updateEventTasks(updatedTasks, eventId)
      if (res instanceof Error) { setErrMsg(res.message) }
      else { setErrMsg(undefined) }
      setShowSaveNotif(true)
      setBusy(false)
    }, 700)
    refreshPending()
  }

  const refreshPending = () => {
    if (refreshPendingTimeout.current) {
      clearTimeout(refreshPendingTimeout.current)
    }
    refreshPendingTimeout.current = setTimeout(async () => {
      queryClient.invalidateQueries(['getEventTasks', eventId])
    }, 2000)
  }

  const handleMouseLeave = () => {
    setHoveredItemId(undefined)
  }

  const updateEditedDescription = (updatedDescription: string) => {
    setIsEdited(true)
    setEditedDescription(updatedDescription)
  }

  const updateTaskStatus = async (idx: number | undefined) => {
    if (idx != undefined) {
      let newItemStatus = ActionItemStatus.DONE
      if (tasks[idx].status == ActionItemStatus.DONE) {
        newItemStatus = ActionItemStatus.TODO
      }
      const updatedTasks = tasks.map((item) =>
        item.id === idx ? { ...item, status: newItemStatus } : item
      )
      setTasks(updatedTasks)
      updatePending(updatedTasks)
    }
  }
  const updateAssignee = async (assignee: AssigneeInfo) => {
    setIsEdited(true)
    const updatedTasks = tasks.map((item) =>
      item.id === hoveredItemId
        ? {
          ...item,
          description: item.description,
          assignee: assignee.name ?? '',
          assignee_info: assignee
        }
        : item
    )
    setTasks(updatedTasks)
    const res = await updateEventTasks(updatedTasks, eventId)
    if (res instanceof Error) { setErrMsg(res.message) }
    else { setErrMsg(undefined) }
    setShowSaveNotif(true)
  }

  const updateTaskDueDate = async (task: ActionItem, value: Dayjs | null) => {
    const updatedTasks = tasks.map((item) =>
      item.id === task.id
        ? {
          ...item,
          due_date_datetime: dayjs(value).toISOString(),
        }
        : item
    )
    setTasks(updatedTasks)
    const res = await updateEventTasks(updatedTasks, eventId)
    if (res instanceof Error) { setErrMsg(res.message) }
    else { setErrMsg(undefined) }
    setShowSaveNotif(true)
  }

  if (isLoading && !tasksListOverride) {
    return <Box>
      <Skeleton variant="rectangular" width={140} />
      <Box sx={{ padding: '5px' }} />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
    </Box>
  }

  return (
    <Box>
      <Typography variant="h4" align="left" sx={{ fontWeight: 'bold', paddingLeft: 1 }}>
        Tasks
      </Typography>
      <List>
        {tasks.map((task) => (
          <ListItem
            key={task.id}
            dense
            onMouseEnter={() => handleMouseEnter(task.id)}
            onMouseLeave={handleMouseLeave}
            sx={sxListitem}
          >
            <Grid container spacing={1} alignItems="center">
              {editItemId === task.id ? (
                <Grid item xs>
                  <ClickAwayListener onClickAway={handleUpdateDescriptionAndDeselect}>
                    <TextField
                      label="Task"
                      value={editedDescription}
                      onChange={(e) => updateEditedDescription(e.target.value)}
                      onKeyDown={handleKeyPress}
                      autoFocus
                      fullWidth
                      multiline
                      disabled={disabled || busy}
                    />
                  </ClickAwayListener>
                </Grid>

              ) : (
                <>
                  <Grid item>
                    <Checkbox
                      edge="start"
                      checked={task.status == ActionItemStatus.DONE}
                      onClick={() => updateTaskStatus(task.id)}
                      disabled={disabled || busy}
                    />
                  </Grid>

                  <Grid
                    item xs
                    onClick={() => handleItemClick(task)}
                    sx={{ paddingRight: "4px" }}
                  >
                    <ListItemText
                      primary={task.description}
                      style={{ textDecoration: task.status == ActionItemStatus.DONE ? 'line-through' : 'none' }}
                    />
                  </Grid>

                </>
              )}
              <Grid item >
                <TaskAssigneeChip
                  assignee={getTaskAssignee(task)}
                  disabled={disabled || busy}
                  eventId={eventId}
                  changeAssignee={updateAssignee}
                />
              </Grid>

              <Grid item >
                <DatePicker
                  label="Due Date"
                  value={dayjs(task.due_date_datetime)}
                  onChange={(value) => updateTaskDueDate(task, value)}
                  sx={{ width: '150px' }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </Grid>

              <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px', width: '48px' }}>
                <Tooltip title='Delete Item'>
                  <IconButton
                    edge="end"
                    aria-label="delete task"
                    style={{ visibility: hoveredItemId === task.id ? 'visible' : 'hidden' }}
                    onClick={(e) => { e.stopPropagation(); handleDeleteItem(task.id) }}
                    disabled={disabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

            </Grid>
          </ListItem>
        ))}
        <ListItem>

          <Grid container alignItems="center" spacing={1}>

            <Grid item xs>
              <ClickAwayListener onClickAway={handleAddItem}>
                <TextField
                  label="New task"
                  value={newItemDescription}
                  onChange={(e) => setNewItemDescription(e.target.value)}
                  fullWidth
                  onKeyDown={handleKeyPressNewItem}
                  disabled={disabled}
                />
              </ClickAwayListener>
            </Grid>
            <Grid item >
              <Chip
                variant="outlined"
                sx={sxSpeakerChip}
                icon={<PersonAddAlt1Icon fontSize='small' sx={{ paddingLeft: 2, paddingRight: 0.3 }} />}
                disabled
              />
            </Grid>
            <Grid item >
              <DatePicker
                label="Due Date"
                value={dayjs().add(7, 'day')}
                sx={{ width: '150px' }}
                slotProps={{ textField: { size: 'small' } }}
                disabled
              />
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px', width: '48px', paddingRight: '8px' }}>
              <Tooltip title='Add New Item'>
                <IconButton
                  edge="end"
                  aria-label="add new task"
                  onClick={handleAddItem}
                  disabled={disabled}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

        </ListItem>
      </List>
      <Snackbar
        open={showSaveNotif}
        autoHideDuration={3000}
        onClose={() => setShowSaveNotif(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setShowSaveNotif(false)}
          severity={errMsg ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {errMsg ? "Tasks Save Failed, Please Try Again - " + errMsg : "Tasks Saved!"}
        </Alert>
      </Snackbar>
    </Box >
  )
}

const sxSpeakerChip: SxProps = {
  fontSize: 12,
  fontWeight: 'bold',
  cursor: 'pointer',
  '&:hover': {
    color: "primary.main"
  }
}

const sxListitem: SxProps = {
  paddingY: 1,
  '&:hover': {
    backgroundColor: '#f9f9f9',
  },
}
