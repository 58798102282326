import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { PersonalProperty } from '../../client'

interface Props {
  eventId?: string
  personalPropertyList?: PersonalProperty[]
}

export default function PersonalPropertyTable({ eventId, personalPropertyList }: Props) {

  const cellRef = useRef(null)
  const [data, setPersonalPropertyList] = useState<PersonalProperty[]>([])

  useEffect(() => {
    if (personalPropertyList) {
      console.log("personalPropertyList", personalPropertyList)
      setPersonalPropertyList(personalPropertyList)
    }
  }, [personalPropertyList])

  const columns = useMemo<MRT_ColumnDef<PersonalProperty>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        size: 100
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        size: 100
      },
      {
        accessorKey: 'Current Value',
        header: 'Current Value',
        size: 100
      },
      {
        accessorKey: 'Purchase Value',
        header: 'Purchase Value',
        size: 100
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data,
  })

  return (
    <MRT_Table table={table} />
  )
}

const sxCard: SxProps = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '75vh',
}
