import React, { ReactNode, useState } from 'react'
import { useStore } from '../../hooks/useStore'
import { observer } from 'mobx-react-lite'
import './GuideBoxWithArrowStyles.css'

interface Props {
  children: ReactNode
  arrow?: 'left' | 'right' | 'top' | 'bottom'
}

export default observer(GuideBoxWithArrow)
function GuideBoxWithArrow({ children, arrow }: Props) {
  const store = useStore()

  let direction = ''
  if (arrow === 'top')
    direction = 'arrow-top'
  else if (arrow === 'right')
    direction = 'arrow-right'
  else if (arrow === 'bottom')
    direction = 'arrow-bottom'
  else if (arrow === 'left')
    direction = 'arrow-left'

  return (
    <div className={`box ${direction}`}>
      {children}
    </div>
  )
}
