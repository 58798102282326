import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToAnchor() {
  // for bookmarks and anchors
  // must be placed inside screens with anchors, when the page loads, can read in available anchors.
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [hash])

  return null
}

export default ScrollToAnchor
