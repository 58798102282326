import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, SxProps, TextField, Typography, DialogActions } from '@mui/material'
import { logInfo } from '../../log'
import { gColor } from 'styles/Theme'
import { auth } from 'service/api'
import { updateProfile } from 'firebase/auth'
import { updateNameInDB } from 'service'

interface Props {
  open: boolean
  onClose: () => void
}

export default function ProfileEditDialog({ open, onClose }: Props) {
  const fbUser = auth.currentUser
  const [name, setName] = useState(fbUser?.displayName)
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    if (open) {
      logInfo('ProfileEditDialog Open')
    }
  }, [open])

  async function onChangeName() {
    setBusy(true)
    if (fbUser) {
      const nameTrimmed = name?.trim() ?? ''
      await updateProfile(fbUser, { displayName: nameTrimmed })
      updateNameInDB(nameTrimmed)
      logInfo('ProfileEditDialog Change', { name })
    }
    setBusy(false)
    onClose()
  }

  return (
    <Dialog open={open} onClose={() => onClose()}>

      <Box sx={sxBox}>

        <Box sx={sxTitle}>
          <Typography variant='h3' sx={{ color: gColor.green500 }}>Update Your Display Name</Typography>
        </Box>

        <Box sx={{ padding: '2vh' }} />

        <TextField
          label='Display Name'
          aria-label='Display Name'
          onChange={(t) => setName(t.target.value)}
          variant="outlined"
          value={name}
          disabled={busy}
        />

        <Box sx={{ padding: '1vh' }} />

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onChangeName}
            aria-label="Update"
            disabled={busy}
          >
            Update
          </Button>
        </DialogActions>

      </Box>
    </Dialog >
  )
}

const sxBox: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  padding: '3vh',
  minWidth: '400px'
}

const sxTitle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}
