/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WealthboxContactType {
    CLIENT = 'Client',
    PAST_CLIENT = 'Past Client',
    PROSPECT = 'Prospect',
    ALL = 'All',
}
