import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { RetirementPlanEmployer } from '../../client'

interface Props {
  eventId?: string
  retirementPlanEmployerList?: RetirementPlanEmployer[]
}

export default function RetirementPlansEmployerTable({ eventId, retirementPlanEmployerList }: Props) {

  const cellRef = useRef(null)
  const [data, setRetirementPlanEmployerList] = useState<RetirementPlanEmployer[]>([])

  useEffect(() => {
    if (retirementPlanEmployerList) {
      console.log("retirementPlanEmployerList", retirementPlanEmployerList)
      setRetirementPlanEmployerList(retirementPlanEmployerList)
    }
  }, [retirementPlanEmployerList])

  const columns = useMemo<MRT_ColumnDef<RetirementPlanEmployer>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        size: 100
      },
      {
        accessorKey: 'Employer Contributions',
        header: 'Employer Contributions',
        size: 100
      },

      {
        accessorKey: 'Employee Contributions',
        header: 'Employee Contributions',
        size: 100
      },
      {
        accessorKey: 'Account Value',
        header: 'Account Value',
        size: 100
      },
      {
        accessorKey: 'Primary Beneficiary',
        header: 'Primary Beneficiary',
        size: 100
      },
      {
        accessorKey: 'Secondary Beneficiary',
        header: 'Secondary Beneficiary',
        size: 100
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data,
  })

  return (
    <MRT_Table table={table} />
  )
}
