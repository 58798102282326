/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Origin {
    RECALL = 'Recall',
    REDTAIL_RECALL = 'Redtail Recall',
    WEALTHBOX_RECALL = 'Wealthbox Recall',
    WEB_UPLOAD = 'Web Upload',
    WEB_RECORDER = 'Web Recorder',
    IOS_RECORDER = 'ios_recorder',
    ANDROID_RECORDER = 'Android Recorder',
    RING_CENTRAL = 'Ring Central',
}
