import mixpanel from 'mixpanel-browser'
import { config } from '../service/api'

export function logInfo(msg: string, obj?: object) {
  if (config.env !== 'prod')
    obj ? console.log(msg, JSON.stringify(obj, null, 2)) : console.log(msg)
  try {
    mixpanel.track(msg, flatten(obj))
  } catch (e) {
    console.error("logInfo MP failed", msg, e)
    try {
      // obj can be too large
      mixpanel.track("log mixpanel failed", { msg, e })
    } catch (e) {
      //
    }
  }

  // DdLogs.info(msg, obj)
}

export function logWarn(msg: string, obj?: object) {
  obj ? console.warn(msg, JSON.stringify(obj, null, 2)) : console.warn(msg)
  try {
    mixpanel.track("WARN " + msg, flatten(obj))
  } catch (e) {
    console.error("logWarn mixpanel failed", msg, e)
    try {
      mixpanel.track("log mixpanel failed", { msg, e })
    } catch (e) {
      //
    }
  }
}

export function logErr(msg: string, obj?: object) {
  obj ? console.error(msg, JSON.stringify(obj, null, 2)) : console.error(msg)
  try {
    mixpanel.track("ERROR " + msg, flatten(obj))
  } catch (e) {
    console.error("logErr mixpanel failed", msg, e)
    try {
      mixpanel.track("log mixpanel failed", { msg, e })
    } catch (e) {
      //
    }
  }
  //   DdLogs.error(msg, obj)
}

const flatten = (obj: any, prefix = '') => {
  if (!obj)
    return
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object') Object.assign(acc, flatten(obj[k], pre + k))
    // @ts-expect-error skip anytype
    else acc[pre + k] = obj[k]
    return acc
  }, {})

}
