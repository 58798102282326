import React from 'react'
import { ButtonBase, Tooltip } from '@mui/material'
import { config } from 'service/api'
import { zoomAuthUrl } from 'service/integrations/recallApi'
import { Media } from 'service/media'

export default function AddToZoomButton() {
  return (
    config.env == 'local' ? (
      <Tooltip title="Add to Zoom does not work in localhost env.">
        <ButtonBase onClick={() => console.error('Add to Zoom does not work in localhost env.')}>
          <img src={Media.addToZoom} width="140" alt="Add to ZOOM" />
        </ButtonBase>
      </Tooltip>
    ) : (
      <ButtonBase href={zoomAuthUrl()}>
        <img src={Media.addToZoom} width="140" alt="Add to ZOOM" />
      </ButtonBase>
    )
  )
}
