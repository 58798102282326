import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Alert } from '@mui/material'
import { getWebBanner } from '../service/systemApi'

export default function WebBanner() {

  const { data, isLoading } = useQuery({
    queryKey: ['getWebBanner'],
    queryFn: getWebBanner,
    retry: false
  })

  if (isLoading || !data)
    return null

  return (
    <Alert
      severity={data.severity}
      sx={{ mb: 2 }}
    >
      {data.message}
    </Alert >
  )
}
