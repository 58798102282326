import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { RetirementPlanIndividual } from '../../client'

interface Props {
  eventId?: string
  retirementPlanIndividualList?: RetirementPlanIndividual[]
}

export default function RetirementPlansIndividualTable({ eventId, retirementPlanIndividualList }: Props) {

  const cellRef = useRef(null)
  const [data, setRetirementPlanIndividualList] = useState<RetirementPlanIndividual[]>([])

  useEffect(() => {
    if (retirementPlanIndividualList) {
      console.log("retirementPlanIndividualList", retirementPlanIndividualList)
      setRetirementPlanIndividualList(retirementPlanIndividualList)
    }
  }, [retirementPlanIndividualList])

  const columns = useMemo<MRT_ColumnDef<RetirementPlanIndividual>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        size: 100
      },
      {
        accessorKey: 'Contributions',
        header: 'Contributions',
        size: 100
      },
      {
        accessorKey: 'Account Value',
        header: 'Account Value',
        size: 100
      },
      {
        accessorKey: 'Primary Beneficiary',
        header: 'Primary Beneficiary',
        size: 100
      },
      {
        accessorKey: 'Secondary Beneficiary',
        header: 'Secondary Beneficiary',
        size: 100
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data,
  })

  return (
    <MRT_Table table={table} />
  )
}
