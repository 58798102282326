import { types } from 'mobx-state-tree'

const Tour = types.model('TourStore', {
  publicTourStep: types.maybe(types.number)
}).actions(self => ({
  setEventPublicTourStep(step?: number) { // Guide
    self.publicTourStep = step
  },
})).views(self => ({
  getEventPublicTourStep(): number {
    return self.publicTourStep ?? 0
  }
}))

export default Tour