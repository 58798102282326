import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { EditorState } from 'prosemirror-state'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import EmailIcon from '@mui/icons-material/Email'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { EditorContent, useEditor } from '@tiptap/react'
import { Alert, Box, Card, CardContent, IconButton, Snackbar, SxProps, Tooltip, Typography, Divider } from '@mui/material'
import { logInfo } from '../../log'
import ShareDialog from './ShareDetailsDialog'
import { Api } from '../../service/api'
import { EditorMenuBar } from '../editor/EditorMenuBar'
import { EventBase, SummaryBase, ActionItem } from '../../client'
import RedtailEventButton from '../integrations/redtail/RedtailEventButton'
import WealthboxEventButton from 'components/integrations/wealthbox/WealthboxEventButton'
import SalesforceEventButton from 'components/integrations/salesforce/SalesforceEventButton'
import { htmlToPlainText } from 'utils'
import EventTaskList from './EventTaskList'
import SearchAndReplace from '../editor/SearchAndReplace'
import '../editor/Editor.css'

interface Props {
  event: EventBase
  summary?: SummaryBase | null
  disabled: boolean
}

export default observer(EventDetailsDone)
function EventDetailsDone({ event, disabled, summary }: Props) {
  const edittedTimer: any = null // Declare timer outside the function scope
  const [isEdit, setEdit] = useState(false)
  const [edittedHtml, setEdittedHtml] = useState("")
  const [showSaveNotif, setShowSaveNotif] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const [tasksHtml, setTasksHtml] = useState("")

  function setEditMode() {
    setEdit(!isEdit)
    if (editor) {
      // reset history
      const newEditorState = EditorState.create({
        doc: editor.state.doc,
        plugins: editor.state.plugins,
        schema: editor.state.schema
      })
      editor.view.updateState(newEditorState)
      editor.commands.focus("start")
      logInfo("Summary Edit Started", { event_id: event.id })
    }
  }

  async function saveEventSummary() {
    if (event.id && editor) {
      const eventSummaryHtml = editor.getHTML()
      const api = await Api()
      await api.events.editEventSummaryHtml(event.id, eventSummaryHtml)
      setEdittedHtml(eventSummaryHtml)
      setShowSaveNotif(true)
      logInfo("Summary Updated", { event_id: event?.id, updated_summary_len: eventSummaryHtml.length })
    }
  }

  async function closeEdit() {
    if (edittedTimer != null) {
      // clear current timer if exist
      clearTimeout(edittedTimer)
    }
    await saveEventSummary()
    setEdit(false)
    logInfo("Summary Edit Finished", { event_id: event?.id })
  }

  const [copyTooltip, setCopyTooltip] = useState('Copy to clipboard')
  let copyTooltipTimer: any = null

  useEffect(() => {
    editor?.setEditable(isEdit)
  }, [isEdit])

  useEffect(() => {
    if (edittedHtml) {
      editor?.commands.setContent(edittedHtml)
    }
  }, [edittedHtml])

  useEffect(() => {
    if (summary) {
      setEdittedHtml(String(summary?.details_html_str))
    }
  }, [summary])

  function copyText() {
    const html = String(edittedHtml + tasksHtml)
    const plainText = htmlToPlainText(html)
    const blob = new Blob([html], { type: 'text/html' })
    const text = new Blob([plainText], { type: "text/plain" })
    const clipboardItem = new window.ClipboardItem({ 'text/html': blob, "text/plain": text })
    navigator.clipboard.write([clipboardItem])
    setCopyTooltip('Copied!')
    if (copyTooltipTimer) {
      clearTimeout(copyTooltipTimer)
    }
    copyTooltipTimer = setTimeout(async () => {
      setCopyTooltip('Copy to clipboard')
    }, 2500)
    logInfo("Summary Copied", { event_id: event.id, module_name: event.module_name })
  }

  const editor = useEditor({
    extensions: [
      StarterKit,
      CharacterCount,
      SearchAndReplace.configure({
        searchResultClass: "search-result", // class to give to found items. default 'search-result'
        disableRegex: false, // also no need to explain
      }),
    ],
    editable: false,
    content: edittedHtml,
    editorProps: {
      attributes: {
        class: 'custom-editor-class',
        style: 'outline-color: #45CBDC;' // Apply styles directly
      }
    },
  })

  function updateTasksForHtml(tasks: ActionItem[]) {
    let html = "<h3>Tasks</h3>"
    if (tasks.length > 0) {
      html += "<ul>"
      tasks.forEach(item => {
        html += "<li>" + item.description

        html += " ("
        if (item.assignee && item.assignee.length > 0) {
          html += "Assignee: " + item.assignee
        }
        if (item.assignee && item.assignee.length > 0 && item.due_date_datetime)
          html += ", "
        if (item.due_date_datetime) {
          const due = new Date(item.due_date_datetime)
          html += "Due date: " + due.toLocaleDateString()
        }
        html += ")"

        html += "</li>"
      })
      html += "</ul>"
    }
    setTasksHtml(html)
  }

  return (
    <Card sx={sxCard}>
      <Box sx={sxTitle}>

        <Box sx={sxTitleText}>
          <Typography variant='h4'>Meeting Summary</Typography>
          {event.module_ui_title ?
            (<Typography sx={sxTitleModule} style={{ color: 'rgba(211, 211, 211, 1)' }}>
              Processed with <strong>{event.module_ui_title}</strong></Typography>) : (<></>)}
        </Box>

        <Box>
          {isEdit ? (
            <>
              <Tooltip title='Save and Exit Edit'>
                <IconButton aria-label='save and close' onClick={closeEdit}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Box sx={sxMenu}>

              <RedtailEventButton
                event={event}
                eventSummaryHtml={edittedHtml + tasksHtml}
                disabled={disabled}
              />

              <WealthboxEventButton
                event={event}
                eventSummaryHtml={edittedHtml + tasksHtml}
                disabled={disabled}
              />

              <SalesforceEventButton
                event={event}
                eventSummaryHtml={edittedHtml + tasksHtml}
                disabled={disabled}
              />

              <Tooltip title={'Email Meeting Details'}>
                <IconButton
                  aria-label='share summary'
                  onClick={() => setShowShare(true)}
                  disabled={disabled}
                >
                  <EmailIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={copyTooltip}>
                <IconButton aria-label='copy' onClick={copyText}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title='Edit'>
                <IconButton
                  aria-label='edit'
                  onClick={() => setEditMode()}
                  disabled={disabled}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              {event && (
                <ShareDialog
                  open={showShare}
                  onClose={() => setShowShare(false)}
                  eventData={event}
                  eventSummaryHtml={edittedHtml + tasksHtml}
                />
              )}
            </Box>
          )}
          <Snackbar
            open={showSaveNotif}
            autoHideDuration={3000}
            onClose={() => setShowSaveNotif(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              variant="filled"
              onClose={() => setShowSaveNotif(false)}
              severity='success'
              sx={{ width: '100%' }}
            >
              Meeting Saved!
            </Alert>
          </Snackbar>
        </Box>

      </Box>

      {isEdit &&
        <Box sx={menuBarBox}>
          <EditorMenuBar editor={editor} />
        </Box>
      }

      <CardContent sx={sxContent}>
        <div
          className="prose"
          style={{
            border: isEdit ? '1px solid rgba(128, 128, 128, 0.25)' : "none",
            borderRadius: '4px',
          }}
        >
          <EditorContent className='tiptap' editor={editor} />
        </div>
        <Box sx={{ padding: '2px' }} />
        <Divider variant="middle" />
        <Box sx={{ padding: '7px' }} />
        <EventTaskList
          eventId={event.id}
          updateTasksForHtml={updateTasksForHtml}
          disabled={disabled}
        />
        {/* <Typography sx={{ color: 'grey', fontSize: '0.875rem' }}>
          {editor ? editor.storage.characterCount.characters() : 0} characters
        </Typography>
        <Typography sx={{ color: 'grey', fontSize: '0.875rem' }}>
          {editor ? editor.storage.characterCount.words() : 0} words
        </Typography> */}
      </CardContent>
    </Card >
  )
}

const sxCard: SxProps = {}

const sxTitle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const sxMenu: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}

const sxTitleText: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
}

const sxTitleModule: SxProps = {
  textAlign: 'left',
}

const sxContent: SxProps = {
  overflowX: 'hidden',
  height: '72vh',
  paddingTop: "1px"
}

const menuBarBox: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}