import React, { useEffect } from 'react'
import { Card, Typography, SxProps, Button, Box, Tooltip, Skeleton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Media } from 'service/media'
import { gSx } from 'styles/Theme'

import { GetCalendarUser } from 'service/calendarApi'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery } from '@tanstack/react-query'

export default observer(CalendarConnected)
function CalendarConnected() {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['GetCalendarUser'],
    queryFn: async () => await GetCalendarUser(),
  })

  const g = data?.connections?.filter(conn => conn.platform === 'google')[0]
  const ms = data?.connections?.filter(conn => conn.platform === 'microsoft')[0]

  return (
    <Card>

      <Box sx={gSx.RowBetween}>
        <Typography sx={gSx.Row} variant='h5'>Calendar Connected</Typography>
        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={20} />
            : (
              <>
                {
                  ms && ms.connected && (
                    <Box sx={gSx.Row}>
                      <Box
                        component="img"
                        sx={icon}
                        src={Media.microsoft100}
                        alt="Microsoft Logo"
                      />
                      <Typography>{ms.email}</Typography>
                    </Box>
                  )
                }
                {
                  g && g.connected && (
                    <Box sx={gSx.Row}>
                      <Box
                        component="img"
                        sx={icon}
                        src={Media.google100}
                        alt="Google Logo"
                      />
                      <Typography>{g.email}</Typography>
                    </Box>
                  )
                }
              </>
            )

        }

        <Button variant='outlined' onClick={() => navigate(Path.CalendarAuth)}>
          Calendar Settings
        </Button>

      </Box>
    </Card>
  )
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: 2
}
