import React from 'react'
import { Typography, Box, Card, SxProps, Button, Skeleton, ButtonBase, Grid } from '@mui/material'
import 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery } from '@tanstack/react-query'
import { Media } from 'service/media'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'
import { SalesforceApp } from 'client'

export default function PractifiIntegrationLine() {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['getSalesforceSettings'],
    queryFn: getSalesforceSettings,
  })
  const enabled = data?.access_token == undefined || data?.app == SalesforceApp.PRACTIFI

  function SignedInText() {
    if (data?.app != SalesforceApp.PRACTIFI)
      return <Typography color={'lightgray'}>Signed in via {data?.app}</Typography>
    else
      return <Typography>Signed In as {data.username}</Typography>
  }

  function onClick() {
    if (enabled && !isLoading)
      navigate(Path.PractifiSettings)
  }

  return (
    <Card>
      <ButtonBase sx={sxContainer} onClick={onClick}>
        <Grid container spacing={2} sx={sxGrid}>
          <Grid item xs={4} >
            <Box
              component="img"
              sx={sxLogo}
              src={Media.practifi}
            />
          </Grid>

          <Grid item xs={7}>
            {
              isLoading
                ? <Skeleton variant="rectangular" width={'100%'} height={50} />
                : (
                  data && data.username ? (
                    <SignedInText />
                  ) : (
                    <Button>Sign In</Button>
                  )
                )
            }
          </Grid>
          <Grid item xs={1}>
            <ChevronRightIcon color={enabled ? 'inherit' : 'disabled'} />
          </Grid>
        </Grid>

      </ButtonBase>
    </Card>
  )
}

const sxGrid: SxProps = {
  height: 80,
  alignItems: 'center'
}

const sxLogo: SxProps = {
  width: 100,
}

const sxContainer: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}