import React from 'react'
import { Box, Button, Card, Link, SxProps, Typography } from '@mui/material'
import { gColor, gSx } from '../../styles/Theme'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import { useQuery } from '@tanstack/react-query'
import { getOrgInvitesAsRecipient } from 'service/orgsApi'

export default function ReceivedInvitesForHome() {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['getOrgInvitesAsRecipient'],
    queryFn: async () => await getOrgInvitesAsRecipient(),
    refetchOnWindowFocus: false
  })

  if (isLoading || data?.length == 0)
    return null

  return (
    <Card sx={sxCard}>
      <Box sx={gSx.RowBetween}>
        <Typography sx={{ color: 'darkred' }}>You have an invitation to join an Organization </Typography>
        <Button variant='contained' onClick={() => navigate(Path.ReceivedInvite)}>
          See Invitations
        </Button>
      </Box>
    </Card>
  )
}

const sxCard: SxProps = {
  border: '5px solid',
  borderColor: gColor.light
}