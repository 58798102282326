import React, { useState } from 'react'
import { EventBase } from '../../../client'
import { Box, Button, SxProps, Tooltip, Typography } from '@mui/material'
import { Media } from '../../../service/media'
import CheckIcon from '@mui/icons-material/Check'
import WealthboxDialog from './WealthboxDialog'
import { useQuery } from '@tanstack/react-query'
import { getWealthboxProfile } from 'service/integrations/wealthboxApi'
import { gSx } from 'styles/Theme'

interface Props {
  event?: EventBase
  eventSummaryHtml?: string
  disabled: boolean
}
export default function WealthboxEventButton({ event, eventSummaryHtml, disabled }: Props) {
  const isSent = !!(event?.wealthbox_sent || event?.wealthbox_tasks_sent || event?.wealthbox_workflows_sent)
  const [showDialog, setShowDialog] = useState(false)

  const { data } = useQuery({
    queryKey: ['getWealthboxProfile'],
    queryFn: getWealthboxProfile,
  })

  const isSignedIn = data ?? false

  if (!isSignedIn || !event || !eventSummaryHtml)
    return null

  return (
    <Box sx={{ paddingX: 1 }}>
      <Tooltip title={isSent ? "Sent to Wealthbox" : 'Send to Wealthbox'}>
        <Button
          variant='outlined'
          onClick={() => setShowDialog(true)}
          aria-label={'Wealthbox'}
          disabled={disabled}
          startIcon={
            <Box
              component="img"
              sx={icon}
              src={Media.wealthboxIcon}
              alt="Wealthbox"
            />
          }
          endIcon={
            isSent &&
            <CheckIcon color='success' fontSize='small' />

          }
        >

          <Typography sx={{ fontSize: 14 }}>Wealthbox</Typography>

        </Button>
      </Tooltip>

      <WealthboxDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        event={event}
        eventSummaryHtml={eventSummaryHtml}
      />
    </Box>
  )
}

const icon: SxProps = {
  width: 20,
  height: 20,
}