import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { PersonalDetails } from '../../client'

interface Props {
  eventId?: string
  personalDetailsList?: PersonalDetails[]
}

export default function PersonalDetailsTable({ eventId, personalDetailsList }: Props) {

  const cellRef = useRef(null)
  const [data, setPersonalDetails] = useState<PersonalDetails[]>([])

  useEffect(() => {
    if (personalDetailsList) {
      setPersonalDetails(personalDetailsList)
    }
  }, [personalDetailsList])

  const columns = useMemo<MRT_ColumnDef<PersonalDetails>[]>(
    () => [
      {
        accessorKey: 'First Name',
        header: 'First Name',
        size: 100
      },
      {
        accessorKey: 'Last Name',
        header: 'Last Name',
        size: 100
      },
      {
        accessorKey: 'Birthdate',
        header: 'Birthdate',
        size: 100
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data,
  })

  return (

    <MRT_Table table={table} />

  )
}
