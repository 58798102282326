import React from 'react'
import { Card, Box, Typography, Button, SxProps } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getUserConfig } from 'service'
import { Media } from 'service/media'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'

/**
 * Connect zoom with ZoomAuthUrl.
 * OnSuccess, zoom redirects to ../zoom/callback
 * @returns 
 */

interface Props {
  hideIfConnected?: boolean
}
export default function ZoomCardForRecorder({ hideIfConnected }: Props) {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
  })

  const isZoomConnected = data?.recall_zoom_oauth_id && data.recall_zoom_oauth_id.length > 0

  if (isLoading || (hideIfConnected && isZoomConnected))
    return null

  return (
    <Card>
      <Typography variant='h5'>{isZoomConnected ? 'Zoom Connected' : 'Zoom Disconnected'}</Typography>
      {
        isZoomConnected ? (
          <Box sx={sxZoomConnected}>
            <Box
              component="img"
              src={Media.zoomConnect}
              alt="Zoom Connected"
            />
            <Box sx={{ padding: 1 }} />
            <Button variant='outlined' onClick={() => navigate(Path.ZoomSettings)}>
              Zoom Settings
            </Button>
          </Box>
        ) : (
          <>
            <Typography sx={{ color: 'tomato' }}>
              To use Zoom, Finmate needs to connect to your Zoom account.
            </Typography>
            <Box sx={{ padding: 1 }} />
            <Box sx={sxMid}>
              <Button variant='outlined' onClick={() => navigate(Path.ZoomSettings)}>
                Zoom Settings
              </Button>
            </Box>
          </>
        )}
    </Card >
  )
}

const sxZoomConnected: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  height: '30px',
}

const sxMid: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}