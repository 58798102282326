import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Api } from '../../service/api'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { logInfo } from '../../log'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { ClientData, PersonalDetails, PersonalProperty, BankAccount, InvestmentAccount, CertificateOfDeposit, CollegeFund, RetirementPlanEmployer, RetirementPlanIndividual, Pension } from '../../client'
import PersonalDetailsTable from '../finances/PersonalDetailsTable'
import PersonalPropertyTable from '../finances/PersonalPropertyTable'
import BankAccountsTable from '../finances/BankAccountsTable'
import InvestmentAccountsTable from '../finances/InvestmentAccountsTable'
import CertificatesOfDepositTable from 'components/finances/CertificatesOfDepositTable'
import CollageFundTable from 'components/finances/CollageFundTable'
import RetirementPlansEmployerTable from 'components/finances/RetirementPlansEmployerTable'
import RetirementPlansIndividualTable from 'components/finances/RetirementPlansIndividualTable'
import PensionTable from 'components/finances/PensionTable'

interface Props {
  eventId?: string
  isModuleSelected?: boolean
  isPublic?: boolean
}

export default function EventFinances({ eventId, isModuleSelected, isPublic }: Props) {

  const cellRef = useRef(null)
  const [personalDetails, setPersonalDetails] = useState<PersonalDetails[]>([])
  // assets
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([])
  const [taxableInvestmentAccounts, setTaxableInvestmentAccounts] = useState<InvestmentAccount[]>([])
  const [personalProperty, setPersonalProperty] = useState<PersonalProperty[]>([])
  const [certificateOfDeposit, setCertificateOfDeposit] = useState<CertificateOfDeposit[]>([])
  const [collegeFunds, setCollegeFunds] = useState<CollegeFund[]>([])
  const [plan401k, setPlan401k] = useState<RetirementPlanEmployer[]>([])
  const [planRoth401k, setPlanRoth401k] = useState<RetirementPlanEmployer[]>([])
  const [plan403b, setPlan403b] = useState<RetirementPlanEmployer[]>([])
  const [plan457b, setPlan457b] = useState<RetirementPlanEmployer[]>([])
  const [planSimpleIRA, setPlanSimpleIRA] = useState<RetirementPlanEmployer[]>([])
  const [planSEPIRA, setPlanSEPIRA] = useState<RetirementPlanEmployer[]>([])
  const [planIRA, setPlanIRA] = useState<RetirementPlanIndividual[]>([])
  const [planRothIRA, setPlanRothIRA] = useState<RetirementPlanIndividual[]>([])
  const [planPensions, setPlanPensions] = useState<Pension[]>([])

  const { data: rawFinances, isLoading } = useQuery({
    queryKey: ['getEventFinances', eventId],
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
  })

  async function runQuery() {
    if (eventId) {
      const api = await Api()
      const res = await api.events.getEventFinances(eventId)
      if (res['Client Details'] && res['Client Details']['Personal Details']) {
        setPersonalDetails(res['Client Details']['Personal Details'])
      }
      if (res['Assets']) {
        const assets = res['Assets']
        if (assets['Personal Property'])
          setPersonalProperty(assets['Personal Property'])
        if (assets["Certificates of Deposit"])
          setCertificateOfDeposit(assets["Certificates of Deposit"])
        if (assets["Taxable Investment Accounts"])
          setTaxableInvestmentAccounts(assets["Taxable Investment Accounts"])
        if (assets['Bank Accounts'])
          setBankAccounts(assets['Bank Accounts'])
        if (assets['College Funds'])
          setCollegeFunds(assets['College Funds'])
      }
      if (res['Retirement Plans']) {
        const plans = res['Retirement Plans']
        if (plans['401(k)'])
          setPlan401k(plans['401(k)'])
        if (plans['Roth 401(k)'])
          setPlanRoth401k(plans['Roth 401(k)'])
        if (plans['403(b)'])
          setPlan403b(plans['403(b)'])
        if (plans['457(b)'])
          setPlan457b(plans['457(b)'])
        if (plans['Simple IRA'])
          setPlanSimpleIRA(plans['Simple IRA'])
        if (plans['SEP IRA'])
          setPlanSEPIRA(plans['SEP IRA'])
        if (plans['IRA'])
          setPlanIRA(plans['IRA'])
        if (plans['Roth IRA'])
          setPlanRothIRA(plans['Roth IRA'])
        if (plans['Pensions'])
          setPlanPensions(plans['Pensions'])
      }
      return []
    }
  }

  if (isLoading) {
    return (
      <Card sx={sxCard}>
        <CardHeader
          title="Client's Finances"
          sx={{ paddingTop: 1 }}
        />
        <Typography>Loading</Typography>
      </Card>
    )
  }

  return (
    <Card sx={sxCard}>
      <CardHeader
        title="Client's Finances"
        sx={{ paddingTop: 1 }}
      />
      <CardContent>
        {/* <MaterialReactTable table={table} />; */}
        <Card>
          <CardHeader
            title="Client Details"
            sx={{ paddingTop: 1 }}
          />
          <PersonalDetailsTable eventId={eventId} personalDetailsList={personalDetails} />
        </Card>
        <Card>
          <CardHeader
            title="Assets"
            sx={{ paddingTop: 1 }}
          />
          <Card>
            <CardHeader
              title="Bank Accounts"
              sx={{ paddingTop: 1 }}
            />
            <BankAccountsTable eventId={eventId} bankAccountList={bankAccounts} />
          </Card>
          <Card>
            <CardHeader
              title="Certificates Of Deposit"
              sx={{ paddingTop: 1 }}
            />
            <CertificatesOfDepositTable eventId={eventId} certificateOfDepositList={certificateOfDeposit} />
          </Card>
          <Card>
            <CardHeader
              title="Taxable Investment Accounts"
              sx={{ paddingTop: 1 }}
            />
            <InvestmentAccountsTable eventId={eventId} investmentAccountList={taxableInvestmentAccounts} />
          </Card>
          <Card>
            <CardHeader
              title="College Funds"
              sx={{ paddingTop: 1 }}
            />
            <CollageFundTable eventId={eventId} collegeFundList={collegeFunds} />
          </Card>

          <Card>
            <CardHeader
              title="Personal Properties"
              sx={{ paddingTop: 1 }}
            />
            <PersonalPropertyTable eventId={eventId} personalPropertyList={personalProperty} />
          </Card>
        </Card>
        <Card>
          <CardHeader
            title="Retirement Plans"
            sx={{ paddingTop: 1 }}
          />
          <Card>
            <CardHeader
              title="401(k)"
              sx={{ paddingTop: 1 }}
            />
            <RetirementPlansEmployerTable eventId={eventId} retirementPlanEmployerList={plan401k} />
          </Card>
          <Card>
            <CardHeader
              title="Roth 401(k)"
              sx={{ paddingTop: 1 }}
            />
            <RetirementPlansEmployerTable eventId={eventId} retirementPlanEmployerList={planRoth401k} />
          </Card>
          <Card>
            <CardHeader
              title="403(b)"
              sx={{ paddingTop: 1 }}
            />
            <RetirementPlansEmployerTable eventId={eventId} retirementPlanEmployerList={plan403b} />
          </Card>
          <Card>
            <CardHeader
              title="457(b)"
              sx={{ paddingTop: 1 }}
            />
            <RetirementPlansEmployerTable eventId={eventId} retirementPlanEmployerList={plan457b} />
          </Card>
          <Card>
            <CardHeader
              title="Simple IRA"
              sx={{ paddingTop: 1 }}
            />
            <RetirementPlansEmployerTable eventId={eventId} retirementPlanEmployerList={planSimpleIRA} />
          </Card>
          <Card>
            <CardHeader
              title="SEP IRA"
              sx={{ paddingTop: 1 }}
            />
            <RetirementPlansEmployerTable eventId={eventId} retirementPlanEmployerList={planSEPIRA} />
          </Card>
          <Card>
            <CardHeader
              title="IRA"
              sx={{ paddingTop: 1 }}
            />
            <RetirementPlansIndividualTable eventId={eventId} retirementPlanIndividualList={planIRA} />
          </Card>
          <Card>
            <CardHeader
              title="Roth IRA"
              sx={{ paddingTop: 1 }}
            />
            <RetirementPlansIndividualTable eventId={eventId} retirementPlanIndividualList={planRothIRA} />
          </Card>
          <Card>
            <CardHeader
              title="Pensions"
              sx={{ paddingTop: 1 }}
            />
            <PensionTable eventId={eventId} pensionList={planPensions} />
          </Card>
        </Card>

      </CardContent >
    </Card >
  )
}

const sxCard: SxProps = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '75vh',
}
