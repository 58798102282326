import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import { gColor } from 'styles/Theme'

interface Props {
  children: ReactNode
  width: string
  height: string
  overLayMessage: string
  paddingTop: string
}
export default function OverlayMessageOnComponent({ children, width, height, paddingTop, overLayMessage }: Props) {

  const container = {
    position: 'relative',
    width: width,
    height: height,
    margin: "0 auto",
    'padding-top': paddingTop,
  }
  return (
    <Box sx={container}>
      <Box sx={styles.bottomComponent}>
        {children}
      </Box>

      <Box sx={styles.overlayComponent}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: gColor.green500 }}>
          {overLayMessage}
        </Typography>
      </Box>
    </Box>
  )
}
const styles = {
  bottomComponent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.2,
    filter: 'blur(1.5px)',
    zIndex: 1, // Lower layer
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayComponent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2, // Higher layer
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
  },
}
