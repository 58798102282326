import React from 'react'
import { Typography, Box, Card, SxProps, Button, Skeleton, ButtonBase, Grid } from '@mui/material'
import 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery } from '@tanstack/react-query'
import { Media } from 'service/media'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { getWealthboxProfile } from 'service/integrations/wealthboxApi'

export default function WealthboxIntegrationLine() {
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({
    queryKey: ['getWealthboxProfile'],
    queryFn: getWealthboxProfile,
  })

  return (
    <Card>
      <ButtonBase sx={sxContainer} onClick={() => navigate(Path.WealthboxSettings)}>
        <Grid container spacing={2} sx={sxGrid}>
          <Grid item xs={4}>
            <Box
              component="img"
              sx={sxLogo}
              src={Media.wealthbox}
            />
          </Grid>

          <Grid item xs={7}>
            {
              isLoading
                ? <Skeleton variant="rectangular" width={'100%'} height={50} />
                : (
                  data && data.email ? (
                    <Typography>Signed In as {data.name}, {data.email}</Typography>
                  ) : (
                    <Button>Sign In</Button>
                  )
                )
            }
          </Grid>
          <Grid item xs={1} >
            <ChevronRightIcon />
          </Grid>
        </Grid>
      </ButtonBase>
    </Card>
  )
}

const sxGrid: SxProps = {
  height: 80,
  alignItems: 'center'
}

const sxLogo: SxProps = {
  width: 150,
}

const sxContainer: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}