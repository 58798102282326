import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Box, Typography, Skeleton } from '@mui/material'
import Discount from './Discount'
import { getCustomer } from "../../service/payApi"
import { gSx } from 'styles/Theme'
import { FinmateSubscription, OrgBase } from 'client'
import Grid from '@mui/material/Unstable_Grid2'

interface Props {
  sub?: FinmateSubscription
  org?: OrgBase
  isLoading: boolean
}

export default function DisplayPlan({ sub, org, isLoading: isLoadingParent }: Props) {

  const { data: customer, isLoading: isLoadingCust } = useQuery({
    queryKey: ['getCustomer'],
    queryFn: getCustomer,
  })
  const isLoading = isLoadingParent || isLoadingCust || !sub

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={5}>

        <Typography variant='h6'>Subscription Plan</Typography>
        <Typography>{`Billed Monthly`}</Typography>

      </Grid>

      <Grid xs={6} md={5}>
        <Typography>Standard Plan </Typography>
        <Typography>Starter Plan  </Typography>
        <Typography>Lite Plan  </Typography>
      </Grid>

      <Grid xs={6} md={2}>
        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={20} />
            : <Typography>{`$${(sub.standard?.price?.unit_amount ?? 0) / 100} / seat`}</Typography>
        }

        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={20} />
            : <Typography>{`$${(sub.starter?.price?.unit_amount ?? 0) / 100} / seat`} </Typography>
        }

        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={20} />
            : <Typography>{`$${(sub.lite?.price?.unit_amount ?? 0) / 100} / seat`} </Typography>
        }

      </Grid>

      <Grid xs={12} md={5}>
      </Grid>

      <Grid xs={12} md={7}>
        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={40} />
            : (
              <Box sx={{ display: 'flex' }}>
                <Discount discount={customer?.discount} org={org} />
              </Box>
            )
        }
      </Grid>

    </Grid >
  )
}