import React from 'react'
import { Paper, SxProps } from '@mui/material'

interface CustomContainerProps {
  children: React.ReactNode;
}

const ContainerMedium: React.FC<CustomContainerProps> = ({ children }) => {
  return (
    <Paper sx={sxContainerMedium}>
      {children}
    </Paper>
  )
}

export default ContainerMedium

export const sxContainerMedium: SxProps = {
  margin: 'auto',
  width: '100%',
  maxWidth: '700px',
  padding: '15px',
}
