import React, { Dispatch, MutableRefObject, ReactNode, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { Typography, Box, Card, Skeleton, Dialog, Button, Link } from '@mui/material'
import 'firebase/auth'
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row
} from 'material-react-table'
import { useQuery } from '@tanstack/react-query'
import { EventBase, SalesforceApp, SalesforceFlow } from 'client'
import { getSalesforceFlows } from 'service/integrations/salesforceApi'
import CheckIcon from '@mui/icons-material/Check'
import { gSx } from 'styles/Theme'

interface Props {
  event: EventBase
  app: SalesforceApp
  setActiveStep: Dispatch<SetStateAction<number>>
  setFlow: (flow: SalesforceFlow) => void
}

export default function SalesforceFlowSelect({ event, app, setActiveStep, setFlow }: Props) {

  const { data, isLoading } = useQuery({
    queryKey: ['getSalesforceFlows'], queryFn: getSalesforceFlows
  })

  const columns = useMemo<MRT_ColumnDef<SalesforceFlow>[]>(
    () => [
      {
        accessorKey: 'Label', // access nested data with dot notation
        header: 'Label',
        size: 300,
        Cell: CellName
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    enableFullScreenToggle: false,
    positionGlobalFilter: 'left',
    enableHiding: false,
    enableDensityToggle: false,
    initialState: {
      density: 'compact',
      columnOrder: [
        ...columns.map((col) => col.accessorKey as string),
        'mrt-row-select', // ID of the selection column
      ],
      pagination: {
        pageIndex: 0,
        pageSize: 5, // Set initial rows per page to 5
      },
    },
    localization: {
      select: 'Start',
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        setFlow(row.original)
        setActiveStep((s) => s + 1)
      },
      sx: { cursor: 'pointer' },
    }),
  })

  return (
    <>
      <Typography>{`Select a flow. Only Process type "Autolaunched flows" are available.`}</Typography>
      <Box sx={{ padding: 1 }} />
      {
        isLoading || !data
          ? <Skeleton variant="rectangular" width={'100%'} height={100} />
          : data.length == 0
            ?
            <>
              <Typography variant='h6'>No Flows found</Typography>
              <Link
                rel='noopener'
                target='_blank'
                href={'https://www.finmate.ai/help/salesforce'}
              >
                Get Started with our Integration Guide here
              </Link>
            </>
            : <MaterialReactTable
              table={table}
            />
      }

      <Box sx={{ padding: 1 }} />
      <Box sx={gSx.RowBetween}>
        <Box />
        <Box sx={gSx.Row}>
          {event?.salesforce_flows_sent &&
            <>
              <CheckIcon color='success' fontSize='small' sx={{ marginLeft: '5px' }} />
              <Typography color='success.main'>sent</Typography>
              <Box sx={{ padding: 2 }} />
            </>
          }
          <Button
            variant={event?.salesforce_flows_sent ? 'outlined' : 'contained'}
            disabled={true}
            aria-label='Select a flow'
          >
            Start Flow
          </Button>
        </Box>
      </Box>
    </>
  )
}

interface CellProps {
  renderedCellValue: ReactNode
  row: MRT_Row<SalesforceFlow>
}

function CellName({ row }: CellProps) {
  const labelMax = 40
  const label = row.original.label ?? ''
  const labelShort = label.length > labelMax ? label.slice(0, labelMax) + "..." : label

  const descMax = 80
  const desc = row.original.description ?? ''
  const descShort = desc.length > descMax ? desc.slice(0, descMax) + "..." : desc

  return (
    <>
      <Typography>{labelShort}</Typography>
      <Typography variant='caption'>{descShort}</Typography>
    </>
  )
}
