/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProductName {
    STANDARD = 'Standard',
    STARTER = 'Starter',
    LITE = 'Lite',
    FREE_TRIAL = 'Free Trial',
    EXPIRED = 'Expired',
}
