import React, { useEffect, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { isImageURLReal } from '../../utils'
import { Box } from '@mui/material'

interface Props {
  photoUrl?: string | null
  width: number
}

export default function ProfilePhoto({ photoUrl, width }: Props) {
  const [isReal, setReal] = useState(false)

  useEffect(() => {
    async function checkImage() {
      setReal(photoUrl ? await isImageURLReal(photoUrl) : false)
    }
    checkImage()
  }, [photoUrl])

  const avatar = {
    width: width,
    height: width,
    borderRadius: '20px'
  }

  return (
    isReal && photoUrl ? (
      <img
        style={avatar}
        src={photoUrl}
        alt={'alt'}
      />
    ) : (
      <AccountCircleIcon sx={{ color: '#e6e6e6', fontSize: width }} />
    )
  )
}
