import React, { useEffect, useState } from 'react'
import { Box, ButtonBase, SxProps, Typography, useMediaQuery } from '@mui/material'
import { Media } from 'service/media'
import { gColor, gSx } from 'styles/Theme'
import './styles.css'
import { isMobile, isAndroid, isIOS } from 'react-device-detect'

export default function MobileWarnAndSuggest() {
  const isSmall = useMediaQuery('(max-width:600px)')
  const sxWarnings: SxProps = {
    padding: '5px',
    color: 'DarkRed',
    fontSize: isSmall ? 12 : 14
  }

  const sxBlackNotes: SxProps = {
    padding: '5px',
    fontSize: isSmall ? 12 : 14
  }

  return (
    <>
      {
        isMobile &&
        <>
          <Typography sx={sxWarnings}>
            Web Recorder cannot prevent your mobile device from going to sleep or locking. Please manually adjust your settings to keep your phone or tablet awake.
          </Typography>

          <Typography sx={sxWarnings}>
            Meetings may exceed your browser memory capacity. we recommend you use the mobile app.
          </Typography>
        </>
      }

      {
        isAndroid &&
        <Box sx={gSx.Row}>

          <Typography sx={sxBlackNotes}>
            {`For a better experience, try the Finmate AI Android App!`}
          </Typography>
          <ButtonBase
            sx={sxCardButton}
            href='https://play.google.com/store/apps/details?id=com.finmate.ai'
            target="_blank"
            rel="noopener"
          >
            <Box
              component="img"
              sx={{ width: 135, height: 43 }}
              src={Media.googlePlayStore}
            />
          </ButtonBase>
        </Box>

      }

      {
        isIOS &&
        <Box sx={gSx.Row}>
          <Typography sx={sxBlackNotes}>
            {`For a better experience, try the Finmate AI iOS App!`}
          </Typography>
          <ButtonBase
            sx={sxCardButton}
            href='https://apps.apple.com/us/app/finmate-ai/id6469643088'
            target="_blank"
            rel="noopener"
          >
            <Box
              component="img"
              sx={{ width: 135, height: 43 }}
              src={Media.appleAppStore}
            />
          </ButtonBase>
        </Box>
      }
    </>
  )
}

const sxCardButton: SxProps = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
}