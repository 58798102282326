import React, { useState } from 'react'
import { Box, Link, SxProps, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import MicrosoftSignIn from '../auth/MicrosoftSignInButton'
import { recallGoogleAuth, recallMicrosoftAuth } from '../../service/authApi'
import { observer } from 'mobx-react-lite'
import { GetCalendarUser, UpdateRecordingPrefs, isConnected, recordExternalMeetings } from '../../service/calendarApi'
import { logErr } from '../../log'
import GoogleCalendarSignInButton from '../auth/GoogleCalendarSignInButton'
import { isFirebaseUserMicrosoft } from '../../utils'

export default observer(SignInCalendarScreen)
function SignInCalendarScreen() {
  const navigate = useNavigate()

  const isMicrosoft = isFirebaseUserMicrosoft()
  // const isMicrosoft = fbUser?.providerData[0].providerId === 'microsoft.com'

  const [processing, setProcessing] = useState(false)

  async function sso() {
    setProcessing(true)
    try {

      isMicrosoft
        ? await recallMicrosoftAuth()
        : await recallGoogleAuth()

      await checkIfSignedIn()
    } catch (err) {
      logErr('sso', { err })
    }
    setProcessing(false)
  }

  async function checkIfSignedIn() {
    const intervalId = setInterval(async () => {

      const calUser = await GetCalendarUser()
      if (calUser && isConnected(calUser)) {
        clearInterval(intervalId)

        // set default recording prefs, when 1st connecting
        const prefs = recordExternalMeetings(true)
        await UpdateRecordingPrefs(prefs)
        navigate(Path.RecorderSettings)
      }
    }, 2000)
  }

  return (
    <Box sx={sxContainer}>
      {
        isMicrosoft ? (
          <MicrosoftSignIn
            text={'Connect Outlook Calendar'}
            disable={processing}
            onClick={sso}
          />
        ) : (
          <GoogleCalendarSignInButton
            text={'Connect to Google Calendar'}
            disable={processing}
            onClick={sso}
          />
        )
      }

      <Box sx={{ padding: '10px' }} />

      {!isMicrosoft && (
        <Typography variant='caption'>
          {`FinMate AI's use and transfer to any other app of information received from Google APIs will adhere to `}
          <Link href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</Link>
          {`, including the Limited Use requirements.`}
        </Typography>
      )
      }
    </Box>
  )
}

const sxContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
}