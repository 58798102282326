import React from "react"
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { FinmateSubscription, OrgUser, StripeCustomer } from "client"
import { getOrgUserPrice } from "service/payApi"

interface Props {
  target: OrgUser
  sub?: FinmateSubscription
  customer?: StripeCustomer
}
const AddPayingSeatInfo = ({ target, sub, customer }: Props) => {

  const nameEmail = target?.name ? `${target?.name}, ${target?.email}` : `${target?.email}`

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
      <Typography variant='h6'>Billing Change</Typography>
      <Typography variant="caption">
        {target?.org_user_plan} plan will be added to {nameEmail}
      </Typography>

      <TableContainer >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow >
              <TableCell component="th" scope="row" sx={{ color: 'darkgreen' }}>
                {target?.org_user_plan} Plan
              </TableCell>
              <TableCell align={'right'} sx={{ color: 'darkgreen', minWidth: 150 }}>
                +${getOrgUserPrice(sub, target)} / month
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

      {customer?.discount &&
        <Typography variant='caption'>Pre-discount price is shown. Your discount will be applied upon saving the change.</Typography>
      }
    </Card>
  )
}

export default AddPayingSeatInfo
