import { types, Instance } from 'mobx-state-tree'
import Security from './securityStore'
import User from './userStore'
import System from './systemStore'

export const RootStore = types.model('RootStore', {
  User: User,
  Security: Security,
  System: System,
})

export type RootType = Instance<typeof RootStore>

export const InitStore = {
  User: {},
  System: {},
  Security: {},
}
