import React, { useState } from 'react'
import { Card, Tab, SxProps } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import MyEventsList from './MyEventsList'
import SharedEventsList from './SharedEventsList'

export default function EventsList() {
  const [tabValue, setTabValue] = useState("1")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  return (
    <Card>
      <TabContext value={tabValue}>
        <TabList sx={sxTabList} onChange={handleChange}>
          <Tab
            sx={sxTabList}
            label='My Meetings'
            value='1'
          />
          <Tab
            sx={sxTabList}
            label='Shared Meetings'
            value='2'
          />

        </TabList>
        <TabPanel sx={sxTabPanel} value='1'>
          <MyEventsList />
        </TabPanel>
        <TabPanel sx={sxTabPanel} value='2'>
          <SharedEventsList />
        </TabPanel>
      </TabContext>

    </Card >
  )
}

const sxTabList: SxProps = {
  minHeight: '30px',
}

const sxTabPanel: SxProps = {
  padding: '0',
}