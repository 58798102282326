import React, { ReactNode } from 'react'
import { CircularProgress, Box } from '@mui/material'

interface Props {
  children: ReactNode;
  loading: boolean;
}

const CenteredCircularProgress = ({ children, loading }: Props) => {
  return (
    <Box position="relative" display="inline-flex">
      {children}
      {loading && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
        >
          <CircularProgress size={50} color="primary" />
        </Box>
      )}
    </Box>
  )
}

export default CenteredCircularProgress
