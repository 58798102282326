import React, { useState } from 'react'
import { Typography, Box, Link, Accordion, AccordionSummary, AccordionDetails, Tooltip } from '@mui/material'
import 'firebase/auth'
import { ClientDetail, EventBase, SalesforceApp, SalesforceFlow } from 'client'
import SalesforceFlowSelect from './SalesforceFlowSelect'
import { MasterTask } from '../SelectTasks'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { gColor, gSx } from 'styles/Theme'
import SalesforceFlowMap from './SalesforceFlowMap'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface Props {
  event: EventBase
  app: SalesforceApp
  contact?: ClientDetail
  tasks: MasterTask[]
}

export default function SalesforceFlowsCard({ event, app, contact, tasks }: Props) {
  const isSent = !!event.salesforce_flows_sent
  const [expanded, setExpanded] = useState(false)
  const [flow, setFlow] = useState<SalesforceFlow>()
  const [activeStep, setActiveStep] = React.useState(0)

  return (
    <Accordion
      sx={gSx.Crm.Accordian}
      expanded={expanded}
      onChange={() => setExpanded(state => !state)}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={gSx.Crm.AccordianSummary}
      >
        <Box sx={gSx.Row}>
          {isSent
            ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
            : <AccountTreeOutlinedIcon sx={gSx.Crm.LeftIcon} />
          }

          <Typography variant='h5'>Flows</Typography>

          <Tooltip title='Integration Guide - Salesforce Flows'>
            <Link
              sx={{ paddingX: 1 }}
              rel='noopener'
              target='_blank'
              href={'https://www.finmate.ai/help/salesforce'}
            >
              <InfoOutlinedIcon fontSize='small' />
            </Link>
          </Tooltip>
        </Box>
        {isSent &&
          <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
        }
      </AccordionSummary>

      <AccordionDetails>

        {activeStep == 0 &&
          <SalesforceFlowSelect
            event={event}
            app={app}
            setActiveStep={setActiveStep}
            setFlow={setFlow}
          />
        }
        {activeStep == 1 &&
          <SalesforceFlowMap
            event={event}
            app={app}
            setActiveStep={setActiveStep}
            flow={flow}
            contact={contact}
            tasks={tasks}
            setExpanded={setExpanded}
          />
        }
      </AccordionDetails>
    </Accordion>
  )
}
