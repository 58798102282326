import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import CheckIcon from '@mui/icons-material/Check'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Link, Snackbar, SxProps, Typography } from '@mui/material'
import { logErr, logInfo } from '../../../log'
import { ActionItem, CRM, EventBase } from '../../../client'
import { Media } from '../../../service/media'
import { gColor, gSx } from '../../../styles/Theme'
import { Path } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { sendRedtailTasks } from 'service/integrations/redtailApi'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import SelectTasks, { MasterTask } from '../SelectTasks'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

interface Props {
  event?: EventBase
}

export default function RedTailTasksCard({ event }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const eventId = event?.id ?? ''
  const hasClient = !!event?.client?.id
  const isSent = !!event?.redtail_tasks_sent
  const { isBlocked } = useIsBlockedByPlan(true)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [expanded, setExpanded] = useState(!isSent)
  const [masterTasks, setMasterTasks] = useState<MasterTask[]>([])

  const isAnySelected = masterTasks.some(m => m.isSelected)
  const disableSend = isBlocked || !hasClient || busy || !isAnySelected

  async function onSend() {
    setBusy(true)
    setErr(undefined)

    const tasks: ActionItem[] = masterTasks.filter(m => m.isSelected)
    const res = await sendRedtailTasks(event?.client, event?.id, tasks)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      await queryClient.invalidateQueries(['getEvent', eventId])
      setExpanded(false)
    }
    setBusy(false)
  }

  return (
    <>
      <Accordion
        sx={gSx.Crm.Accordian}
        expanded={expanded}
        onChange={() => setExpanded(state => !state)}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={gSx.Crm.AccordianSummary}
        >
          <Box sx={gSx.Row}>
            {isSent
              ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
              : <TaskOutlinedIcon sx={gSx.Crm.LeftIcon} />
            }
            <Typography variant='h5'>Tasks</Typography>
          </Box>
          {isSent &&
            <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
          }
        </AccordionSummary>

        <AccordionDetails>
          <Typography>All selected items will be saved as Redtail Activities with Task type.</Typography>

          <Typography>
            To Assign your teammates, they must be a member of a Redtail Team. (
            <Link component="button" onClick={() => navigate(`${Path.RedtailSettings}#teammates`)}>
              learn more
            </Link>
            )
          </Typography>

          <SelectTasks
            eventId={eventId}
            masterTasks={masterTasks}
            setMasterTasks={setMasterTasks}
            disabled={busy}
            crm={CRM.REDTAIL}
          />
          {err && <Typography color={'tomato'}>{err}</Typography>}

          <Box sx={gSx.RowBetween}>
            <Box />
            <Box sx={gSx.Row}>
              <LoadingButton
                variant={'contained'}
                onClick={onSend}
                disabled={disableSend}
                loading={busy}
                sx={{ marginLeft: 3 }}
              >
                Send Tasks
              </LoadingButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Redtail Tasks Sent
        </Alert>
      </Snackbar>
    </>
  )
}
