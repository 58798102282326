import React, { useState } from 'react'
import {
  Box,
  SxProps,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
  Dialog,
} from '@mui/material'
import { EmailBase, EventBase } from '../../client'
import { gSx } from '../../styles/Theme'
import { Api } from '../../service/api'
import { logErr, logInfo } from '../../log'
import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import { isEmailValid } from '../../utils'

interface Props {
  open: boolean
  onClose: () => void
  eventData: EventBase
  eventSummaryHtml?: string
}

export default function ShareDetailsDialog({ open, onClose, eventData, eventSummaryHtml }: Props) {
  const [processing, setProcessing] = useState(false)
  const [status, setStatus] = useState('')
  const [statusColor, setStatusColor] = useState('black')
  const [email, setEmail] = useState('')

  async function send() {
    setStatus('')
    setStatusColor('black')

    if (!isEmailValid(email)) {
      setStatus('Invalid Email')
      setStatusColor('error.main')
      return
    }

    setProcessing(true)

    const data: EmailBase = {
      to_emails: [email],
      client_fullname: `${eventData.client?.first_name ?? ''} ${eventData.client?.last_name ?? ''}`,
      client_email: eventData.client?.email ?? '',
      body_html: `<p>${eventSummaryHtml}</p>`,
      event_title: eventData.title ?? '',
      event_date: new Date(eventData.start_time ?? '').toLocaleString(),
    }

    try {
      const api = await Api()
      await api.communications.emailMeetingDetails(data)
      setProcessing(false)
      setStatus(`Email sent to ${email}`)
      setStatusColor('success.main')
      setEmail('')
      logInfo("Summary Emailed", { event_id: eventData?.id, bot_id: eventData?.id, module_name: eventData?.module_name })
    } catch (e) {
      setProcessing(false)
      logErr('sendEmail share event detail', { e })
      setStatus('Something went wrong. Try again later.')
      setStatusColor('error.main')
    }
  }

  const sxStatus: SxProps = {
    color: statusColor,
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton sx={sxClose} aria-label='close' onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <Box sx={sxBox}>
        <Typography variant='h3'>Email the Meeting Details</Typography>

        <Box sx={{ padding: '1vh' }}></Box>

        <Box sx={gSx.Row}>
          <TextField
            id='outlined-basic'
            aria-label='Share Summary via Email'
            label='Share Summary via Email'
            variant='outlined'
            sx={sxEmail}
            disabled={processing}
            value={email}
            onChange={(t) => setEmail(t.target.value)}
          />

          <IconButton aria-label='Send Summary via Email' onClick={() => send()}>
            <SendIcon sx={sxSendIcon} />
          </IconButton>
        </Box>

        <Box sx={{ padding: '1vh' }}></Box>
        {processing && (
          <Box sx={gSx.Row}>
            <Typography>Sending</Typography>
            <Box sx={{ paddingX: '1vh' }}></Box>
            <CircularProgress />
          </Box>
        )}

        <Box sx={gSx.Row}>
          <Typography sx={sxStatus}>{status}</Typography>
          <Box sx={{ paddingX: '1vh' }}></Box>
        </Box>
      </Box>
    </Dialog>
  )
}
const sxBox: SxProps = {
  paddingX: '3vh',
  paddingBottom: '3vh',
}

const sxClose: SxProps = {
  alignSelf: 'flex-end',
}

const sxSendIcon: SxProps = {
  paddingLeft: '1vh',
  color: 'primary.main',
}

const sxEmail: SxProps = {
  width: '400px',
}
