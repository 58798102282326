import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, LinearProgress, SxProps, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Media } from 'service/media'
import { SalesforceApp, SalesforceBase } from 'client'
import { saveSalesforceAuthLocalhost } from 'service/integrations/salesforceApi'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import SalesforceFinmateConnectIcons from './SalesforceFinmateConnectIcons'

export default function AuthSalesforceCallbackLocalhost() {
  const [app, setApp] = useState(SalesforceApp.SALESFORCE)
  const storageItem = localStorage.getItem('SalesforceApp')

  useEffect(() => {
    if (storageItem != null)
      setApp(storageItem as SalesforceApp)
  }, [storageItem])

  useEffect(() => {
    // some reason the salesforce uses # instead of ? in the url.
    if (window.location.href.includes("callback/localhost#")) {
      const newUrl = window.location.href.replace("callback/localhost#", "callback/localhost?")
      window.location.href = newUrl
    }
  }, [window.location.href])

  const url = window.location.href
  const urlParams = new URLSearchParams(new URL(url).search)
  const input: SalesforceBase = {
    access_token: urlParams.get("access_token") ?? undefined,
    refresh_token: urlParams.get("refresh_token") ?? undefined,
    issued_at: urlParams.get('issued_at') ?? undefined,
    scope: urlParams.get('scope') ?? undefined,
    instance_url: urlParams.get('instance_url') ?? undefined,
    signature: urlParams.get('signature') ?? undefined,
  }

  const queryClient = useQueryClient()
  const [err, setErr] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['saveSalesforceAuthLocalhost'],
    queryFn: async () => await saveSalesforceAuthLocalhost(input),
  })

  useEffect(() => {
    if (data instanceof Error) {
      setErr(data.message)
    } else if (data == true) {
      queryClient.invalidateQueries({ queryKey: ['getSalesforceSettings'] })
    }
  }, [data])

  if (isLoading) {
    return (
      <Box sx={sxWrap}>
        <SalesforceFinmateConnectIcons />
        <Typography variant='h3'>Finalizing {app} Connections</Typography>
        <Typography variant='h3'>Localhost</Typography>

        <Box sx={{ width: 200, paddingY: '2vh' }}>
          <LinearProgress />
        </Box>
      </Box>
    )
  }

  if (data == true)
    return (
      <Box sx={sxWrap}>
        <SalesforceFinmateConnectIcons />
        <Typography variant='h3'>You are signed into {app}</Typography>
        <Typography variant='h3'>Localhost</Typography>
        <Typography variant='h4'>You may close this window</Typography>
      </Box >
    )

  return (
    <Box sx={sxWrap}>
      <SalesforceFinmateConnectIcons />
      <Typography variant='h3'>Something went wrong</Typography>
      <Typography variant='h3'>Localhost</Typography>
      <Typography sx={{ color: 'tomato' }}>{err}</Typography>
    </Box >
  )
}

const sxWrap: SxProps = {
  display: "flex",
  flexDirection: 'column',
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
}

const sxIcon: SxProps = {
  height: '50px',
  padding: 3
}
