import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, FormControl, Select, MenuItem, LinearProgress, Snackbar, Alert } from '@mui/material'
import 'firebase/auth'
import { useQuery } from '@tanstack/react-query'
import { getWealthboxConfig, setWealthboxVisibility } from 'service/integrations/wealthboxApi'
import { WealthboxVisibility } from 'client'
import { gSx } from 'styles/Theme'

export default function WealthboxConfigCard() {
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getWealthboxConfig'],
    queryFn: getWealthboxConfig,
  })
  const visibility = data?.visibility ?? WealthboxVisibility.EVERYONE

  async function onChangeVisibility(value: WealthboxVisibility) {
    setBusy(true)
    setErr(undefined)

    const res = await setWealthboxVisibility(value)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setOpenSuccessSnack(true)
      await refetch()
    }
    setBusy(false)
  }

  return (
    <Card>
      <Typography variant='h4'>Configurations</Typography>

      {busy &&
        <Box sx={{ width: '100%', paddingY: '1vh' }}>
          <LinearProgress />
        </Box>
      }

      <Box sx={{ padding: 1 }} />

      <Box sx={gSx.RowBetween} >
        <Box>
          <Typography variant='h6'>Default Visibility</Typography>
          <Typography>Wealthbox Notes and Tasks Visibility</Typography>
        </Box>
        <FormControl sx={{ width: 150 }}>
          {
            !isLoading &&
            <Select
              id="visibility"
              value={visibility}
              onChange={e => onChangeVisibility(e.target.value as WealthboxVisibility)}
              disabled={busy}
            >
              <MenuItem value={WealthboxVisibility.PRIVATE}>Only Me</MenuItem>
              <MenuItem value={WealthboxVisibility.EVERYONE}>{WealthboxVisibility.EVERYONE}</MenuItem>
            </Select>
          }

        </FormControl>
      </Box>

      {err &&
        <Typography color={'tomato'} sx={{ paddingVertical: 2 }}>
          {err}
        </Typography>
      }

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Wealthbox Configuration Updated
        </Alert>

      </Snackbar>

    </Card>
  )
}

const icon: SxProps = {
  width: 200,
  marginRight: 2
}

const sxConnect: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}