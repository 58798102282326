import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Button, Skeleton, Divider } from '@mui/material'
import 'firebase/auth'
import RedtailSignIn from '../../components/integrations/redtail/RedtailSignIn'
import { Media } from '../../service/media'
import { disconnect, getRedtailSettings } from '../../service/integrations/redtailApi'
import { useQuery } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import { gSx } from '../../styles/Theme'
import { logInfo } from 'log'
import IntegrationsBackButton from 'components/IntegrationsBackButton'
import { LoadingButton } from '@mui/lab'
import ContainerMedium from 'components/ContainerMedium'
import RedtailImportContacts from 'components/integrations/redtail/RedtailImportContacts'
import RedtailVisibilitySetting from 'components/integrations/redtail/RedtailVisibilitySetting'
import RedtailNotifyUserSetting from 'components/integrations/redtail/RedtailNotifyUserSetting'
import RedtailNotifyTeamSetting from 'components/integrations/redtail/RedtailNotifyTeamSetting'
import ContainerSmall from 'components/ContainerSmall'

export default function RedtailSettingsScreen() {
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<undefined | string>()

  useEffect(() => {
    logInfo('Redtail Settings Screen')
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
  })
  const isSignedIn = !!data?.user_id

  async function signout() {
    setErr(undefined)
    setBusy(true)
    const res = await disconnect()
    if (res instanceof Error)
      setErr(res.message)
    else
      await refetch()
    setBusy(false)
  }

  return (
    <>
      <Box sx={{ padding: '1vh ' }} />
      <IntegrationsBackButton />
      <ContainerSmall>
        <Typography variant='h3'>Redtail Integration</Typography>
        <Divider sx={{ marginY: '2vh ' }} />
        <Link href="https://redtailtechnology.com" rel='noopener' target='_blank'>
          <Box
            component="img"
            sx={sxLogo}
            src={Media.redtail}
          />
        </Link>
        <Box sx={{ padding: '1vh ' }} />

        <Typography>Connect FinMate AI to Redtail CRM, to easily keep your records up to date. </Typography>
        <Box sx={{ padding: '1vh ' }} />
        <Typography>Transfer your AI generated meeting notes, tasks, and contacts to Redtail.</Typography>
        <Box sx={{ padding: '1vh ' }} />
        <Typography>{`By connecting Redtail, Redtail Activities will show up in FinMate AI's Calendar.`}</Typography>

        <Typography>{`Redtail Calendar Activities, auto recording feature is coming soon.`}</Typography>

        <Box sx={{ padding: '1vh ' }} />
        <Card>

          {isLoading ? (
            <Skeleton variant="rectangular" width={'100%'} height={100} />
          ) : (
            isSignedIn ? (
              <Box sx={gSx.RowBetween}>
                <Typography>{`Signed in as `}<b>{`${data.username}`}</b></Typography>
                <Tooltip title="Disconnect Redtail">
                  <LoadingButton
                    loading={busy}
                    disabled={busy}
                    aria-label='Disconnect Redtail'
                    onClick={signout}
                  >
                    <CloseIcon />
                  </LoadingButton>
                </Tooltip>
              </Box>

            ) : <RedtailSignIn />
          )
          }

          {err && <Typography color={'tomato'}>
            {err}
          </Typography>
          }
        </Card >

        {isSignedIn && (
          <>
            <Card>
              <Typography variant='h4'>Configurations</Typography>
              {/* <Box sx={{ padding: 2 }} />
              <RedtailNotifyUserSetting />   TODO figure out how this works in Redtail
              <Box sx={{ padding: 2 }} />
              <RedtailNotifyTeamSetting /> */}
              <Box sx={{ padding: 2 }} />
              <RedtailVisibilitySetting />
            </Card>

            <Card>
              <Typography variant='h4'>Import Contacts</Typography>
              <Box sx={{ padding: 1 }} />
              <RedtailImportContacts />
              <Box sx={{ padding: 0.5 }} />
              <Typography variant='caption'>Only contacts with emails are imported.</Typography>
            </Card>
          </>
        )}
      </ContainerSmall>
    </>
  )
}

const sxLogo: SxProps = {
  width: 200,
}