import React from 'react'
import { OrgBase, OrgUserRole, ProductName, UserUsage } from '../../client'
import { Box, SxProps, Typography } from '@mui/material'
import UpgradeButton from '../pay/UpgradeButton'
import { auth } from 'service/api'
import { getOrgUser } from 'service/orgsApi'

interface Props {
  org?: OrgBase
  usage?: UserUsage
}
export default function HoursRemaining({ org, usage }: Props) {
  const uid = auth?.currentUser?.uid
  const orgUser = getOrgUser(org, uid)

  if (orgUser?.org_user_role === OrgUserRole.INACTIVE
    || orgUser?.org_user_plan !== ProductName.FREE_TRIAL)
    return null

  return (
    <>
      <Box sx={sxProfile}>
        <Typography sx={sxFree} variant='caption'>
          {`${usage?.free_hours_remaining?.toFixed(2)} Meeting Hours Remaining on your Free Trial`}
        </Typography>
        {/* <Box sx={{ padding: 1 }} /> */}
        <UpgradeButton />

        {
          usage?.free_hours_remaining === 0 &&
          <>
            <Box sx={{ padding: 1 }} />
            <Typography variant='caption'>
              {'We are here to help. Contact us at the bottom right for any questions.'}
            </Typography>
          </>
        }
      </Box>
      {/* <Divider /> */}
    </>
  )

}
const sxFree: SxProps = {
  fontWeight: 'bold',
  color: 'red',

}

const sxProfile: SxProps = {
  py: 1.5,
  px: 2,
  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
  // backgroundColor: 'blue',
}