import _ from 'lodash'
import { Api, auth } from './api'
import { logErr, logInfo } from '../log'
import { OrgConfigs, OrgUser, OrgUserRole, OrgUserStatus, OrgBase, OrgInvite, ProductName, FinmateSubscription } from '../client'
import { getErrorMessage } from 'utils'
import { errorMonitor } from 'events'

export async function getOrg(): Promise<OrgBase | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    return await api.orgs.getOrg()
  } catch (e) {
    logErr('getOrg', { e })
  }
}

export function isAdminRole(org?: OrgBase): boolean {
  if (!org)
    return false
  for (const user of org.org_user_list)
    if (user.uid === auth.currentUser?.uid &&
      user.org_user_role === OrgUserRole.ADMIN)
      return true
  return false
}

export function getOrgUser(org?: OrgBase, uid?: string) {
  if (!org || !uid)
    return undefined

  for (const orgUser of org.org_user_list) {
    if (orgUser.uid == uid)
      return orgUser
  }
}

export function numActivePaidPlansOrgUsers(orgUsers?: OrgUser[]): number {
  if (!orgUsers)
    return 0
  return _.filter(orgUsers, (user =>
    user.org_user_role != OrgUserRole.INACTIVE &&
    (user.org_user_plan == ProductName.STANDARD ||
      user.org_user_plan == ProductName.STARTER)
  )).length
}

export function numActiveOrgUsersByPlan(plan: ProductName, orgUsers?: OrgUser[]): number {
  if (!orgUsers)
    return 0
  return _.filter(orgUsers, (user =>
    user.org_user_plan === plan && user.org_user_role != OrgUserRole.INACTIVE
  )).length
}

export async function updateOrgUser(orgUser: OrgUser): Promise<OrgUser[] | Error> {
  try {
    logInfo('Update Org User', { orgUser })
    const api = await Api()
    return await api.orgs.updateOrgUser(orgUser)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateOrgUser', { err })
    return err
  }
}

export async function getOrgConfigs(): Promise<any> {
  try {
    const api = await Api()
    const res = await api.orgs.getOrgConfigs()
    if (res)
      return res
    return []
  } catch (e) {
    logErr('getOrgConfigs', { e })
  }
  return []
}

export async function updateOrgConfigs(
  org_configs: OrgConfigs): Promise<any> {
  try {
    logInfo('Update Org Configs', { org_configs })
    const api = await Api()
    const res = await api.orgs.updateOrgConfigs(org_configs)
    if (res)
      return res
    return {}
  } catch (e) {
    logErr('updateOrgConfigs', { e })
  }
  return []
}

export async function setOrgName(orgName: string): Promise<any> {
  try {
    logInfo('Set Org Name', { orgName })
    const api = await Api()
    const res = await api.orgs.setOrgName(orgName)
    if (res)
      return res
    return
  } catch (e) {
    logErr('setOrgName', { e })
  }
  return
}

export async function createOrgInvites(role: OrgUserRole, emails: string[]): Promise<OrgInvite[] | undefined> {
  try {
    logInfo('createOrgInvites', { emails })
    const api = await Api()
    return await api.orgs.createOrgInvites(role, emails)
  } catch (e) {
    logErr('createOrgInvites', { e })
  }
  return
}

export async function getOrgInvitesAsRecipient(): Promise<OrgInvite[] | undefined> {
  try {
    logInfo('getOrgInvitesAsRecipient')
    const api = await Api()
    return await api.orgs.getOrgInvitesAsRecipient()
  } catch (e) {
    logErr('getOrgInvitesAsRecipient', { e })
  }
  return
}

export async function getOrgInvites(): Promise<OrgInvite[] | undefined> {
  try {
    logInfo('getOrgPendingDeclinedInvites')
    const api = await Api()
    return await api.orgs.getOrgInvites()
  } catch (e) {
    logErr('getOrgInvites', { e })
  }
  return
}

export async function answerOrgInvite(inviteId: string, newStatus: OrgUserStatus): Promise<OrgInvite | undefined> {
  if (inviteId.length == 0)
    return
  try {
    logInfo('answerOrgInvite', { inviteId, newStatus })
    const api = await Api()
    return await api.orgs.answerOrgInvite(inviteId, newStatus)
  } catch (e) {
    logErr('answerOrgInvite', { e })
  }
  return
}

export function payingSeatsRemaining(plan: ProductName, org?: OrgBase, sub?: FinmateSubscription) {
  if (!org)
    return 0

  if (plan == ProductName.STANDARD) {
    const paid = sub?.standard?.quantity ?? 0
    const num_org = numActiveOrgUsersByPlan(ProductName.STANDARD, org.org_user_list)
    return paid - num_org
  }

  if (plan == ProductName.STARTER) {
    const paid = sub?.starter?.quantity ?? 0
    const num_org = numActiveOrgUsersByPlan(ProductName.STARTER, org?.org_user_list)
    return paid - num_org
  }

  if (plan == ProductName.LITE) {
    const paid = sub?.lite?.quantity ?? 0
    const num_org = numActiveOrgUsersByPlan(ProductName.LITE, org?.org_user_list)
    return paid - num_org
  }

  return 0
}

export function checkSwapPayingSeat(org?: OrgBase, sub?: FinmateSubscription, original?: OrgUser, update?: OrgUser) {
  if (!original
    || !update
    || original.email !== update.email
    || original.org_user_role == OrgUserRole.INACTIVE
    || update.org_user_role == OrgUserRole.INACTIVE
  ) {
    return false
  }

  if ((original.org_user_plan == ProductName.STARTER && update.org_user_plan == ProductName.STANDARD) ||
    (original.org_user_plan == ProductName.STANDARD && update.org_user_plan == ProductName.STARTER)
  ) {
    // is swapping.

    const standardRemaining = payingSeatsRemaining(ProductName.STANDARD, org, sub)
    const starterRemaining = payingSeatsRemaining(ProductName.STARTER, org, sub)

    if (update.org_user_plan == ProductName.STANDARD && standardRemaining >= 1)
      return false // is swapping, and enough seats
    if (update.org_user_plan == ProductName.STARTER && starterRemaining >= 1)
      return false // is swapping, and enough seats

    // is swapping, and not enough seats
    return true
  }

  return false
}

export function checkAddPayingSeat(org?: OrgBase, sub?: FinmateSubscription, original?: OrgUser, update?: OrgUser) {
  // from non paying FREE TRIAL EXPIRED LITE to paying STANDARD STARTER   
  // used to trigger purchase

  const standardRemaining = payingSeatsRemaining(ProductName.STANDARD, org, sub)
  const starterRemaining = payingSeatsRemaining(ProductName.STARTER, org, sub)

  if (!original
    || !update
    || original.uid !== update.uid
    || update.org_user_role == OrgUserRole.INACTIVE
  ) {
    return false
  }

  if (original.org_user_plan == ProductName.STANDARD || original.org_user_plan == ProductName.STARTER)
    return false

  if (update.org_user_plan == ProductName.EXPIRED || update.org_user_plan == ProductName.FREE_TRIAL || update.org_user_plan == ProductName.LITE)
    return false

  if (update.org_user_plan == ProductName.STARTER && starterRemaining > 0)
    return false

  if (update.org_user_plan == ProductName.STANDARD && standardRemaining > 0)
    return false

  return true
}

export function getIsLiteMaxedOut(org?: OrgBase, sub?: FinmateSubscription, original?: OrgUser, update?: OrgUser) {
  const liteRemaining = payingSeatsRemaining(ProductName.LITE, org, sub)
  if (!original || !update)
    return false

  if (original.org_user_plan == ProductName.LITE || update.org_user_plan != ProductName.LITE)
    return false

  if (liteRemaining == 0)
    return true

  return false
}

export function isAtLeastOneActiveAdmin(targetUser: OrgUser, org?: OrgBase) {
  if (!org)
    return false

  let activeAdmin = 0
  for (const u of org.org_user_list) {
    if (u.uid == targetUser.uid) {
      if (targetUser.org_user_role == OrgUserRole.ADMIN)
        activeAdmin++
    } else {
      if (u.org_user_role == OrgUserRole.ADMIN)
        activeAdmin++
    }
    if (activeAdmin >= 1)
      return true
  }
  return false
}
