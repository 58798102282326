import React, { useState, useEffect } from 'react'
import { SxProps, Card, Skeleton, Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Api } from '../../service/api'
import { logInfo } from '../../log'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import FollowupEmailCustomizer from '../templates/FollowupEmailCustomizer'
import TextEditorCard from 'components/editor/TextEditorCard'
import EmailIcon from '@mui/icons-material/Email'
import { createGmailComposeURL, createOutlookComposeURL, htmlToPlainText, isFirebaseUserGoogle } from '../../utils'
import { EventBase, SummaryProcessingStatus, ProcessingStatus } from '../../client'
import { getEventSummaryStatus } from '../../service/eventApi'
import { EventSkeletonTranscriptInProgressPageForFollowupEmail } from 'components/Skeletons'

interface Props {
  eventId: string
  eventMetaData?: EventBase
  isEventMetaDataLoading?: boolean
  isPublic?: boolean
  disabled: boolean
}

export default function EventFollowupEmail({ eventId, eventMetaData, isEventMetaDataLoading, isPublic, disabled }: Props) {

  const [edittedEmail, setEdittedEmail] = useState("")

  const isGoogle = isFirebaseUserGoogle()

  const { data: followupEmailContent, isLoading: isFollowupEmailLoading } = useQuery({
    queryKey: ['getEventFollowupEmail', eventId],
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
  })

  async function runQuery() {
    if (isPublic && eventId) {
      const api = await Api()
      return await api.public.getPublicEventFollowupEmail(eventId)
    }
    if (eventId) {
      const api = await Api()
      return await api.events.getEventFollowupEmail(eventId)
    }
  }

  const { data: eventStatus, isLoading: isEventStatusLoading } = useQuery({
    queryKey: ['getEventSummaryStatus', eventId],
    queryFn: runQueryEventStatus,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
    refetchInterval: () => isFollowupEmailLoading ? 7000 : false, // refetch every 7 sec when followup email is not loaded
  })

  async function runQueryEventStatus() {
    if (isPublic) {
      const publicStatus = { transcript_status: ProcessingStatus.DONE, summary_overall_status: ProcessingStatus.DONE, followup_email_status: ProcessingStatus.DONE } as SummaryProcessingStatus
      return publicStatus
    }
    if (eventId) {
      const res = await getEventSummaryStatus(eventId)
      return res
    }
  }

  const followupEmailModuleNotSelected = eventMetaData?.followup_email_module_id ? false : true

  useEffect(() => {
    logInfo('Event Followup Email', { event_id: eventId })
  }, [])

  useEffect(() => {
    if (eventStatus?.followup_email_status == ProcessingStatus.PROCESSING) {
      // clear edittedEmail for rerun
      if (edittedEmail.length > 0) {
        setEdittedEmail("")
      }
    }
  }, [eventStatus])

  function isHTML(str: string) {
    const doc = new DOMParser().parseFromString(str, "text/html")
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
  }

  function textToHTML(text: string) {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .replace(/\n/g, "<br>")
  }

  useEffect(() => {
    if (followupEmailContent) {
      if (isHTML(followupEmailContent)) {
        setEdittedEmail(followupEmailContent)
      } else {
        // backward compatible 
        const followupEmailContentHtml = textToHTML(followupEmailContent)
        setEdittedEmail(followupEmailContentHtml)
      }
    }

  }, [followupEmailContent])

  async function saveEmail(edittedHtml: string) {
    if (eventId && !isPublic) {
      const api = await Api()
      await api.events.patchEventFollowupEmail(eventId, edittedHtml)
      setEdittedEmail(edittedHtml)
    }
  }

  function onCopyToClipboard(edittedHtml: string) {
    return edittedHtml
  }

  function sendFollowupEmail() {
    let emailUrl: string
    let clientEmail = ""
    if (eventMetaData?.client?.email) {
      clientEmail = eventMetaData?.client?.email
    }
    if (isGoogle) {
      const plaintext = htmlToPlainText(edittedEmail)
      emailUrl = createGmailComposeURL(clientEmail, "", plaintext)
    } else {
      const plaintext = htmlToPlainText(edittedEmail, true)
      emailUrl = createOutlookComposeURL(clientEmail, "", plaintext)
    }
    window.open(emailUrl, '_blank')
  }

  function isTranscriptProcessing() {
    if (eventStatus?.transcript_status == ProcessingStatus.PROCESSING)
      return true
    return false
  }

  function isTranscriptEmpty() {
    if (eventStatus?.transcript_status == ProcessingStatus.DONE_EMPTY_CONTENT)
      return true
    return false
  }

  function isTranscriptAborted() {
    if (eventStatus?.transcript_status == ProcessingStatus.ABORTED)
      return true
    return false
  }

  function isFollowupEmailCustomizationReady() {
    if (!isEventMetaDataLoading && eventStatus?.transcript_status == ProcessingStatus.DONE && followupEmailModuleNotSelected) {
      return true
    }
    return false
  }

  function isFollowupEmailProcessing() {
    if (eventStatus?.followup_email_status == ProcessingStatus.PROCESSING) {
      return true
    }
    if (edittedEmail.length == 0) {
      return true
    }
    if (!followupEmailModuleNotSelected && isFollowupEmailLoading) {
      logInfo("isFollowupEmailProcessing fallback logic", { event_id: eventId, event_status: eventStatus })
      return true
    }
    return false
  }

  function isFollowupEmailDone() {
    if (!isEventMetaDataLoading && eventStatus?.followup_email_status == ProcessingStatus.DONE && !isFollowupEmailLoading)
      return true
    return false
  }

  if (isFollowupEmailDone())
    return (
      <TextEditorCard
        eventId={eventId ?? null}
        title="Meeting Followup Email (BETA)"
        htmlContent={edittedEmail}
        disabled={(disabled ?? true) || (isPublic ?? false)}
        onSave={saveEmail}
        onCopyToClipboard={onCopyToClipboard}
        headerComponents={
          <>
            <Tooltip title={isGoogle ? 'Open in Gmail' : 'Open in Outlook'}>
              <IconButton
                aria-label='send followup email'
                onClick={() => sendFollowupEmail()}
                disabled={disabled}
              >
                <EmailIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      />
    )

  if (isTranscriptProcessing()) {
    return <EventSkeletonTranscriptInProgressPageForFollowupEmail />
  }

  if (isTranscriptEmpty()) {
    return (
      <Card sx={sxCard}>
        <Box sx={{ padding: '12vh' }} />
        <Typography sx={{ fontWeight: 'bold' }} textAlign={'center'}>No Meeting Transcript Available</Typography>
        <Typography textAlign={'center'}>Follwup Email is not available due to an empty transcript.</Typography>
        <Typography textAlign={'center'}>If this seems incorrect, please contact support for assistance.</Typography>
        <Box sx={{ padding: '22vh' }} />
      </Card>)
  }

  if (isTranscriptAborted()) {
    return (
      <Card sx={sxCard}>
        <Box sx={{ padding: '12vh' }} />
        <Typography sx={{ fontWeight: 'bold' }} textAlign={'center'}>Transcript Processing Error Occurred</Typography>
        <Typography textAlign={'center'}>We apologize, but an error has occurred during transcript processing.</Typography>
        <Typography textAlign={'center'}>Please contact our support team for further assistance in resolving this issue.</Typography>
        <Box sx={{ padding: '22vh' }} />
      </Card>)
  }

  if (isFollowupEmailCustomizationReady()) {
    return <FollowupEmailCustomizer eventId={eventId} disabled={disabled} />
  }

  const skeletonHeight = 20
  if (isFollowupEmailProcessing())
    return (
      <Card>
        <Box sx={{ padding: '2vh' }} />
        <Skeleton variant="rectangular" width={'20%'} height={skeletonHeight} />
        <Box sx={{ padding: '2vh' }} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Box sx={{ padding: '2vh' }} />
        <Typography textAlign={'center'}>Processing the followup email</Typography>
        <Typography textAlign={'center'}>Please check in a few minutes</Typography>
        <Box sx={{ padding: '2vh' }} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Box sx={{ padding: '2vh' }} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
        <Skeleton variant="rectangular" width={'30%'} height={skeletonHeight} />
      </Card>
    )

  return (
    <Card>
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={skeletonHeight} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'30%'} height={skeletonHeight} />
    </Card>
  )
}

const sxCard: SxProps = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '75vh',
}
