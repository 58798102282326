import { types, applySnapshot } from 'mobx-state-tree'
import { OrgUser, EventSearchParams, User as UserType } from '../client'

const User = types.model('UserStore', {
  videoSeekSeconds: types.maybe(types.number),
  transcriptSeekSeconds: types.maybe(types.number),
  eventCount: types.maybe(types.number),
  hideTip: types.maybe(types.boolean),
  eventSearchParams: types.maybe(types.string), // JSON

  affiliateCID: types.maybe(types.string),
}).actions(self => ({
  setSeekSeconds(seconds: number) {
    self.videoSeekSeconds = seconds
  },
  setTranscriptSeekSeconds(seconds: number) {
    self.transcriptSeekSeconds = seconds
  },
  setEventCount(eventCount: number) {
    self.eventCount = eventCount
  },
  setHideTip(hide: boolean) {
    self.hideTip = hide
  },
  setEventSearchParams(eventSearchParams?: EventSearchParams) { // firebase User
    self.eventSearchParams = eventSearchParams ? JSON.stringify(eventSearchParams) : undefined
  },
  reset() {
    applySnapshot(self, {

    })
  },

})).views(self => ({
  getSeekSeconds() {
    return self.videoSeekSeconds ? self.videoSeekSeconds : 0
  },
  getTranscriptSeekSeconds() {
    return self.transcriptSeekSeconds ? self.transcriptSeekSeconds : 0
  },
  getEventSearchParams(): EventSearchParams {
    return self.eventSearchParams ? JSON.parse(self.eventSearchParams) : {}
  },
}))

export default User