import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, List, ListItem, Grid, Chip } from '@mui/material'
import { Api } from '../../service/api'
import { SubPromptOptionFormatType } from '../../client'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import SubjectIcon from '@mui/icons-material/Subject'
import { gSx } from '../../styles/Theme'

export type SampleOutputHighlightOptions = {
  ToneOfVoice?: boolean;
  LevelOfDetail?: boolean;
  Format?: boolean;
  PointOfView?: boolean
}

interface DialogProps {
  sectionTitle: string;
  open: boolean;
  onClose: () => void;
  subpromptTemplateId: string;
  highlightOptions: SampleOutputHighlightOptions;
}

export const SampleOutputDialog: React.FC<DialogProps> = ({ sectionTitle, open, onClose, subpromptTemplateId, highlightOptions }) => {

  const { data, isLoading } = useQuery({
    queryKey: ['getSubPromptTemplate', subpromptTemplateId],
    queryFn: runQuery,
    retry: true,
  })

  async function runQuery() {
    if (subpromptTemplateId) {
      const api = await Api()
      const res = await api.customizations.getSubPromptTemplate(subpromptTemplateId)
      return res
    }
  }

  return (
    <Dialog fullWidth maxWidth='lg' open={open} onClose={onClose}>
      <DialogTitle>Sample Results for {sectionTitle}</DialogTitle>
      <DialogContent>
        {isLoading && <CircularProgress />}
        {data && (
          <List>
            {data.sample_output_list?.map((item, index: number) => (
              <ListItem key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    {highlightOptions.ToneOfVoice && item.options?.tone_of_voice && (
                      <Chip color={highlightOptions.ToneOfVoice ? "primary" : "default"} label={item.options?.tone_of_voice} />)
                    }
                    {highlightOptions.PointOfView && item.options?.point_of_view && (
                      <Chip color={highlightOptions.PointOfView ? "primary" : "default"} label={item.options?.point_of_view} />)
                    }
                    {highlightOptions.LevelOfDetail && item.options?.level_of_detail && (
                      <Chip color={highlightOptions.LevelOfDetail ? "primary" : "default"} label={item.options?.level_of_detail} />)
                    }
                    {highlightOptions.Format && item.options?.format_type && (
                      <Chip color={highlightOptions.Format ? "primary" : "default"} label={item.options?.format_type} icon={item.options?.format_type == SubPromptOptionFormatType.LIST ? <FormatListBulletedIcon /> : SubPromptOptionFormatType.PARAGRAPH ? <SubjectIcon /> : <></>} />)
                    }
                  </Grid>
                  <Grid item xs={10}>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.text ?? "Coming soon" }}
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        padding: '16px',
                        overflow: 'auto',
                        backgroundColor: '#fcfcfc',
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog >
  )
}
