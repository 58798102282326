/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RedtailVisibility {
    ONLY_ME = 'Only Me',
    ONLY_ATTENDEES = 'Only Attendees',
    EVERYONE = 'Everyone',
}
