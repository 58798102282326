import React, { useEffect, useState } from 'react'
import { Box, SxProps } from '@mui/material'
import { Media } from 'service/media'
import { SalesforceApp } from 'client'
import SyncAltIcon from '@mui/icons-material/SyncAlt'

export default function SalesforceFinmateConnectIcons() {
  const [app, setApp] = useState<SalesforceApp | undefined>()
  const storageItem = localStorage.getItem('SalesforceApp')

  useEffect(() => {
    if (storageItem != null)
      setApp(storageItem as SalesforceApp)
  }, [storageItem])

  if (app == undefined)
    return null

  if (app == SalesforceApp.PRACTIFI)
    return (
      <Box sx={sxIconWrapper}>
        <Box component='img' sx={sxIcon} src={Media.logo100} />
        <SyncAltIcon />
        <Box component='img' sx={sxIcon} src={Media.practifiIcon} />
      </Box>
    )

  if (app == SalesforceApp.XLR8)
    return (
      <Box sx={sxIconWrapper}>
        <Box component='img' sx={sxIcon} src={Media.logo100} />
        <SyncAltIcon />
        <Box component='img' sx={sxIcon} src={Media.xlr8Icon} />
      </Box>
    )

  return (
    <Box sx={sxIconWrapper}>
      <Box component='img' sx={sxIcon} src={Media.logo100} />
      <SyncAltIcon />
      <Box component='img' sx={sxIcon} src={Media.salesforceIcon} />
    </Box>
  )
}

const sxIconWrapper: SxProps = {
  display: "flex",
  flexDirection: 'row',
  justifyContent: "center",
  alignItems: "center",
}

const sxIcon: SxProps = {
  height: '50px',
  padding: 3
}
