import React, { KeyboardEvent, useEffect, useRef, useState } from 'react'

import { logErr, logInfo } from 'log'

import { LoadingButton } from '@mui/lab'
import { MenuProps } from '@mui/material/Menu'
import { alpha, styled } from '@mui/material/styles'
import PushPinIcon from '@mui/icons-material/PushPin'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { dom, IconName, library } from '@fortawesome/fontawesome-svg-core'
import { Button, CardHeader, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, TextField, Tooltip, CircularProgress } from '@mui/material'

import IconSelector from './IconSelector'
import { getCustomizationsConfigs, updateCustomizationsConfigs } from '../../service/eventApi'

export const MeetingTypePinnedMsgBefore = "Pin to show in Meeting"
export const MeetingTypePinnedMsgAfter = "Pinned: Shown in Meeting"

interface CustomizerCardHeaderProps {
  module_id?: string
  isCustomizable?: boolean
  isEditable?: boolean
  module_ui_title: string
  module_ui_icon: string
  module_ui_description: string
  onTitleChange?: (title: string) => void
  onIconChange?: (icon: string) => void
  onDescriptionChange?: (description: string) => void
  onDuplicateModule?: () => Promise<void>
  onDeleteModule?: () => Promise<void>
}

const CustomizerCardHeader: React.FC<CustomizerCardHeaderProps> = ({ module_id, isCustomizable, isEditable, module_ui_title, module_ui_icon, module_ui_description, onTitleChange, onIconChange, onDescriptionChange, onDuplicateModule, onDeleteModule }) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [pinned, setPinned] = useState(false)
  const [busy, setBusy] = useState(false)
  const inputRefTitle = useRef<HTMLInputElement>(null)
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)

  const { data: customizationsConfigs, isLoading: isLoadingConfigs, refetch: refetchConfigs } = useQuery({
    queryKey: ['customizationsConfigs'], queryFn: getConfigs
  })

  async function getConfigs() {
    const configs = await getCustomizationsConfigs()
    return configs
  }

  useEffect(() => {
    if (module_id && customizationsConfigs && customizationsConfigs.summarize_module_id_list) {
      const pinned = customizationsConfigs.summarize_module_id_list?.includes(module_id)
      setPinned(pinned)
    }
  }, [module_id, customizationsConfigs])

  const handleTitleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setIsEditingTitle(false)
    }
  }

  function handleEditTitle(updatedTitle: string) {
    if (onTitleChange)
      onTitleChange(updatedTitle)
  }

  function handleEditDescription(updatedDescription: string) {
    if (onDescriptionChange)
      onDescriptionChange(updatedDescription)
  }

  function handleEditIcon(updatedIcon: string) {
    if (onIconChange)
      onIconChange(updatedIcon)
  }

  function handleTitleClick() {
    if (isEditable)
      setIsEditingTitle(true)
  }

  async function handlePinToggle() {
    if (!module_id) return
    if (!customizationsConfigs) return
    if (module_id == "allpurpose-module-general") return
    setPinned(!pinned)
    const updatedConfig = { ...customizationsConfigs }
    if (pinned && customizationsConfigs.summarize_module_id_list) {
      updatedConfig.summarize_module_id_list = customizationsConfigs.summarize_module_id_list.filter(item => item != module_id)
    } else {
      if (updatedConfig.summarize_module_id_list) {
        updatedConfig.summarize_module_id_list.push(module_id)
      } else {
        updatedConfig.summarize_module_id_list = [module_id]
      }
    }
    try {
      updatedConfig.summarize_module_id_list = Array.from(new Set(updatedConfig.summarize_module_id_list))
      await updateCustomizationsConfigs(updatedConfig)
      await refetchConfigs()
    } catch (error) {
      logErr("Update module configurations failed", { error })
      // Revert the optimistic update if there was an error
      setPinned(!pinned)
    }
  }

  function handleClickMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorMenu(event.currentTarget)
  }

  function handleMenuClose() {
    setAnchorMenu(null)
  }

  async function handleDuplicateModule() {
    setBusy(true)
    if (onDuplicateModule)
      await onDuplicateModule()
    setAnchorMenu(null)
    setBusy(false)
  }

  function openDeleteDialog() {
    setDeleteDialogOpen(true)
  }

  async function handleDeleteModule() {
    setBusy(true)
    if (onDeleteModule)
      await onDeleteModule()
    setAnchorMenu(null)
    setBusy(false)
  }

  if (!isCustomizable) {
    return (
      <CardHeader
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={['fas', module_ui_icon as IconName]}
              style={{ color: 'grey', marginRight: 16 }}
              fontSize="24px"
            />
            <span style={{ cursor: 'pointer' }}>
              {module_ui_title}
            </span>
          </div>
        }
        subheader={module_ui_description}
        action={
          <div>
            <Tooltip title={pinned ? MeetingTypePinnedMsgAfter : MeetingTypePinnedMsgBefore}>
              <IconButton onClick={(e) => { handlePinToggle() }}>
                <PushPinIcon
                  sx={{ color: pinned ? '#FF9800' : 'grey' }}
                />
              </IconButton>
            </Tooltip>
          </div>
        }
      />
    )
  }

  return (
    <>
      <ClickAwayListener onClickAway={(e) => setIsEditingTitle(false)}>
        <CardHeader
          title={
            isEditingTitle ? (
              <TextField
                value={module_ui_title}
                onChange={(e: any) => handleEditTitle(e.target.value)}
                autoFocus
                fullWidth
                size="small"
                label="Title"
                variant="filled"
                onKeyDown={handleTitleKeyDown}
                inputRef={inputRefTitle}
              />

            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconSelector selectedIcon={module_ui_icon} disabled={!isEditable} onSelect={
                  (iconName) => {
                    handleEditIcon(iconName)
                  }
                }></IconSelector>
                <span onClick={handleTitleClick} style={{ cursor: 'pointer' }}>
                  {module_ui_title}
                </span>
              </div>
            )
          }

          subheader={
            isEditingTitle ? (
              <TextField
                value={module_ui_description}
                onChange={(e: any) => handleEditDescription(e.target.value)}
                autoFocus
                fullWidth
                size="small"
                label="Description"
                variant="filled"
                onKeyDown={handleTitleKeyDown}
                inputRef={inputRefTitle}
              />
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span onClick={handleTitleClick} style={{ cursor: 'pointer' }}>
                  {module_ui_description}
                </span>
              </div>
            )
          }
          action={
            <div>
              <Tooltip title={pinned ? MeetingTypePinnedMsgAfter : MeetingTypePinnedMsgBefore}>
                <IconButton onClick={(e) => { handlePinToggle() }}>
                  <PushPinIcon
                    sx={{ color: pinned ? '#FF9800' : 'grey' }}
                  />
                </IconButton>
              </Tooltip>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openMenu ? 'long-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                <MoreVertIcon />
              </IconButton>
              <StyledMenu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorMenu}
                open={openMenu}
                onClose={handleMenuClose}
              >
                <MenuItem key={"duplicate"} onClick={handleDuplicateModule}>
                  {busy ? <CircularProgress size={16} style={{ marginRight: '14px' }} /> : <FileCopyIcon />}
                  Duplicate
                </MenuItem>
                {
                  isEditable &&
                  <MenuItem key={"delete"} onClick={openDeleteDialog}>
                    <DeleteForeverIcon />
                    Delete
                  </MenuItem>
                }

              </StyledMenu>
            </div>
          }
        />
      </ClickAwayListener>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Confirm Delete Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Warning: This Action is Irreversible
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            color="primary"
            aria-label='cancel'
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={handleDeleteModule}
            color="error"
            loading={busy}
            autoFocus
            aria-label='delete'
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog >
    </>
  )
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 160,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))

export default CustomizerCardHeader
