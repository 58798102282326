import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, Checkbox, Typography } from '@mui/material'
import { CRM } from 'client'
import Grid from '@mui/material/Unstable_Grid2'
import { gSx } from 'styles/Theme'
import { MasterTask } from './SelectTasks'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import TaskAssigneeChip from 'components/event/TaskAssigneeChip'

interface Props {
  originalAssignee?: string
  masterTasks: MasterTask[]
  setMasterTasks: Dispatch<SetStateAction<MasterTask[]>>
  disabled: boolean
  crm: CRM
}

export default function SelectTasksByAssignee(props: Props) {
  const { originalAssignee, masterTasks, setMasterTasks, disabled, crm } = props
  const [selectedAssignee, setSelectedAssignee] = useState(false)

  const assigneeTasks = getTasksByOriginalAssignee(masterTasks, originalAssignee)
  const assigneeInfo = assigneeTasks[0].assignee_info

  useEffect(() => { // select all, auto
    const allSelected = assigneeTasks.every(task => task.isSelected)
    setSelectedAssignee(allSelected)
  }, [assigneeTasks])

  const toggleSelectByAssignee = (event?: any) => {
    event?.preventDefault()
    const updated: MasterTask[] = masterTasks.map(m =>
      originalAssignee === m.originalAssignee
        ? { ...m, isSelected: !selectedAssignee } : m
    )
    setMasterTasks(updated)
    setSelectedAssignee(!selectedAssignee)
  }

  const toggleSelectItem = (task: MasterTask) => {
    const updated: MasterTask[] = masterTasks.map(m =>
      task.id === m.id ? { ...m, isSelected: !task.isSelected } : m
    )
    setMasterTasks(updated)
  }

  function isRedtailUser() {
    return !!(assigneeTasks.length > 0 && assigneeTasks[0].assignee_info?.redtail_user_id)
  }
  function isWealthboxUser() {
    return !!(assigneeTasks.length > 0 && assigneeTasks[0].assignee_info?.wealthbox_user_id)
  }
  function isSalesforceUser() {
    return !!(assigneeTasks.length > 0 && assigneeTasks[0].assignee_info?.salesforce_user_id)
  }

  if (!assigneeInfo)
    return null

  return (
    <Box sx={{ backgroundColor: '#f9f9f9', marginBottom: 1, borderRadius: 2, padding: 1 }}>

      <Box sx={gSx.Row}>
        <Checkbox
          checked={selectedAssignee}
          onChange={(event) => toggleSelectByAssignee(event)}
          disabled={disabled}
        />
        <TaskAssigneeChip
          assignee={assigneeInfo}
          targetCrm={crm}
          disabled={true}
        />

        <Box sx={{ paddingX: 0.5 }} />
        <Typography variant='caption' color={'gray'}>{`(Select all by Assignee)`}</Typography>
      </Box>

      {(
        (crm == CRM.REDTAIL && !isRedtailUser())
        || (crm == CRM.WEALTHBOX && !isWealthboxUser())
        || (crm == CRM.SALESFORCE && !isSalesforceUser())
      ) &&
        <Typography variant='caption'>Selected tasks will be assigned to you, since this person is not a {crm} User.</Typography>
      }

      <Box sx={{ padding: 0.5 }} />
      {assigneeTasks.map((t) => (
        <Grid container spacing={2} key={t.id}>
          <Grid xs={1} />
          <Grid xs={1}>
            <Checkbox
              sx={{ padding: 0 }}
              checked={t.isSelected}
              onChange={() => toggleSelectItem(t)}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={7}>
            <Typography>{t.description}</Typography>
          </Grid>

          <Grid xs={3}>
            <DatePicker
              label="Due Date"
              value={dayjs(t.due_date_datetime)}
              slotProps={{ textField: { size: 'small' } }}
              sx={{ width: '150px' }}
              disabled
            />
          </Grid>
        </Grid>
      ))}

    </Box>
  )
}

function getTasksByOriginalAssignee(tasks: MasterTask[], originalAssignee?: string) {
  return tasks.filter(item => item.originalAssignee === originalAssignee)
}