import { Box, Button, SxProps, Typography } from "@mui/material"
import GuideBoxWithArrow from "./GuideBoxWithArrow"
import { observer } from "mobx-react-lite"
import { useStore } from "../../hooks/useStore"

interface Props {
  last?: boolean
}
export default observer(EventGuideBackNext)
function EventGuideBackNext({ last }: Props) {
  const store = useStore()
  const step = store.System.getEventPublicTourStep()

  return (
    <Box sx={sxButtonWrap}>
      {
        step === 0 ? (
          <Button
            variant="outlined"
            onClick={() => store.System.setEventPublicTourStep(-1)}
          >
            Skip Tour
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={() => store.System.setEventPublicTourStep(step - 1)}
          >
            Back
          </Button>
        )
      }
      <Box sx={{ paddingX: '3vh' }}></Box>
      <Button
        variant="contained"
        onClick={() => store.System.setEventPublicTourStep(step + 1)}
      >
        {
          last ? 'Close' : 'Next'}
      </Button>
    </Box>
  )

}

const sxButtonWrap: SxProps = {
  paddingTop: '30px',
  display: 'flex',
  justifyContent: 'space-between',
}