import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getUsage } from '../service'
import { OrgUserRole, ProductName } from '../client'
import { getOrg, getOrgUser } from '../service/orgsApi'
import { auth } from 'service/api'

export interface BlockPlan {
  isBlocked: boolean,
  userPlan?: ProductName,
  isLoading: boolean
}
export default function useIsBlockedByPlan(isLiteAllowed?: boolean) {
  const uid = auth?.currentUser?.uid

  const { data: org, isLoading, refetch } = useQuery({
    queryKey: ['getOrg'],
    queryFn: getOrg,
    enabled: false
  })
  const orgUser = getOrgUser(org, uid)

  const { data: usage, isLoading: isLoadingUsage, refetch: refetchUsage } = useQuery({
    queryKey: ['getUsage'],
    queryFn: getUsage,
    enabled: false
  })

  useEffect(() => {
    if (!org)
      refetch()
    if (!usage)
      refetchUsage()
  }, [org, usage])

  function disable() {
    const userPlan = orgUser?.org_user_plan

    if (userPlan === ProductName.FREE_TRIAL && usage?.free_hours_remaining === 0)
      return true
    if (userPlan === ProductName.EXPIRED)
      return true
    if (orgUser?.org_user_role == OrgUserRole.INACTIVE)
      return true
    if (!isLiteAllowed && userPlan === ProductName.LITE)
      return true
    return false
  }

  const res: BlockPlan = {
    isBlocked: disable(),
    isLoading: isLoading || isLoadingUsage,
    userPlan: orgUser?.org_user_plan
  }
  return res
}