import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from '@tanstack/react-query'
import { Box, Card, CardContent, SxProps, Typography } from '@mui/material'
import { logInfo } from '../../log'

import { Api } from '../../service/api'
import EventModuleSelect from './EventModuleSelect'
import { EventBase, SummaryProcessingStatus, ProcessingStatus } from '../../client'
import { getEventSummaryBase, getEventSummaryStatus } from '../../service/eventApi'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import EventDetailsDone from './EventDetailsDone'
import { EventSkeletonPage, EventSkeletonSummaryInProgressPage, EventSkeletonTranscriptInProgressPage } from 'components/Skeletons'

interface Props {
  eventId: string
  data?: EventBase
  disabled: boolean
  isEventMetaDataLoading?: boolean
  isPublic?: boolean
}
export default observer(EventDetailsWrapper)
function EventDetailsWrapper({ eventId, data, disabled, isPublic, isEventMetaDataLoading }: Props) {
  const [hasData, setHasData] = useState(false)

  const { data: summary, isLoading: isSummaryLoading } = useQuery({
    queryKey: ['getEventSummary', eventId],
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
    refetchOnWindowFocus: !hasData
  })
  async function runQuery() {
    if (isPublic && eventId) {
      const api = await Api()
      const res = await api.public.getPublicEventSummaryBase(eventId)
      return res
    }
    if (eventId) {
      const res = await getEventSummaryBase(eventId)
      return res
    }
  }

  useEffect(() => {
    if (summary && summary.details)
      setHasData(true)
  }, [summary])

  const { data: summaryStatus, isLoading: isSummaryStatusLoading } = useQuery({
    queryKey: ['getEventSummaryStatus', eventId],
    queryFn: runQueryEventSummaryStatus,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
    refetchInterval: () => isSummaryLoading ? 7000 : false, // refetch every 7 sec when summary is not loaded
  })

  async function runQueryEventSummaryStatus() {
    if (isPublic) {
      const publicStatus = { transcript_status: ProcessingStatus.DONE, summary_overall_status: ProcessingStatus.DONE } as SummaryProcessingStatus
      return publicStatus
    }
    if (eventId) {
      const res = await getEventSummaryStatus(eventId)
      return res
    }
  }

  const processingModuleNotSelected = data?.module_name ? false : true

  function isTranscriptProcessing() {
    if (summaryStatus?.transcript_status == ProcessingStatus.PROCESSING)
      return true
    return false
  }

  function isTranscriptEmpty() {
    if (summaryStatus?.transcript_status == ProcessingStatus.DONE_EMPTY_CONTENT)
      return true
    return false
  }

  function isTranscriptAborted() {
    if (summaryStatus?.transcript_status == ProcessingStatus.ABORTED)
      return true
    return false
  }

  function isModuleSelectionReady() {
    if (!isEventMetaDataLoading && summaryStatus?.transcript_status == ProcessingStatus.DONE && processingModuleNotSelected) {
      return true
    }

    return false
  }

  function isSummaryProcessing() {
    if (summaryStatus?.summary_overall_status == ProcessingStatus.PROCESSING)
      return true
    if (!processingModuleNotSelected && isSummaryLoading) {
      logInfo("isSummaryProcessing fallback logic", { event_id: data?.id, bot_id: data?.id, summary_status: summaryStatus })
      return true
    }
    return false
  }

  function isSummaryDone() {
    if (!isEventMetaDataLoading && summaryStatus?.summary_overall_status == ProcessingStatus.DONE && !isSummaryLoading)
      return true
    return false
  }

  if (isSummaryStatusLoading)
    return <EventSkeletonPage />

  if (isTranscriptProcessing()) {
    return <EventSkeletonTranscriptInProgressPage />
  }

  if (isTranscriptEmpty()) {
    return <Card sx={sxCard}>

      <Box sx={{ padding: '12vh' }} />
      <Typography sx={{ fontWeight: 'bold' }} textAlign={'center'}>No Meeting Transcript Available</Typography>
      <Typography textAlign={'center'}>Meeting type selection is not available due to an empty transcript.</Typography>
      <Typography textAlign={'center'}>If this seems incorrect, please contact support for assistance.</Typography>
      <Box sx={{ padding: '22vh' }} />

    </Card>
  }

  if (isTranscriptAborted()) {
    return <Card sx={sxCard}>

      <Box sx={{ padding: '12vh' }} />
      <Typography sx={{ fontWeight: 'bold' }} textAlign={'center'}>Transcript Processing Error Occurred</Typography>
      <Typography textAlign={'center'}>We apologize, but an error has occurred during transcript processing.</Typography>
      <Typography textAlign={'center'}>Please contact our support team for further assistance in resolving this issue.</Typography>
      <Box sx={{ padding: '22vh' }} />

    </Card>
  }

  if (isModuleSelectionReady()) {
    return (
      <Card sx={sxCard}>
        <Box sx={sxTitle}>
          <Typography variant='h4'>Select Meeting Type</Typography>
        </Box>
        <CardContent sx={sxContent}>
          <EventModuleSelect eventId={eventId} disabled={disabled} />
        </CardContent>
      </Card >
    )
  }

  if (isSummaryDone() && data) {
    return (
      <EventDetailsDone
        event={data}
        summary={summary}
        disabled={disabled || (isPublic ?? false)}
      />
    )
  }

  if (isSummaryProcessing()) {
    return <EventSkeletonSummaryInProgressPage />
  }

  return <EventSkeletonPage />
}

const sxCard: SxProps = {}

const sxTitle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const sxContent: SxProps = {
  overflowX: 'hidden',
  height: '75vh',
  paddingTop: "1px"
}
