/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrgUserRole {
    ADMIN = 'Admin',
    MEMBER = 'Member',
    INACTIVE = 'Inactive',
}
