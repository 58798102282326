import React, { Dispatch, MutableRefObject, ReactNode, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { Typography, Box, Card, Skeleton, Snackbar, Alert } from '@mui/material'
import 'firebase/auth'
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row
} from 'material-react-table'
import { getWealthboxWorkflowTemplates, startWealthboxWorkflows } from 'service/integrations/wealthboxApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { EventBase, WealthboxBase, WealthboxType, WealthboxVisibility, WealthboxWorkflow, WealthboxWorkflowTemplate } from 'client'
import { LoadingButton } from '@mui/lab'
import CheckIcon from '@mui/icons-material/Check'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { gColor, gSx } from 'styles/Theme'
import OverlayMessageOnComponent from '../OverlayMessageOnComponent'

interface Props {
  event: EventBase
  wbConfig?: WealthboxBase
  wbType: WealthboxType
  setExpanded: Dispatch<SetStateAction<boolean>>
}
interface CellProps {
  renderedCellValue: ReactNode
  row: MRT_Row<WealthboxWorkflowTemplate>
}

export default function WealthboxWorkflowsStart({ event, wbConfig, wbType, setExpanded }: Props) {
  const queryClient = useQueryClient()
  const hasClient = !!event?.client?.id
  const { isBlocked } = useIsBlockedByPlan(true)
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const workflowStart = useRef<WealthboxWorkflow[]>([])
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const numSelected = Object.keys(rowSelection).length
  const disableSend = isBlocked || !hasClient || busy || numSelected == 0

  const { data, isLoading } = useQuery({
    queryKey: ['getWealthboxWorkflowTemplates'], queryFn: getWealthboxWorkflowTemplates
  })

  useEffect(() => {
    const client = event.client
    if (!client)
      return
    workflowStart.current = table.getSelectedRowModel().rows.map((row) => {
      const res: WealthboxWorkflow = {
        linked_to: {
          id: client.wealthbox_id,
          name: `${client.first_name ?? ''} ${client.last_name ?? ''}`,
        },
        workflow_template: row.original,
        visible_to: wbConfig?.visibility ?? WealthboxVisibility.EVERYONE,
        workflow_milestones: row.original.workflow_milestones
      }
      return res
    })
  }, [rowSelection])

  const columns = useMemo<MRT_ColumnDef<WealthboxWorkflowTemplate>[]>(
    () => [
      {
        accessorKey: 'name', // access nested data with dot notation
        header: 'Name',
        size: 300,
        Cell: CellName
      },
      {
        accessorKey: 'steps',
        header: 'Steps',
        size: 10,
        Cell: CellSteps,
      },
      {
        accessorKey: 'kind',
        header: 'Workflow For',
        size: 10,
      }
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    enableFullScreenToggle: false,
    positionGlobalFilter: 'left',
    enableHiding: false,
    enableDensityToggle: false,
    enableSelectAll: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    initialState: {
      density: 'compact',
      columnOrder: [
        ...columns.map((col) => col.accessorKey as string),
        'mrt-row-select', // ID of the selection column
      ],
      pagination: {
        pageIndex: 0,
        pageSize: 5, // Set initial rows per page to 5
      },
    },
    localization: {
      select: 'Start',
    },
    positionActionsColumn: "last"
  })

  async function onSend() {
    setBusy(true)
    setErr(undefined)

    const res = await startWealthboxWorkflows(event?.client, event?.id, wbType, workflowStart.current)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      queryClient.invalidateQueries(['getEvent', event.id])
      queryClient.invalidateQueries(['getWealthboxWorkflowTemplates'])
      queryClient.invalidateQueries(['getWealthboxWorkflows'])
      setSnackSuccess(true)
      setExpanded(false)
    }
    setBusy(false)
  }

  return (
    <Box>
      <Typography>Start a workflow by selecting a Workflow Template.</Typography>
      <Box sx={{ padding: 1 }} />

      {isLoading || !data
        ? <Skeleton variant="rectangular" width={'100%'} height={100} />
        : data.length == 0
          ? <Typography>No Workflow Templates found.</Typography>
          : hasClient
            ? <MaterialReactTable table={table} />
            : <OverlayMessageOnComponent
              width='800px' height='250px' paddingTop='80px'
              overLayMessage={'Add Contact to Continue'}
            >
              <MaterialReactTable table={table} />
            </OverlayMessageOnComponent>
      }

      <Typography variant='caption'>Milestones are set 1 day ahead by default.</Typography>

      {err && <Typography color={'tomato'}>{err}</Typography>}
      <Box sx={gSx.RowBetween}>
        <Box />
        <Box sx={gSx.Row}>
          <LoadingButton
            variant={'contained'}
            onClick={onSend}
            disabled={disableSend}
            loading={busy}
            sx={{ marginLeft: 3 }}
          >
            Start Workflows
          </LoadingButton>
        </Box>
      </Box>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Wealthbox Workflows started
        </Alert>
      </Snackbar>
    </Box>
  )
}

function CellName({ row }: CellProps) {
  const maxLength = 40
  const text = row.original.name ?? ''
  const short = text.length > maxLength ? text.slice(0, maxLength) + "..." : text
  return (
    <Typography>
      {short}
    </Typography>
  )
}

function CellSteps({ row }: CellProps) {
  const steps = row.original.workflow_steps?.length
  return (
    <Typography>
      {steps}
    </Typography>
  )
}
