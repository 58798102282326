import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { CollegeFund } from '../../client'

interface Props {
  eventId?: string
  collegeFundList?: CollegeFund[]
}

export default function CollageFundTable({ eventId, collegeFundList }: Props) {

  const cellRef = useRef(null)
  const [data, setCollegeFundList] = useState<CollegeFund[]>([])

  useEffect(() => {
    if (collegeFundList) {
      console.log("collegeFundList", collegeFundList)
      setCollegeFundList(collegeFundList)
    }
  }, [collegeFundList])

  const columns = useMemo<MRT_ColumnDef<CollegeFund>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        size: 100
      },
      {
        accessorKey: 'Beneficiary',
        header: 'Beneficiary',
        size: 100
      },

      {
        accessorKey: 'Contributions',
        header: 'Contributions',
        size: 100
      },
      {
        accessorKey: 'Current Balance',
        header: 'Current Balance',
        size: 100
      },
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data,
  })

  return (
    <MRT_Table table={table} />
  )
}
