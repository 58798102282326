import queryString from 'query-string'
import { Api, auth, config } from "service/api"
import { logErr, logInfo } from "log"
import { getErrorMessage } from 'utils'
import { RingCentralRecord } from 'client'

export async function ringCentralOAuth() {
  const windowName = 'PopupWindow'
  const windowFeatures = 'width=500,height=700'

  const queryParams = {
    client_id: config.ringcentralClientId,
    redirect_uri: config.ringcentralRedirect,
    response_type: "code",
  }

  try {
    const baseUrl = `https://platform.ringcentral.com/restapi/oauth/authorize`
    const url = `${baseUrl}?${queryString.stringify(queryParams)}`
    window.open(url, windowName, windowFeatures)
  } catch (e) {
    logErr('RingCentral OAuth sign in')
  }
}

export async function setRingCentralAuth(code: string): Promise<undefined | Error> {
  try {
    logInfo('RingCentral authenticate')
    const api = await Api()
    await api.ringcentral.ringCentralAuth(code)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('RingCentral authenticate', { err })
    return err
  }
}

export async function getRingCentralProfile() {
  try {
    const api = await Api()
    const res = await api.ringcentral.ringCentralProfile()
    return res
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('RingCentral profile', { err })
  }
}

export async function getRingCentralCallLogs() {
  try {
    const api = await Api()
    const res = await api.ringcentral.ringCentralCallLogs()
    return res
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('RingCentral call logs', { err })
  }
}

export async function createEventFromCallLog(record?: RingCentralRecord) {
  if (!record)
    return
  try {
    const api = await Api()
    await api.ringcentral.createEventFromCallLog(record)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('RingCentral createEventFromCallLog', { err })
    return err
  }
}

export async function disconnectRingCentral(): Promise<undefined | Error> {
  try {
    const api = await Api()
    await api.ringcentral.ringCentralDisconnect()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('RingCentral disconnect', { err })
    return err
  }
}