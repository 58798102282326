import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogTitle, SxProps, Typography, Divider, DialogContent, DialogActions, DialogContentText, Card, FormGroup, FormControlLabel, Checkbox, ButtonBase, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Api } from '../../../service/api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import DownloadIcon from '@mui/icons-material/Download'
import { LoadingButton } from '@mui/lab'
import { getErrorMessage } from '../../../utils'
interface ExportPdfDialogProps {
  eventId: string
  open: boolean
  onCancel: () => void
}

export function ExportPdfDialog({ eventId, open, onCancel }: ExportPdfDialogProps) {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [includeSummary, SetIncludeSummary] = useState(true)
  const [includeActionItems, SetIncludeActionItems] = useState(true)
  const [includeTranscript, SetIncludeTranscript] = useState(false)

  const { data: pdfUrl, isLoading } = useQuery({
    queryKey: ['getPdfSignedUrl', eventId],
    queryFn: getPdfSignedUrl,
  })

  async function getPdfSignedUrl() {
    if (eventId) {
      const api = await Api()
      const pdfSignedUrlRes = await api.events.getPdfSignedUrl(eventId)
      return pdfSignedUrlRes
    }
  }

  async function onBuildPdf() {
    setBusy(true)
    try {
      setErrorMsg(null)
      const api = await Api()
      const pdfSignedUrlRes = await api.events.buildPdf(eventId, includeSummary, includeTranscript, includeActionItems)
      if (pdfSignedUrlRes) {
        handleDownload(pdfSignedUrlRes)
        await queryClient.invalidateQueries({ queryKey: ['getPdfSignedUrl', eventId] })
      }
    } catch (e) {
      const err = getErrorMessage(e)
      setErrorMsg(err.message)
    }
    setBusy(false)
  }

  const handleDownload = (pdfSignedUrl: string) => {
    if (pdfSignedUrl) {
      const link = document.createElement('a')
      link.href = pdfSignedUrl // Update the path if necessary
      link.target = "_blank"
      link.rel = "noopener noreferrer"
      const filename = new URL(pdfSignedUrl).pathname.split('/').pop()
      if (filename)
        link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  function handleChangeIncludeSummary(event: React.ChangeEvent<HTMLInputElement>) {
    SetIncludeSummary(event.target.checked)
  }

  function handleChangeIncludeActionItems(event: React.ChangeEvent<HTMLInputElement>) {
    SetIncludeActionItems(event.target.checked)
  }

  function handleChangeIncludeTranscript(event: React.ChangeEvent<HTMLInputElement>) {
    SetIncludeTranscript(event.target.checked)
  }

  function closeDailog() {
    setErrorMsg(null)
    onCancel()
  }

  return (
    <>
      <Dialog open={open} maxWidth={"md"} onClose={() => closeDailog()}>
        <DialogTitle>Export PDF File</DialogTitle>
        <DialogContent>
          <Card>
            <FormGroup>
              <FormControlLabel disabled={busy} control={<Checkbox checked={includeSummary}
                onChange={handleChangeIncludeSummary} />} label="Include Summary" />
              <FormControlLabel disabled={busy} control={<Checkbox checked={includeActionItems}
                onChange={handleChangeIncludeActionItems} />} label="Include Tasks" />
              <FormControlLabel disabled={busy} control={<Checkbox checked={includeTranscript}
                onChange={handleChangeIncludeTranscript} />} label="Include Transcript" />
            </FormGroup>
            <Typography variant="body2" sx={{ fontSize: 11 }}>
              {"If any of the selected item have expired, they will not be included."}</Typography>
          </Card>

          {
            pdfUrl &&
            <a href={pdfUrl} download={new URL(pdfUrl).pathname.split('/').pop()} style={{ textDecoration: 'underline', color: '#349ead' }} target="_blank" rel="noopener noreferrer">
              {"PDF not downloading? Click here"}
            </a>
          }
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: 0 }}>
          <Button
            onClick={() => closeDailog()}
            color="primary"
            aria-label='Close'
            sx={{ margin: '10px' }}
            variant="outlined"
          >
            Close
          </Button>
          <LoadingButton
            onClick={() => onBuildPdf()}
            color="primary"
            aria-label='Build PDF File'
            sx={{ margin: '10px' }}
            variant='contained'
            loading={busy}
            disabled={!includeSummary && !includeActionItems && !includeTranscript}
            startIcon={<DownloadIcon />}
          >
            {"Generate & Download PDF"}
          </LoadingButton>

        </DialogActions>
        <Snackbar
          open={Boolean(errorMsg)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity="error">
            {"PDF build failed, please try again - "} {errorMsg}
          </Alert>
        </Snackbar>
      </Dialog >
    </>
  )
}
