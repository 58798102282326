import React, { useEffect, useState } from 'react'
import {
  Box,
  Popover,
  TextField,
  Autocomplete,
  LinearProgress,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { assigneeDisplay, getSpeakersAndAssignees, updateEventTranscriptSpeaker } from 'service/eventApi'
import { AssigneeInfo } from 'client'
import AssigneePopoverRender from './AssigneePopoverRender'

interface Props {
  eventId: string
  speaker?: AssigneeInfo
  anchorEl: HTMLButtonElement | null
  handleClose: () => void
}

export default function TranscriptSpeakerPopover({ eventId, speaker, anchorEl, handleClose }: Props) {
  const queryClient = useQueryClient()
  const openPopover = Boolean(anchorEl)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState(false)
  const [openAssigneeList, setOpenAssigneeList] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['getSpeakersAndAssignees', eventId],
    queryFn: async () => await getSpeakersAndAssignees(eventId),
  })
  const featured = data?.filter(a => a.is_featured) ?? []
  const [assignees, setAssignees] = useState<AssigneeInfo[]>([])

  useEffect(() => {
    if (data)
      setAssignees(featured)
  }, [data])

  const onInputChange = (event: any, value: string | null) => {
    if (!data)
      return
    if (!value || value.length == 0) {
      setAssignees(featured)
      return
    }
    const query = value.toLowerCase()
    const matching = data.filter(item =>
      item.name?.toLowerCase().includes(query)
    )
    setAssignees(matching)
  }

  const onChange = async (event: any, value: AssigneeInfo | string | null) => {
    setBusy(true)
    const res = await updateEventTranscriptSpeaker(eventId, speaker?.speaker_id, value)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setOpenSuccessSnack(true)
      handleClose()
      queryClient.invalidateQueries({ queryKey: ['getEventTranscript', eventId] })
      queryClient.invalidateQueries({ queryKey: ['getSpeakersAndAssignees', eventId] })
    }
    setBusy(false)

  }

  useEffect(() => {
    if (openPopover) {
      setTimeout(() => {
        setOpenAssigneeList(true)
      }, 170)
    } else {
      setOpenAssigneeList(false)
    }
  }, [openPopover])

  if (isLoading || !data)
    return null

  return (
    <>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Autocomplete
          openOnFocus
          open={openAssigneeList}
          sx={{ width: 300, margin: 2 }}
          id="speaker"
          freeSolo
          options={assignees}
          getOptionLabel={(option) => assigneeDisplay(option)}
          renderOption={(props, option) =>
            <AssigneePopoverRender {...props} option={option} />
          }
          renderInput={(params) =>
            <TextField autoFocus {...params} label="Speaker (⏎ to save)" />
          }
          value={""}
          onChange={onChange}
          onInputChange={onInputChange}
          disabled={busy}
          disableClearable
        />

        {busy &&
          <Box sx={{ width: '100%', paddingY: '1vh' }}>
            <LinearProgress />
          </Box>
        }

        {err &&
          <Typography color={'tomato'} sx={{ paddingX: 2 }}>
            {err}
          </Typography>
        }

      </Popover>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Transcript Speaker Name Updated
        </Alert>

      </Snackbar>
    </>
  )
}