import React, { useState } from 'react'

import { gSx } from 'styles/Theme'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'

import { ModuleIdentifier } from '../../client'

interface Props {
  item: ModuleIdentifier
}

export default function ModuleExamples({ item }: Props) {
  const [openDialog, setOpenDialog] = useState(false)

  if (item.sample_output && item.sample_output.length > 0)
    return (
      <>
        <Box sx={{ alignContent: 'center' }}>
          <Tooltip title={`Sample ${item.module_ui_title}`}>
            <IconButton
              aria-label='info'
              onClick={() => setOpenDialog(true)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="confirm module selection"
        >
          <Box sx={gSx.RowBetween}>
            <DialogTitle>{`Sample ${item.module_ui_title}`}</DialogTitle>
            <IconButton
              sx={{ alignSelf: 'flex-start' }}
              aria-label='close'
              onClick={() => setOpenDialog(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <DialogContent>
            <Typography variant="caption">Note: The point of view setting has not been applied. The sample output below uses third-person perspective.</Typography>
            <div
              dangerouslySetInnerHTML={{ __html: item.sample_output ?? "Coming soon" }}
              style={{
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                padding: '16px',
                overflow: 'auto',
                backgroundColor: '#fcfcfc',
              }}
            />
          </DialogContent>
        </Dialog>
      </>
    )

  return null
}
